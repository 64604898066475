import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import { Input, Select, FileImg, InputDate, Button } from '../Fields';
import { getFolder } from '../../services/folders';
import { espece, sexe, boolean } from '../../services/constantes';
import './Forms.scss';

interface IForm {
  onSubmitted?: (
    e: Record<string, string[] | string | Date | number | undefined>
  ) => void;
}

function Form({ onSubmitted }: IForm): JSX.Element {
  const user = useSelector(userSelector);
  const folder = user?.folder;
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      animalName: '',
      animalSpecies: '',
      animalRace: '',
      animalSex: '',
      animalSterilise: '',
      animalBirthDate: '',
      animalIdentification: '',
      suiviPhotoProprio: '',
    },
  });

  const { handleSubmit, getValues, setValue } = form;

  useEffect(() => {
    if (folder?.id) {
      getFolder(folder.id).then((folderDetail) => {
        setValue('animalName', folderDetail.animalName);
        setValue('animalSpecies', folderDetail.animalSpecies);
        setValue('animalRace', folderDetail.animalRace);
        setValue('animalSex', folderDetail.animalSex);
        setValue('animalSterilise', folderDetail.animalSterilise);
        setValue('animalBirthDate', new Date(folderDetail.animalBirthDate));
        setValue('animalIdentification', folderDetail.animalIdentification);
        setValue('suiviPhotoProprio', folderDetail.suiviPhotoProprio);
      });
    }
  }, [folder, setValue]);

  function preSubmit() {
    if (onSubmitted) {
      const values = getValues();
      onSubmitted({
        animalSpecies: parseInt(`${values.animalSpecies}`, 10),
        animalRace: values.animalRace,
        animalSex: parseInt(`${values.animalSex}`, 10),
        animalSterilise: parseInt(`${values.animalSterilise}`, 10),
        animalBirthDate: values.animalBirthDate,
        animalIdentification: values.animalIdentification,
        suiviPhotoProprio: values.suiviPhotoProprio,
      });
    }
  }

  return (
    <form
      className="Forms"
      onSubmit={handleSubmit(preSubmit)}
      encType="multipart/form-data"
      noValidate
    >
      <Input
        icon="paw"
        type="text"
        name="animalName"
        label="Nom"
        formApi={form}
        disabled
      />
      <Select
        icon="paw"
        name="animalSpecies"
        label="Espèce*"
        formApi={form}
        required="Merci de selectionner un genre."
      >
        {espece.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>
      <Input
        icon="paw"
        type="text"
        name="animalRace"
        label="Race"
        formApi={form}
      />
      <Select
        icon="paw"
        name="animalSex"
        label="Sexe*"
        formApi={form}
        required="Merci de selectionner un sexe."
      >
        {sexe.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>
      <Select
        icon="paw"
        name="animalSterilise"
        label="Stérilisé*"
        formApi={form}
        required="Merci de selectionner une réponse."
      >
        {boolean.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>
      <InputDate
        name="animalBirthDate"
        label="Date de naissance*"
        formApi={form}
        required="Merci de renseigner une date."
        maxDate={new Date()}
      />
      <Input
        icon="fingerPrint"
        type="text"
        name="animalIdentification"
        label="Identification"
        formApi={form}
        maxLength={15}
        format={(value) => value?.replace(/\D/g, '').toUpperCase()}
        description="(Si l’animal n’est pas identifié, il devra l’être avant le règlement de la facture)"
      />
      <FileImg
        label="Photo de l'animal"
        name="suiviPhotoProprio"
        formApi={form}
        multiple
      />
      <Button>Valider</Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
};

export default Form;
