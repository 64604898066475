import React from 'react';
import './Text.scss';

interface IText {
  children?: React.ReactNode;
  label?: string;
  type?: 'grey';
  capitalize?: boolean;
}
function Text({ children, label, type, capitalize }: IText): JSX.Element {
  return (
    <div
      className={`Text ${type ? `-${type}` : ''} ${
        capitalize ? '-capitalize' : ''
      }`}
    >
      {label && <>{label}&nbsp;: </>}
      {typeof children === 'string' && label ? <b>{children}</b> : children}
    </div>
  );
}
Text.defaultProps = {
  children: null,
  label: null,
  type: null,
  capitalize: null,
};
export default Text;
