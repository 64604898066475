import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import FolderList, { IFolderItem } from '../../../components/FolderList';
import { LinkFolder } from '../../../components/Link';
import DetailFolderMember from '../../../components/DetailFolderMember';
import {
  folderStatusVeto,
  getMemberFolderStatusById,
} from '../../../services/constantes';
import './Folders.scss';

interface ParamTypes {
  filter: string;
  folderId: string;
}

function Folders(): JSX.Element {
  const user = useSelector(userSelector);
  const { filter, folderId } = useParams<ParamTypes>();
  const [search, setSearch] = useState<string | null>(null);
  const [folderList, setFolderList] = useState<IFolderItem[] | null | Error>(
    null
  );
  const [folderFilteredList, setFolderFilteredList] = useState<
    IFolderItem[] | null | Error
  >(null);

  useEffect(() => {
    setFolderList(null);
    setFolderFilteredList(null);

    if (user?.folders) {
      const folders = user.folders.map((folder) => {
        return {
          id: folder['@id'],
          title: `${folder.animalName} - ${folder.ownerFirstname} ${folder.ownerLastname}`,
          statusLabel: [1, 2, 5, 6].includes(folder.status)
            ? getMemberFolderStatusById(folder.status)
            : null,
          dateCreate: new Date(folder.createdAt),
          notification: [3, 5].includes(folder.status) && !!folder.isUpdate,
          status: folder.status,
          content: <DetailFolderMember folderId={folder.id} />,
          expanded: folderId === folder.id,
          search: [
            folder.id,
            folder.ownerFirstname,
            folder.ownerLastname,
            folder.animalName,
            folder.etbtName,
          ],
        };
      });

      const foldersFiltered = folders
        .filter((e) => folderStatusVeto[filter].includes(e.status))
        .filter((folder) =>
          search
            ? folder.search.some((value) =>
                value?.toLowerCase().includes(search.toLowerCase())
              )
            : true
        )
        .sort((a, b) => {
          return new Date(a.dateCreate).getTime() <
            new Date(b.dateCreate).getTime()
            ? 1
            : -1;
        });

      setFolderList(folders);
      setFolderFilteredList(foldersFiltered);
    }
  }, [filter, folderId, user, search]);

  function checkNotification(type: string) {
    return Array.isArray(folderList)
      ? folderList.filter(
          (e) =>
            e.status &&
            e.notification &&
            folderStatusVeto[type].includes(e.status)
        ).length
      : 0;
  }

  return (
    <div className="MemberFolders">
      <div className="MemberFolders__header">
        <h1 className="MemberFolders__header__title">Dossiers</h1>
        <nav>
          <ul className="MemberFolders__header__nav">
            <li className="MemberFolders__header__nav__list">
              <LinkFolder
                href="/membre/dossiers/en-attente"
                notification={checkNotification('en-attente')}
                folderPending
              >
                Dossiers <b>en attente</b>
              </LinkFolder>
            </li>
            <li className="MemberFolders__header__nav__list">
              <LinkFolder
                href="/membre/dossiers/en-cours"
                notification={checkNotification('en-cours')}
                folderProgress
              >
                Dossiers <b>en cours</b>
              </LinkFolder>
            </li>
            <li className="MemberFolders__header__nav__list">
              <LinkFolder
                href="/membre/dossiers/clotures"
                notification={checkNotification('clotures')}
                folderClose
              >
                Dossiers <b>clôturés</b>
              </LinkFolder>
            </li>
            <li className="MemberFolders__header__nav__list">
              <LinkFolder
                href="/membre/dossiers/refuses"
                notification={checkNotification('refuses')}
                folderReject
              >
                Dossiers <b>refusés</b>
              </LinkFolder>
            </li>
          </ul>
        </nav>
        <div className="MemberFolders__header__search">
          <input
            type="search"
            placeholder="Rechercher un dossier"
            className="MemberFolders__header__search__input"
            onChange={({ target }) => setSearch(target.value)}
          />
        </div>
      </div>
      <FolderList
        foldersList={folderFilteredList}
        notFoundLabel="Aucun dossier trouvé..."
        expandForRender
      />
    </div>
  );
}

export default Folders;
