import React, { useState, useEffect } from 'react';
import { getMedia } from '../../../services/folders';

interface IImg {
  file?: File;
  media?: string;
  className?: string;
}

function ImgUploaded({ file, media, className }: IImg): JSX.Element | null {
  const [label, setLabel] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file) {
      setLabel(file?.name);
    } else if (media) {
      getMedia(parseInt(media.replace('/api/medias/', ''), 10)).then(
        ({ niceName }) => {
          setLabel(niceName);
        }
      );
    }
  }, [media, file]);

  return <div className={className}>{label}</div>;
}

ImgUploaded.defaultProps = {
  file: undefined,
  media: undefined,
  className: undefined,
};

export default ImgUploaded;
