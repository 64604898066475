import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateUserEmail } from '../../store/user/user.actions';
import { Input, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  white?: boolean;
}

function Form({ white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: user?.email || '',
    },
  });

  const { handleSubmit, getValues } = form;
  function onSubmit() {
    setLoading(true);
    dispatch(setUpdateUserEmail(getValues().email)).finally(() =>
      setLoading(false)
    );
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Input
        icon="email"
        type="email"
        name="email"
        label="Adresse mail*"
        formApi={form}
        required="Merci de renseigner une adresse mail."
        pattern={{
          value: /.+@.+\..+/,
          message: 'Merci de renseigner une adresse mail valide.',
        }}
        white={white}
      />

      <Button loading={isLoading}>Modifier</Button>
    </form>
  );
}

Form.defaultProps = {
  white: false,
};

export default Form;
