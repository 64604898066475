import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setCreateMember } from '../../../store/user/user.actions';
import { ExpandLabel } from '../../../components/Expand';
import { Input, Select, FileImg, Button } from '../../../components/Fields';
import Charte from '../../../components/Charte';
import { typeEtablissement, fonction } from '../../../services/constantes';
import { ReactComponent as Logo } from '../../../img/logo.svg';
import './Subscribe.scss';

function Subscribe(): JSX.Element {
  const dispatch = useDispatch();
  const [parts, setParts] = useState<boolean[]>([true, false, true]);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      EtabNom: undefined,
      GroupOrGie: undefined,
      nbOfVeto: undefined,
      SIRET: undefined,
      EtabAdresse: undefined,
      EtabCP: undefined,
      EtabVille: undefined,
      telephone: undefined,
      medias: undefined,
      iban: undefined,
      bic: undefined,
      contactReferent: undefined,
      fonctionRef: undefined,
      refTel: undefined,
      refEmail: undefined,
    },
  });

  const { handleSubmit, errors, getValues } = form;

  function showPart(index: number, closeOther = false) {
    setParts((e) =>
      e.map((status, i) => {
        if (closeOther) {
          return i === index;
        }
        return i === index ? true : status;
      })
    );
  }

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  useEffect(() => {
    if (Object.values(errors).length) {
      showPart(0);
      showPart(1);
    }
  }, [errors]);

  function onSubmit() {
    if (!accepted) return;
    setLoadingForm(true);

    const values = getValues();

    dispatch(
      setCreateMember({
        EtabNom: values.EtabNom,
        GroupOrGie: parseInt(`${values.GroupOrGie}`, 10),
        nbOfVeto: parseInt(`${values.nbOfVeto}`, 10),
        SIRET: values.SIRET,
        EtabAdresse: values.EtabAdresse,
        EtabCP: values.EtabCP,
        EtabVille: values.EtabVille,
        telephone: values.telephone,
        medias: values.medias ? [values.medias] : [],
        iban: values.iban,
        bic: values.bic,

        contactReferent: values.contactReferent,
        fonctionRef: parseInt(`${values.fonctionRef}`, 10),
        refTel: values.refTel,
        refEmail: values.refEmail,

        charte: 1,
      })
    ).catch(() => setLoadingForm(false));
  }

  return (
    <div className="MemberSubscribe">
      <div className="MemberSubscribe__header">
        <Logo className="MemberSubscribe__logo" />
      </div>

      <form
        className="MemberSubscribe__content"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        noValidate
      >
        <ExpandLabel
          onClick={() => togglePart(0)}
          expand={parts[0]}
          label="Information structure de soin"
        >
          <Input
            icon="house"
            type="text"
            name="EtabNom"
            label="Nom de l’établissement*"
            formApi={form}
            required="Merci de renseigner un nom de l’établissement."
          />

          <Select
            icon="house"
            name="GroupOrGie"
            label="Type d’établissement*"
            formApi={form}
            required="Merci de selectionner un type d’établissement."
          >
            {typeEtablissement.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>

          <Input
            icon="house"
            type="number"
            name="nbOfVeto"
            label="Nombre de vétérinaires exerçant au sein du DPE*"
            formApi={form}
            required="Merci de renseigner un nombre."
            inputMode="numeric"
          />

          <Input
            icon="house"
            inputMode="numeric"
            name="SIRET"
            label="SIRET*"
            formApi={form}
            required="Merci de renseigner un numéro SIRET."
            format={(value) => value.replace(/\D/g, '')}
          />

          <Input
            icon="pin"
            type="text"
            name="EtabAdresse"
            label="Adresse*"
            formApi={form}
            required="Merci de renseigner une adresse."
          />

          <Input
            icon="pin"
            type="text"
            name="EtabCP"
            label="Code postal*"
            formApi={form}
            required="Merci de renseigner un code postal."
            maxLength={5}
            inputMode="numeric"
            pattern={{
              value: /[0-9]{5}/,
              message: 'Merci de renseigner une code postal valide.',
            }}
            format={(value) => value?.replace(/\D/g, '')}
          />

          <Input
            icon="pin"
            type="text"
            name="EtabVille"
            label="Ville*"
            formApi={form}
            required="Merci de renseigner une ville."
          />

          <Input
            icon="phone"
            type="text"
            name="telephone"
            inputMode="tel"
            label="Téléphone*"
            formApi={form}
            required="Merci de renseigner un numéro de téléphone."
            maxLength={14}
            pattern={{
              value: /([0-9]{2}\s?){5}/g,
              message: 'Merci de renseigner un numéro de téléphone valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\D/g, '')
                ?.match(/.{1,2}/g)
                ?.join(' ') || ''
            }
          />

          <FileImg name="medias" label="Votre logo :" formApi={form} />

          <Input
            icon="euro"
            type="text"
            inputMode="numeric"
            name="iban"
            label="IBAN*"
            formApi={form}
            required="Merci de renseigner un numéro IBAN."
            maxLength={33}
            pattern={{
              value: /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/g,
              message: 'Merci de renseigner un numéro IBAN valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\W/g, '')
                ?.toUpperCase()
                ?.match(/.{1,4}/g)
                ?.join(' ') || ''
            }
          />

          <Input
            icon="euro"
            type="text"
            name="bic"
            label="BIC*"
            formApi={form}
            required="Merci de renseigner un numéro BIC."
            maxLength={11}
            format={(value) => value?.replace(/\W/g, '').toUpperCase()}
          />

          <Button type="button" onClick={(e) => showPart(1, true)}>
            Valider
          </Button>
        </ExpandLabel>

        <ExpandLabel
          onClick={() => togglePart(1)}
          expand={parts[1]}
          label="Information référent Vetericare"
        >
          <Input
            icon="user"
            type="text"
            name="contactReferent"
            label="Prénom & Nom*"
            formApi={form}
            required="Merci de renseigner un nom."
          />

          <Select
            icon="user"
            name="fonctionRef"
            label="Fonction*"
            formApi={form}
            required="Merci de selectionner une fonction."
          >
            {fonction.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>

          <Input
            icon="phone"
            type="tel"
            name="refTel"
            label="Téléphone*"
            formApi={form}
            required="Merci de renseigner un numéro de téléphone."
            inputMode="tel"
            maxLength={14}
            pattern={{
              value: /([0-9]{2}\s?){5}/g,
              message: 'Merci de renseigner un numéro de téléphone valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\D/g, '')
                ?.match(/.{1,2}/g)
                ?.join(' ')
            }
          />

          <Input
            icon="email"
            type="email"
            name="refEmail"
            label="Email*"
            formApi={form}
            required="Merci de renseigner un email."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse mail valide.',
            }}
          />

          <Button type="button" onClick={(e) => showPart(2, true)}>
            Valider
          </Button>
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(2)}
          expand={parts[2]}
          label="Charte Vetericare"
        >
          <Charte
            onAccepted={setAccepted}
            infoLabel="Merci de lire attentivement notre charte d’engagements et de la
          valider pour finaliser votre adhésion."
            statusLabel="Au nom de mon établissement, je m’engage au respect de la charte
d’engagements."
          >
            <p>
              <strong>Vetericare</strong> est une association à but non
              lucratif, qui aide les équipes vétérinaires à soigner des animaux
              nécessitant des soins lourds et soutenir leurs familles démunies.
            </p>
            <p>
              La réussite pérenne des missions de l’association repose sur la
              solidarité et l’intérêt collectif.{' '}
              <strong>Adhérer, c’est partager nos valeurs</strong> et s’inscrire
              dans la Charte Vetericare.
              <br />
              <strong>
                Adhérer, c’est aussi s’engager à respecter les règles de
                fonctionnement
              </strong>{' '}
              de la plateforme Vetericare
            </p>

            <h2>Charte Vetericare</h2>

            <p>
              <strong>En adhérant à l’association Vetericare,</strong>
              <br />
              <br />
              Notre équipe participe au développement{' '}
              <strong>d’une médecine solidaire et responsable :</strong>
            </p>

            <p>
              - en proposant aux familles modestes une solution d’aide au
              financement de soins lourds à bon pronostic,
            </p>

            <p>
              - en donnant accès à un parcours pédagogique dédié aux
              propriétaires, responsables d’animaux de compagnie,
            </p>
            <p>
              - en participant financièrement, à travers notre adhésion et
              l’organisation de différentes actions caritatives, au financement
              de l’association,
            </p>
            <p>
              - en faisant connaître Vetericare et ses actions et ainsi
              participer au développement de la médecine solidaire vétérinaire.
            </p>

            <h2>Règles de fonctionnement de la plateforme</h2>

            <p>
              <strong>En adhérant à l’association Vetericare,</strong>
              <br />
              <br />
              Je m’engage, ainsi que mon équipe, à sélectionner des
              propriétaires honnêtes, de bonne foi et motivés pour suivre le
              cursus de formation lors d’une demande de financement.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, quant à la véracité et
              l’honnêteté des informations médicales transmises sur la
              plateforme de l’association.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, à réaliser des devis de soins
              réalistes et cohérents avec les prix de marché.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, à m’assurer que le propriétaire
              de l’animal sera capable d’assumer les suites médicales et
              financières des soins pris en charge par l’association
            </p>
            <p>
              J’ai pris connaissance, ainsi que mon équipe, du délai de carence
              de 10 jours entre l’adhésion et la possibilité de constituer le
              premier dossier de financement
            </p>
            <p>
              J’ai pris connaissance, ainsi que mon équipe, des critères
              médicaux de prise en charge (soins lourds de bon pronostic) et je
              m’engage à réserver mes demandes de financement aux cas
              correspondant à ces critères.
            </p>
            <p>
              J’accepte, ainsi que mon équipe, la publication et la diffusion
              des données médicales et non-médicales (photos, vidéos, ....)
              transmises à Vetericare.
            </p>
            <p>
              J’accepte, ainsi que mon équipe, que Vetericare communique sur
              l’adhésion de mon établissement et les soins réalisés par
              l’équipe.
            </p>
          </Charte>

          <Button disabled={!accepted} loading={loadingForm}>
            Valider
          </Button>
        </ExpandLabel>
      </form>
      <div className="MemberSubscribe__footer">*champs obligatoires</div>
    </div>
  );
}

export default Subscribe;
