import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import Link from '../../../components/Link';
import Tabs, { Tab } from '../../../components/Tabs';
import FolderList, { IFolderItem } from '../../../components/FolderList';
import {
  folderStatusVeto,
  getMemberFolderStatusById,
} from '../../../services/constantes';
import './Home.scss';

function Home(): JSX.Element {
  const user = useSelector(userSelector);
  const [list1, setList1] = useState<IFolderItem[] | null | Error>(null);
  const [list2, setList2] = useState<IFolderItem[] | null | Error>(null);

  useEffect(() => {
    if (user?.folders) {
      const folders = user.folders
        .map((folder) => ({
          id: folder['@id'],
          title: `${folder.animalName} - ${folder.ownerFirstname} ${folder.ownerLastname}`,
          statusLabel: [1, 2, 5, 6].includes(folder.status)
            ? getMemberFolderStatusById(folder.status)
            : null,
          dateCreate: new Date(folder.createdAt),
          notification: !!folder.isUpdate,
          status: folder.status,
          link: folder.id,
        }))
        .sort((a, b) => {
          return new Date(a.dateCreate).getTime() <
            new Date(b.dateCreate).getTime()
            ? 1
            : -1;
        });

      setList1(
        folders
          .filter((e) => folderStatusVeto['en-cours'].includes(e.status))
          .map((folder) => ({
            ...folder,
            link: `/membre/dossiers/en-cours/${folder.link}`,
          }))
      );

      setList2(
        folders
          .filter((e) => folderStatusVeto['en-attente'].includes(e.status))
          .map((folder) => ({
            ...folder,
            link: `/membre/dossiers/en-attente/${folder.link}`,
          }))
      );
    }
  }, [user]);

  const getLabelValid = useCallback(() => {
    if (list1 === null || list1 === undefined) {
      return 'Dossier en cours';
    }
    if (list1 instanceof Error) {
      return 'Dossier en cours';
    }
    if (!list1.length) {
      return 'Aucun dossier en cours';
    }
    if (list1.length === 1) {
      return '1 dossier en cours';
    }
    return `${list1.length} dossiers en cours`;
  }, [list1]);

  const getLabelTodo = useCallback(() => {
    if (list2 === null || list2 === undefined) {
      return 'Dossier en attente';
    }
    if (list2 instanceof Error) {
      return 'Dossier en attente';
    }
    if (!list2.length) {
      return 'Aucun dossier en attente';
    }
    if (list2.length === 1) {
      return '1 dossier en attente';
    }
    return `${list2.length} dossiers en attente`;
  }, [list2]);

  return (
    <div className="MemberHome">
      <h1 className="MemberHome__title">Tableau de bord</h1>
      <Tabs>
        <Tab title={getLabelValid()}>
          <FolderList
            foldersList={list1}
            notFoundLabel="Aucun dossier en cours..."
          />
          <Link href="/membre/dossiers/en-cours" className="MemberHome__more">
            Tous les dossiers
          </Link>
        </Tab>
        <Tab title={getLabelTodo()}>
          <FolderList
            foldersList={list2}
            notFoundLabel="Aucun dossier en attente..."
          />
          <Link href="/membre/dossiers/en-attente" className="MemberHome__more">
            Tous les dossiers
          </Link>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Home;
