import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Charte.scss';

interface ICharte {
  children?: React.ReactNode;
  infoLabel?: string;
  statusLabel?: string;
  onAccepted?: (e: boolean) => void;
}
function Charte({
  children,
  infoLabel,
  statusLabel,
  onAccepted,
}: ICharte): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);

  const update = useCallback(() => {
    const $contentEl = contentRef.current;
    const $wrapEl = $contentEl?.querySelector(
      '.Charte__content__text__scroll__wrap'
    );

    if ($wrapEl) {
      const scrollTop = $contentEl?.scrollTop || 0;
      const heightTarget = $contentEl?.clientHeight || 0;
      const heightWrap = $wrapEl?.clientHeight || 0;

      if (statusRef.current) {
        const $status = statusRef.current;
        const scrollPercent = (scrollTop / heightWrap) * 100;
        const heightPercent = (heightTarget / heightWrap) * 100;
        $status.style.top = `${scrollPercent}%`;
        $status.style.height = `${heightPercent}%`;
      }

      if (scrollTop + heightTarget >= heightWrap && !isAllowed) {
        setIsAllowed(true);
      }
    }
  }, [isAllowed]);

  useEffect(() => {
    update();
    window.onresize = update;
  }, [update]);

  useEffect(() => {
    if (onAccepted) {
      onAccepted(isAllowed);
    }
  }, [isAllowed, onAccepted]);

  return (
    <div className="Charte">
      {infoLabel && <div className="Charte__label">{infoLabel}</div>}
      <div className="Charte__content">
        <div className="Charte__content__icon -justice" />
        <div className="Charte__content__text">
          <div
            className="Charte__content__text__scroll"
            onScroll={() => update()}
            ref={contentRef}
          >
            <div className="Charte__content__text__scroll__wrap">
              {children}
            </div>
          </div>
          <div className="Charte__content__status">
            <span ref={statusRef} />
          </div>
        </div>
      </div>
      {onAccepted && (
        <div className="Charte__statusLabel">
          <span
            className={`Charte__statusLabel__status ${
              isAllowed ? '-allowed' : ''
            }`}
          />
          {statusLabel}
        </div>
      )}
    </div>
  );
}

Charte.defaultProps = {
  children: undefined,
  infoLabel: undefined,
  statusLabel: undefined,
  onAccepted: undefined,
};

export default Charte;
