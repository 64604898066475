import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  showModalAction,
  hideModalAction,
} from '../../store/modal/modal.actions';
import { setCreateFolder } from '../../store/user/user.actions';
import { userSelector } from '../../store/user/user.selectors';
import { ExpandLabel } from '../Expand';
import { Input, Select, FileDoc, Textarea, Button, InputDate } from '../Fields';
import {
  genre,
  espece,
  typePathologie,
  sexe,
  boolean,
} from '../../services/constantes';
import './Forms.scss';
import { LinkButton } from '../Link';

interface IForm {
  onSubmitted?: any;
}

function Form({ onSubmitted }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [parts, setParts] = useState<boolean[]>([true, false, false, false]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm<any>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      etbtName: user?.member?.EtabNom || '',
      vetoIncharge: user?.member?.contactReferent || '',
      contactMail: user?.member?.refEmail || '',
      contactPhone: user?.member?.refTel || '',
      structAdress: user?.member?.EtabAdresse || '',
      structureCP: user?.member?.EtabCP || '',
      structureVille: user?.member?.EtabVille || '',

      firstname: '',
      lastname: '',
      adress: '',
      cp: '',
      ville: '',
      phone: '',
      emailProprietaire: '',
      animalName: '',
      animalRace: '',
      animalBirthDate: '',
      animalIdentification: '',
      pathoSignes: '',
      medias: [],
      estimateAmount: '',
      devis: '',
      comments: '',
    },
  });
  const { handleSubmit, getValues, errors } = form;

  function showAllParts() {
    setParts((e) => e.map(() => true));
  }

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  function preSubmit() {
    if (onSubmitted) {
      onSubmitted(getValues());
    } else {
      setLoading(true);
      const values = getValues();

      dispatch(
        setCreateFolder({
          ...values,
          animalSpecies: parseInt(`${values.animalSpecies}`, 10),
          animalSex: parseInt(`${values.animalSex}`, 10),
          animalSterilise: parseInt(`${values.animalSterilise}`, 10),
          pathoEuthanasie: parseInt(`${values.pathoEuthanasie}`, 10),
          pathoType: parseInt(`${values.pathoType}`, 10),
          genre: parseInt(`${values.genre}`, 10),
          estimateAmount: parseFloat(`${values.estimateAmount || 0}`),
        })
      )
        .then(() => {
          dispatch(
            showModalAction(
              <div>
                <div style={{ marginBottom: '20px' }}>
                  Le dossier a bien été créé !
                </div>
                <LinkButton
                  href="/"
                  onClick={() => dispatch(hideModalAction())}
                >
                  Retour au tableau de bord
                </LinkButton>
              </div>
            )
          );
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <form
      className="Forms"
      onSubmit={handleSubmit(preSubmit)}
      encType="multipart/form-data"
      noValidate
    >
      <ExpandLabel
        label="Informations structure de soins"
        expand={parts[0]}
        onClick={() => togglePart(0)}
      >
        <Input
          icon="house"
          type="text"
          name="etbtName"
          label="Nom de l’établissement*"
          formApi={form}
          required="Merci de renseigner un nom de l’établissement."
        />
        <Input
          icon="house"
          type="text"
          name="vetoIncharge"
          label="Nom du vétérinaire en charge du cas*"
          formApi={form}
          required="Merci de renseigner le Nom du vétérinaire en charge du cas."
        />
        <Input
          icon="email"
          type="email"
          name="contactMail"
          label="Mail de contact*"
          formApi={form}
          required="Merci de renseigner un email."
          pattern={{
            value: /.+@.+\..+/,
            message: 'Merci de renseigner une adresse mail valide.',
          }}
        />
        <Input
          icon="phone"
          type="tel"
          name="contactPhone"
          label="Téléphone de contact*"
          formApi={form}
          required="Merci de renseigner un numéro de téléphone."
          inputMode="tel"
          maxLength={14}
          pattern={{
            value: /([0-9]{2}\s?){5}/g,
            message: 'Merci de renseigner un numéro de téléphone valide.',
          }}
          format={(value) =>
            value
              ?.replace(/\D/g, '')
              ?.match(/.{1,2}/g)
              ?.join(' ')
          }
        />
        <Input
          icon="pin"
          type="text"
          name="structAdress"
          label="Adresse*"
          formApi={form}
          required="Merci de renseigner une adresse."
        />
        <Input
          icon="pin"
          type="text"
          name="structureCP"
          label="Code postal*"
          formApi={form}
          required="Merci de renseigner un code postal."
          maxLength={5}
          inputMode="numeric"
          pattern={{
            value: /[0-9]{5}/,
            message: 'Merci de renseigner une code postal valide.',
          }}
          format={(value) => value?.replace(/\D/g, '')}
        />
        <Input
          icon="pin"
          type="text"
          name="structureVille"
          label="Ville*"
          formApi={form}
          required="Merci de renseigner une ville."
        />
      </ExpandLabel>
      <ExpandLabel
        label="Informations propriétaire"
        expand={parts[1]}
        onClick={() => togglePart(1)}
      >
        <Select
          icon="house"
          name="genre"
          label="Genre*"
          formApi={form}
          required="Merci de selectionner un genre."
        >
          {genre.map((e) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <Input
          icon="user"
          type="text"
          name="firstname"
          label="Prénom*"
          formApi={form}
          required="Merci de renseigner un prénom."
        />
        <Input
          icon="user"
          type="text"
          name="lastname"
          label="Nom*"
          formApi={form}
          required="Merci de renseigner un nom."
        />
        <Input
          icon="pin"
          type="text"
          name="adress"
          label="Adresse*"
          formApi={form}
          required="Merci de renseigner une adresse."
        />
        <Input
          icon="pin"
          type="text"
          name="cp"
          label="Code postal*"
          formApi={form}
          required="Merci de renseigner un code postal."
          maxLength={5}
          inputMode="numeric"
          pattern={{
            value: /[0-9]{5}/,
            message: 'Merci de renseigner une code postal valide.',
          }}
          format={(value) => value?.replace(/\D/g, '')}
        />
        <Input
          icon="pin"
          type="text"
          name="ville"
          label="Ville*"
          formApi={form}
          required="Merci de renseigner une ville."
        />
        <Input
          icon="phone"
          type="tel"
          name="phone"
          label="Téléphone*"
          formApi={form}
          required="Merci de renseigner un numéro de téléphone."
          inputMode="tel"
          maxLength={14}
          pattern={{
            value: /([0-9]{2}\s?){5}/g,
            message: 'Merci de renseigner un numéro de téléphone valide.',
          }}
          format={(value) =>
            value
              ?.replace(/\D/g, '')
              ?.match(/.{1,2}/g)
              ?.join(' ')
          }
        />
        <Input
          icon="email"
          type="email"
          name="emailProprietaire"
          label="Mail*"
          formApi={form}
          required="Merci de renseigner un email."
          pattern={{
            value: /.+@.+\..+/,
            message: 'Merci de renseigner une adresse mail valide.',
          }}
        />
      </ExpandLabel>
      <ExpandLabel
        label="Informations animal"
        expand={parts[2]}
        onClick={() => togglePart(2)}
      >
        <Input
          icon="paw"
          type="text"
          name="animalName"
          label="Nom*"
          formApi={form}
          required="Merci de renseigner un nom."
        />
        <Select
          icon="paw"
          name="animalSpecies"
          label="Espèce*"
          formApi={form}
          required="Merci de selectionner un genre."
        >
          {espece.map((e: any) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <Input
          icon="paw"
          type="text"
          name="animalRace"
          label="Race"
          formApi={form}
        />
        <Select
          icon="paw"
          name="animalSex"
          label="Sexe*"
          formApi={form}
          required="Merci de selectionner un sexe."
        >
          {sexe.map((e) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <Select
          icon="paw"
          name="animalSterilise"
          label="Stérilisé*"
          formApi={form}
          required="Merci de selectionner une réponse."
        >
          {boolean.map((e) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <InputDate
          name="animalBirthDate"
          label="Date de naissance*"
          formApi={form}
          required="Merci de renseigner une date."
          maxDate={new Date()}
        />
        <Input
          icon="fingerPrint"
          type="text"
          name="animalIdentification"
          label="Identification"
          formApi={form}
          maxLength={15}
          format={(value) => value?.replace(/\D/g, '').toUpperCase()}
          description="(Si l’animal n’est pas identifié, il devra l’être avant le règlement de la facture)"
        />
      </ExpandLabel>
      <ExpandLabel
        label="Informations demande de soin"
        expand={parts[3]}
        onClick={() => togglePart(3)}
      >
        <Select
          icon="paw"
          name="pathoType"
          label="Type de pathologie*"
          formApi={form}
          required="Merci de selectionner une pathologie."
        >
          {typePathologie.map((e) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <Textarea
          icon="paw"
          label="Descriptions médicales*"
          name="pathoSignes"
          required="Merci de renseigner une description médicale."
          formApi={form}
        />
        <FileDoc
          label="Ajouter pièce.s Jointe.s"
          name="medias"
          formApi={form}
          multiple
        />
        <Input
          icon="euro"
          type="number"
          name="estimateAmount"
          label="Montant du devis*"
          formApi={form}
          required="Merci de renseigner un montant."
        />
        <FileDoc
          label="Joindre le devis*"
          name="devis"
          formApi={form}
          required="Merci d'ajouter un devis."
          info="Les informations ci-dessous ne seront pas transmises au propriétaire"
        />
        <Select
          icon="paw"
          name="pathoEuthanasie"
          label="L’euthanasie a-t-elle été évoquée avec le propriétaire ?*"
          formApi={form}
          required="Merci de selectionner une réponse."
        >
          {boolean.map((e) => (
            <option value={e.id} key={e.id}>
              {e.label}
            </option>
          ))}
        </Select>
        <Textarea
          icon="paw"
          label="Commentaires"
          name="comments"
          formApi={form}
        />
      </ExpandLabel>

      <Button
        loading={isLoading}
        onClick={() => {
          if (Object.keys(errors).length) showAllParts();
        }}
      >
        Enregistrer les modifications
      </Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
};

export default Form;
