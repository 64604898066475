import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import FolderList, { IFolderItem } from '../../../components/FolderList';
import Link from '../../../components/Link';
import Tabs, { Tab } from '../../../components/Tabs';
import { folderStatusAdmin } from '../../../services/constantes';
import money from '../../../img/money.svg';
import people from '../../../img/people.svg';
import ListMembers from '../../../components/ListMembers';
import { formatDevise } from '../../../services/helpers';
import { getFolders } from '../../../services/folders';
import './Home.scss';

function Home(): JSX.Element {
  const user = useSelector(userSelector);
  const [list1, setList1] = useState<IFolderItem[] | null | Error>(null);
  const [list2, setList2] = useState<IFolderItem[] | null | Error>(null);
  const [list3, setList3] = useState<IFolderItem[] | null | Error>(null);

  function moneyCollected() {
    if (!user?.members) return 0;

    const total = user.members.reduce(
      (a, e) => a + (e?.user?.amountPaid || 0) + (e?.user?.donationAmount || 0),
      0
    );
    return formatDevise(total);
  }

  useEffect(() => {
    getFolders().then((folders) => {
      setList1(
        folders
          .map((folder) => ({
            id: folder['@id'],
            title: `${folder.animalName} - ${folder.userProprietaire.user.firstname} ${folder.userProprietaire.user.lastname}`,
            info: folder.etbtName,
            dateCreate: new Date(folder.createdAt),
            notification: !!folder.isUpdate,
            status: folder.status,
            linkId: folder.id,
          }))
          .filter((e) => folderStatusAdmin['a-valider'].includes(e.status))
          .map((folder) => ({
            ...folder,
            link: `/admin/dossiers/a-valider/${folder.linkId}`,
          }))
          .sort((a, b) =>
            a.dateCreate.getTime() < b.dateCreate.getTime() ? 1 : -1
          )
          .slice(0, 3)
      );

      setList2(
        folders
          .map((folder) => ({
            id: folder['@id'],
            title: `${folder.animalName} - ${folder.userProprietaire.user.firstname} ${folder.userProprietaire.user.lastname}`,
            info: folder.etbtName,
            dateCreate: new Date(folder.createdAt),
            notification: !!folder.isUpdate,
            status: folder.status,
            linkId: folder.id,
          }))
          .filter((e) => folderStatusAdmin['a-regler'].includes(e.status))
          .map((folder) => ({
            ...folder,
            link: `/admin/dossiers/a-regler/${folder.linkId}`,
          }))
          .sort((a, b) =>
            a.dateCreate.getTime() < b.dateCreate.getTime() ? 1 : -1
          )
          .slice(0, 3)
      );

      setList3(
        folders
          .filter(
            (folder) =>
              folder.petOwnerNewsComments && folder.petOwnerNewsComments.length
          )
          .map((folder) => {
            return {
              id: folder['@id'],
              title: `${folder.animalName} - ${folder.userProprietaire.user.firstname} ${folder.userProprietaire.user.lastname}`,
              info: folder.etbtName,
              dateCreate: new Date(folder.createdAt),
            };
          })
          .sort((a, b) => {
            if (!a.dateCreate) return +1;
            if (!b.dateCreate) return -1;
            return a.dateCreate.getTime() < b.dateCreate.getTime() ? 1 : -1;
          })
          .slice(0, 3)
      );
    });
  }, []);

  const getLabelValid = useCallback(() => {
    if (list1 === null || list1 === undefined) {
      return 'Dossier à valider';
    }
    if (list1 instanceof Error) {
      return 'Dossier à valider';
    }
    if (!list1.length) {
      return 'Aucun dossier à valider';
    }
    if (list1.length === 1) {
      return '1 dossier à valider';
    }
    return `${list1.length} dossiers à valider`;
  }, [list1]);

  const getLabelTodo = useCallback(() => {
    if (list2 === null || list2 === undefined) {
      return 'Dossier à régler';
    }
    if (list2 instanceof Error) {
      return 'Dossier à régler';
    }
    if (!list2.length) {
      return 'Aucun dossier à régler';
    }
    if (list2.length === 1) {
      return '1 dossier à régler';
    }
    return `${list2.length} dossiers à régler`;
  }, [list2]);

  const getLabelNews = useCallback(() => {
    if (list3 === null || list3 === undefined) {
      return 'Actualité propriétaire';
    }
    if (list3 instanceof Error) {
      return 'Actualité propriétaire';
    }
    if (!list3.length) {
      return 'Aucune actualité propriétaire';
    }
    if (list3.length === 1) {
      return '1 actualité propriétaire';
    }
    return `${list3.length} actualités propriétaires`;
  }, [list3]);

  return (
    <div className="AdminHome">
      <h1 className="AdminHome__title">Tableau de bord</h1>

      {/* TABULATION 1 */}
      <Tabs>
        <Tab title={getLabelValid()}>
          <FolderList
            foldersList={list1}
            notFoundLabel="Aucun dossier à valider..."
            limit={3}
          />
          <Link href="/admin/dossiers/a-valider" className="AdminHome__more">
            Tous les dossiers
          </Link>
        </Tab>
        <Tab title={getLabelTodo()}>
          <FolderList
            foldersList={list2}
            notFoundLabel="Aucun dossier à régler..."
            limit={3}
          />
          <Link href="/admin/dossiers/a-regler" className="AdminHome__more">
            Tous les dossiers
          </Link>
        </Tab>
      </Tabs>

      {/* TABULATION 2 */}
      <Tabs>
        <Tab title={getLabelNews()}>
          <FolderList
            foldersList={list3}
            notFoundLabel="Aucune actualité..."
            limit={3}
          />
          <Link href="/admin/nouvelles-des-animaux" className="AdminHome__more">
            Toutes nos actualités propriétaires
          </Link>
        </Tab>
      </Tabs>

      {/* TABULATION 3 */}
      <Tabs>
        <Tab title="Récapitulatif adhérents">
          <div className="AdminHome__figures">
            <div className="AdminHome__figure">
              <div className="AdminHome__figure__group">
                <img src={money} alt="" className="AdminHome__figure__img" />
                <span className="AdminHome__figure__number">
                  {moneyCollected()} €
                </span>
              </div>
              <span className="AdminHome__figure__label">Argent collecté</span>
            </div>
            <div className="AdminHome__figure">
              <div className="AdminHome__figure__group">
                <img src={people} alt="" className="AdminHome__figure__img" />
                <span className="AdminHome__figure__number">
                  {user?.members?.length || 0}
                </span>
              </div>
              <span className="AdminHome__figure__label">
                Nombre d&apos;adhérents
              </span>
            </div>
          </div>
        </Tab>
        <Tab title="Nos derniers adhérents">
          <ListMembers maxLength={3} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Home;
