import React, { useState } from 'react';
import { ReactComponent as Loader } from '../../../img/loader.svg';
import './Toggle.scss';

interface IToggle {
  children: React.ReactNode;
  status: boolean | null | undefined;
  className?: string;
  onChange?: (e: boolean) => void;
}

const Toggle: React.FC<IToggle> = ({
  children,
  className,
  status: initialStatus,
  onChange,
}: IToggle) => {
  const [status, setStatus] = useState(initialStatus);

  return (
    <div className={`Toggle ${className}`}>
      <span className="Toggle__label">{children}</span>
      <button
        onClick={() => {
          setStatus((e) => {
            if (onChange) onChange(!e);
            return !e;
          });
        }}
        type="button"
        className="Toggle__button"
        data-status={status}
      >
        <div className="Toggle__status" />
      </button>
    </div>
  );
};

Toggle.defaultProps = {
  className: undefined,
  onChange: undefined,
};

export default Toggle;
