import {
  ILogin,
  IUser,
  ICreatePassword,
  IUpdateUser,
  IFolder,
  IMember,
  IContact,
} from './api.interface';
import { setJWT, request, callApi, getDecodedJWT } from './api';
import { store } from '../store';

/*
 * recupere les infos de l'utilisateurser en fonction de son role
 * @return Promise<IUser | null>
 */
export function getUser(): Promise<IUser | null> {
  const token = getDecodedJWT();

  switch (token?.roles[0]) {
    case 'ROLE_ADMIN_VETERICARE':
      return Promise.all([
        request('GET', '/api/users'),
        request('GET', '/api/dossiers'),
        request('GET', '/api/user_veterinaires'),
      ]).then(([users, dossiers, veterinaires]: any) => {
        const user = users['hydra:member'][0][0];
        const folders = dossiers['hydra:member'][0];
        const members = veterinaires['hydra:member'][0];

        return {
          role: 'ROLE_ADMIN_VETERICARE',
          root: 'admin',
          '@id': user['@id'],
          userId: user['@id'].replace('/api/users/', ''),
          email: user.email,
          logo: user.logo,
          billNotification: user.billNotification,
          folderNotif: user.folderNotif,
          petOwnerNewsNotif: user.petOwnerNewsNotif,
          firstname: user.firstname,
          lastname: user.lastname,
          genre: user.genre,
          members: members.map((member: IMember) => ({
            '@id': member['@id'],
            id: member['@id'].replace('/api/user_veterinaires/', ''),
            EtabNom: member.EtabNom,
            EtabVille: member.EtabVille,
            user: {
              amountPaid: member?.user?.amountPaid
                ? member?.user?.amountPaid / 100
                : 0,
              donationAmount: member?.user?.donationAmount
                ? member?.user?.donationAmount / 100
                : 0,
              startSubscription: member?.user?.startSubscription,
              endSubscription: member?.user?.endSubscription,
            },
          })),
          folders:
            folders.map((folder: IFolder) => ({
              '@id': folder['@id'],
              id: folder['@id'].replace('/api/dossiers/', ''),
              createdAt: folder.createdAt,
              status: folder.status,
              etbtName: folder.etbtName,
              animalName: folder.animalName,
              isUpdate: folder.isUpdate,
              '@ownerId': folder.userProprietaire['@id'],
              ownerId: folder.userProprietaire['@id'].replace(
                '/api/user_proprietaires/',
                ''
              ),
              ownerFirstname: folder.userProprietaire.user.firstname,
              ownerLastname: folder.userProprietaire.user.lastname,
            })) || [],
        };
      });

    case 'ROLE_VETERINAIRE':
      return Promise.all([
        request('GET', '/api/users'),
        request('GET', '/api/user_veterinaires'),
      ]).then(([users, veterinaires]: any) => {
        const user = users['hydra:member'][0];
        const member = veterinaires['hydra:member'].length
          ? veterinaires['hydra:member'][0]
          : null;

        return {
          role: 'ROLE_VETERINAIRE',
          root: 'membre',
          '@id': user['@id'],
          userId: user['@id'].replace('/api/users/', ''),
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          genre: user.genre,
          member: member
            ? {
                '@id': member['@id'],
                id: member['@id'].replace('/api/user_veterinaires/', ''),
                EtabAdresse: member.EtabAdresse,
                EtabCP: member.EtabCP,
                EtabNom: member.EtabNom,
                EtabVille: member.EtabVille,
                FactAdresse: member.FactAdresse,
                FactCP: member.FactCP,
                FactNom: member.FactNom,
                FactVille: member.FactVille,
                GroupOrGie: member.GroupOrGie,
                nbOfVeto: member.nbOfVeto,
                SIRET: member.SIRET,
                iban: member.iban,
                bic: member.bic,
                contactReferent: member.contactReferent,
                fonctionRef: member.fonctionRef,
                refEmail: member.refEmail,
                refTel: member.refTel,
                telephone: member.telephone,
                medias: member.medias,
              }
            : null,
          folders: member
            ? member.dossiers.map((folder: IFolder) => ({
                '@id': folder['@id'],
                id: folder['@id'].replace('/api/dossiers/', ''),
                createdAt: folder.createdAt,
                status: folder.status,
                etbtName: folder.etbtName,
                animalName: folder.animalName,
                isUpdate: folder.isUpdate,
                '@ownerId': folder.userProprietaire['@id'],
                ownerId: folder.userProprietaire['@id'].replace(
                  '/api/user_proprietaires/',
                  ''
                ),
                ownerFirstname: folder.userProprietaire.user.firstname,
                ownerLastname: folder.userProprietaire.user.lastname,
              }))
            : [],
        };
      });

    case 'ROLE_USER':
      return Promise.all([
        request('GET', '/api/users'),
        request('GET', '/api/user_proprietaires'),
      ]).then(([users, proprietaires]: any) => {
        const user = users['hydra:member'][0];

        const owner = proprietaires['hydra:member'].length
          ? proprietaires['hydra:member'][0]
          : null;
        const folder =
          owner.dossiers && owner.dossiers.length ? owner.dossiers[0] : {};

        return {
          role: 'ROLE_USER',
          root: 'proprietaire',
          '@id': user['@id'],
          userId: user['@id'].replace('/api/users/', ''),
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          genre: user.genre,
          logo: user.logo,
          owner: owner
            ? {
                '@id': owner['@id'],
                id: owner['@id'].replace('/api/user_proprietaires/', ''),
                adress: owner.adress,
                cp: owner.cp,
                ville: owner.ville,
                phone: owner.phone,
              }
            : null,
          folder: {
            '@id': folder['@id'],
            id: folder['@id'].replace('/api/dossiers/', ''),
            createdAt: folder.createdAt,
            status: folder.status,
            etbtName: folder.etbtName,
            animalName: folder.animalName,
            isUpdate: folder.isUpdate,
            animalBirthDate: folder.animalBirthDate,
            avisImpot: folder.avisImpot,
          },
        };
      });

    default:
      return Promise.resolve(null);
  }
}

/*
 * authentification de l'utilisateur
 * @param { email, password }: ILogin
 * @return Promise<IUser | null>
 */
export function authentification({
  email,
  password,
}: ILogin): Promise<IUser | null> {
  return callApi('POST', '/authentication_token', { email, password }).then(
    ({ data }: any) => {
      setJWT(data.token);
      return getUser();
    }
  );
}

/*
 * deconnecte l'utilisateur
 * @return Promise<null>
 */
export function clearUser(): Promise<void> {
  return new Promise((resolve) => {
    setJWT(null);
    setTimeout(() => resolve(), 700);
  });
}

/*
 * update user_proprietaires param
 * @param param<any>
 * @return Promise<any>
 */
export function putOwnerIdById(
  ownerId: string | number | undefined,
  param: {
    adress?: string;
    cp?: string;
    ville?: string;
    phone?: string;
  }
): Promise<any> {
  return request('PUT', `/api/user_proprietaires/${ownerId}`, param);
}

/*
 * update user param
 * @param param<IUpdateUser>
 * @return Promise<any>
 */
export function putUserById(
  userId: string | number | undefined,
  param: IUpdateUser
): Promise<any> {
  return request('PUT', `/api/users/${userId}`, param);
}

/*
 * update user param
 * @param param<IUpdateUser>
 * @return Promise<any>
 */
export function putUser(param: IUpdateUser): Promise<any> {
  const { user } = store.getState();
  return putUserById(user.user?.userId, param);
}

/*
 * update user email
 * @param email<string>
 * @return Promise
 */
export function updateUserEmail(email: string): Promise<void> {
  const { user } = store.getState();
  return request('PUT', `/api/users/${user.user?.userId}`, { email }).then(
    ({ token }: any) => {
      setJWT(token);
    }
  );
}

/*
 * creation du password du veterinaire
 * @param token:string, password:string
 * @return Promse<any>
 */
export function createPassword({
  token,
  password,
}: ICreatePassword): Promise<any> {
  return request('POST', `/creation-du-mot-de-passe/${token}`, {
    password,
  }).then((data: any) => {
    return authentification({ email: data.user.email, password });
  });
}

/*
 * contact
 * @param {referent:string, phone:string, email:string, message:string}
 * @return Promse<any>
 */
export function contact({
  referent,
  phone,
  email,
  message,
}: IContact): Promise<any> {
  return request('POST', `/api/contacts`, {
    referent,
    phone,
    email,
    message,
  });
}

/*
 * mot de passe oublié
 * @param {userEmail:string}
 * @return Promse<any>
 */
export function forgotPassword({
  userEmail,
}: {
  userEmail: string;
}): Promise<any> {
  return request('POST', `/api/nouveau-mot-de-passe`, {
    userEmail,
  });
}

/*
 * changement du mot de passe
 * @param {userEmail:string}
 * @return Promse<any>
 */
export function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<any> {
  return request('POST', `/nouveau-mot-de-passe/${token}`, { password });
}
