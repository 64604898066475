import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  hideModalAction,
  showModalAction,
  showErrorModalAction,
} from '../../store/modal/modal.actions';
import { Input, CheckPassword, Button } from '../Fields';
import { putUser } from '../../services/users';
import './Forms.scss';

interface IForm {
  white?: boolean;
}

function Form({ white }: IForm): JSX.Element {
  const isValid = useRef(false);
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      oldpass: '',
      newPassword: '',
    },
  });

  const { handleSubmit, getValues, formState } = form;

  function onSubmit() {
    if (formState.isValid && isValid.current) {
      setFormLoading(true);
      putUser({
        oldpass: getValues().oldpass,
        password: getValues().newPassword,
      })
        .then(() => {
          dispatch(hideModalAction());
          setTimeout(() => {
            dispatch(showModalAction('Le mot de passe a bien été modifié !'));
          }, 300);
        })
        .catch((err) => {
          dispatch(hideModalAction());
          setTimeout(() => {
            dispatch(showErrorModalAction(`${err.status} | ${err.message}`));
          }, 300);
        })
        .finally(() => setFormLoading(false));
    }
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Input
        icon="password"
        type="password"
        name="oldpass"
        label="Mot de passe*"
        formApi={form}
        required="Merci de renseigner votre mot de passe."
        white={white}
      />

      <CheckPassword
        name="newPassword"
        label1="Nouveau mot de passe*"
        label2="Confirmation nouveau mot de passe*"
        requiredLabel1="Merci de renseigner votre nouveau mot de passe."
        requiredLabel2="Merci de confirmer votre nouveau mot de passe."
        formApi={form}
        white={white}
        isValid={(e) => {
          isValid.current = e;
        }}
      />

      <Button loading={formLoading}>Valider</Button>
    </form>
  );
}

Form.defaultProps = {
  white: false,
};

export default Form;
