import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '../../../store/modal/modal.actions';
import { ExpandLabel } from '../../../components/Expand';
import { Button } from '../../../components/Fields';
import {
  UpdatePassword,
  UpdateEmail,
  UpdateLogo,
  FormOwnerUpdate,
} from '../../../components/Forms';
import './Account.scss';

function Account(): JSX.Element {
  const dispatch = useDispatch();
  const [parts, setParts] = useState<boolean[]>([true, false]);

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  return (
    <div className="OwnerAccount">
      <div className="OwnerAccount__header">
        <h1 className="OwnerAccount__header__title">Mon compte</h1>
      </div>
      <div className="OwnerAccount__content">
        <ExpandLabel
          onClick={() => togglePart(0)}
          expand={parts[0]}
          label="Profil"
        >
          <UpdateEmail />
          <Button
            onClick={() => {
              dispatch(showModalAction(<UpdatePassword white />));
            }}
          >
            Changer le mot de passe
          </Button>
          <UpdateLogo label="Votre photo de profil :" />
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(1)}
          expand={parts[1]}
          label="Coordonnées"
        >
          <FormOwnerUpdate />
        </ExpandLabel>
      </div>
    </div>
  );
}

export default Account;
