import { IPostUserOwner } from './api.interface';
import { request } from './api';

/*
 * recupération des données du proprietaire
 * @param userId:string
 * @return Promse<any>
 */
export function getOwnerUser(userId: string): Promise<any> {
  return request('GET', `/api/user_proprietaires/${userId}`);
}

/*
 * modification des données du proprietaire
 * @param params:IPostUserOwner
 * @return Promse<any>
 */
export function updateOwnerUser(
  userId: string | undefined,
  userOwnerId: string | undefined,
  params: IPostUserOwner
): Promise<any> {
  return Promise.all([
    request('PUT', `/api/user_proprietaires/${userOwnerId}`, {
      adress: params.adress,
      cp: params.cp,
      ville: params.ville,
      phone: params.phone,
    }),
    request('PUT', `/api/users/${userId}`, {
      genre: params.genre,
      firstname: params.firstname,
      lastname: params.lastname,
    }),
  ]);
}
