import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUpdateFolder } from '../../store/user/user.actions';
import { Input, FileImg, FileDoc, Textarea, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  folderId: string;
  onSubmitted?: () => void;
  white?: boolean;
}

function Form({ folderId, onSubmitted, white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { handleSubmit, getValues } = form;

  function onSubmit() {
    setLoading(true);
    const values = getValues();
    dispatch(
      setUpdateFolder(folderId, {
        ...values,
        suiviPJ: values.suiviPJ || [],
        suiviPhotos: values.suiviPhotos || [],
        billAmount: parseFloat(values.billAmount),
      })
    )
      .then(() => {
        if (onSubmitted) onSubmitted();
      })
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FileDoc
        name="suiviPJ"
        formApi={form}
        multiple
        label="Pièce.s jointe.s"
        white={white}
      />
      <Textarea
        name="report"
        label="Compte rendu*"
        formApi={form}
        required="Merci de renseigner un compte rendu"
        autoHeight
        white={white}
      />
      <FileImg
        name="suiviPhotos"
        formApi={form}
        multiple
        label="Photo.s (fortement recommandée.s)"
        white={white}
      />
      <Input
        icon="euro"
        type="number"
        name="billAmount"
        label="Saisir le montant de la facture*"
        formApi={form}
        required="Merci de renseigner un montant."
        white={white}
      />
      <FileDoc
        name="facture"
        formApi={form}
        label="Facture*"
        required="Merci d'uploader une facture"
        white={white}
      />
      <Textarea
        name="suiviComment"
        label="Commentaires"
        formApi={form}
        autoHeight
        white={white}
      />
      <Button loading={isLoading}>Finaliser le dossier</Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
  white: false,
};

export default Form;
