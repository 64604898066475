import React, { useState, useEffect } from 'react';
import { getMedia } from '../../../services/folders';

interface IImg {
  file?: File;
  media?: string;
  className?: string;
}

function ImgUploaded({ file, media, className }: IImg): JSX.Element | null {
  const [newSrc, setNewSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file) {
      if (['image/gif', 'image/png', 'image/jpeg'].includes(file.type)) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) =>
          setNewSrc(e?.target?.result ? `${e?.target?.result}` : undefined);
        reader.readAsDataURL(file);
      } else {
        setNewSrc(undefined);
      }
    } else if (media) {
      getMedia(parseInt(media.replace('/api/medias/', ''), 10)).then(
        ({ fileLink }) => {
          setNewSrc(`${process.env.REACT_APP_URL_API_DRUPAL}${fileLink}`);
        }
      );
    }
  }, [media, file]);

  if (newSrc) return <img src={newSrc} alt="" className={className} />;
  return <span className={className} />;
}

ImgUploaded.defaultProps = {
  file: undefined,
  media: undefined,
  className: undefined,
};

export default ImgUploaded;
