import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateMember } from '../../store/user/user.actions';
import { Input, Select, Button } from '../Fields';
import { fonction } from '../../services/constantes';
import './Forms.scss';

interface IForm {
  label: string;
  onSubmitted?: any;
}

function Form({ label, onSubmitted }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [isLoading, setLoading] = useState<boolean>(false);

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      contactReferent: user?.member?.contactReferent || undefined,
      fonctionRef: user?.member?.fonctionRef || undefined,
      refTel: user?.member?.refTel || undefined,
      refEmail: user?.member?.refEmail || undefined,
    },
  });

  const { handleSubmit, getValues } = form;

  function preSubmit() {
    if (onSubmitted) {
      onSubmitted(getValues());
    } else {
      setLoading(true);

      dispatch(
        setUpdateMember({
          ...getValues(),
          fonctionRef: parseInt(`${getValues().fonctionRef}`, 10) || undefined,
        })
      ).finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(preSubmit)} noValidate>
      <Input
        icon="user"
        type="text"
        name="contactReferent"
        label="Prénom & Nom*"
        formApi={form}
        required="Merci de renseigner un nom."
      />

      <Select
        icon="user"
        name="fonctionRef"
        label="Fonction*"
        formApi={form}
        required="Merci de selectionner une fonction."
      >
        {fonction.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>

      <Input
        icon="phone"
        type="tel"
        name="refTel"
        label="Téléphone*"
        formApi={form}
        required="Merci de renseigner un numéro de téléphone."
        inputMode="tel"
        maxLength={14}
        pattern={{
          value: /([0-9]{2}\s?){5}/g,
          message: 'Merci de renseigner un numéro de téléphone valide.',
        }}
        format={(value) =>
          value
            ?.replace(/\D/g, '')
            ?.match(/.{1,2}/g)
            ?.join(' ')
        }
      />

      <Input
        icon="email"
        type="email"
        name="refEmail"
        label="Email*"
        formApi={form}
        required="Merci de renseigner un email."
        pattern={{
          value: /.+@.+\..+/,
          message: 'Merci de renseigner une adresse mail valide.',
        }}
      />

      <Button loading={isLoading}>{label}</Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
};

export default Form;
