import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificationFolderAction } from '../../store/user/user.actions';
import Loading from '../Loading';
import { ExpandLabel } from '../Expand';
import { Line, Text } from '../Content';
import { getFolder } from '../../services/folders';
import { IFolder } from '../../services/api.interface';
import { formatDate, formatDevise } from '../../services/helpers';
import { MissingElements, Facture } from '../Forms';
import {
  getLabelEspece,
  getTypePathologie,
  getSexe,
  getBoolean,
} from '../../services/constantes';
import DownloadMedia from '../DownloadMedia';
import PopinMedia from '../PopinMedia';
import './DetailFolderMember.scss';

interface IDetailFolderMember {
  folderId: string;
}

function DetailFolderMember({ folderId }: IDetailFolderMember): JSX.Element {
  const dispatch = useDispatch();
  const [folder, setFolder] = useState<IFolder | null>(null);

  const setFolderData = useCallback(() => {
    getFolder(folderId).then((e) => {
      if (e.isUpdate && [3, 5].includes(e.status)) {
        dispatch(setNotificationFolderAction(folderId));
      }
      setFolder(e);
    });
  }, [folderId, dispatch]);

  useEffect(() => {
    setFolderData();
  }, [setFolderData]);

  if (!folder) return <Loading small />;

  return (
    <div className="DetailFolderMember">
      <Text label="N°">{folder.id}</Text>
      <Text label="Espèce">{getLabelEspece(folder.animalSpecies)}</Text>
      <Text label="Date de naissance">
        {formatDate(folder.animalBirthDate)}
      </Text>
      <Text label="Type de pathologie">
        {getTypePathologie(folder.pathoType)}
      </Text>
      <Text label="Montant du devis">
        <b>{formatDevise(folder.estimateAmount)}€</b>
      </Text>
      {folder.maxAmountSupported && (
        <Text label="Montant maximim de la prise en charge Vetericare">
          <b>{formatDevise(folder.maxAmountSupported)} €</b>
        </Text>
      )}
      <ExpandLabel>
        <div className="DetailFolderMember__group">
          <Text label="Sexe">{getSexe(folder.animalSex)}</Text>
          <Text label="N° identification">{folder.animalIdentification}</Text>
          <Text label="Stérilisation">
            {getBoolean(folder.animalSterilise)}
          </Text>
          <Text label="Descriptions médicales">{folder.pathoSignes}</Text>
          <Text label="L’euthanasie a-t-elle été évoquée avec le propriétaire">
            {getBoolean(folder.pathoEuthanasie)}
          </Text>
          <Text label="Commentaires">
            {folder.comments.length ? (
              folder.comments.map(({ comment, ...rest }) => (
                <span key={rest['@id']} className="DetailFolderAdmin__comment">
                  <b>{comment}</b>
                </span>
              ))
            ) : (
              <b>Aucun commentaire.</b>
            )}
          </Text>
          <Line />
          {folder?.medias?.map((mediaId: string, i) => (
            <DownloadMedia
              key={mediaId}
              mediaId={mediaId}
              name={`Pièce jointe ${i + 1}`}
            />
          ))}
          <DownloadMedia
            mediaId={folder.devis}
            name={`Pièce jointe ${folder.medias.length + 1}`}
          />

          {[6, 8].includes(folder.status) && (
            <>
              <Line />
              <div className="DetailFolderMember__subtitle">
                Suivi de l&apos;animal
              </div>
              <Text label="Compte rendu">{folder.report}</Text>
              {folder.suiviPJ.map((mediaId: string, i) => (
                <DownloadMedia
                  key={mediaId}
                  mediaId={mediaId}
                  name={`Pièce jointe ${i + 1}`}
                />
              ))}
              <p className="DetailFolderMember__text">Photos</p>
              <p className="DetailFolderMember__medias">
                {folder.suiviPhotos.map((mediaId: string) => (
                  <PopinMedia key={mediaId} mediaId={mediaId} />
                ))}
              </p>
              <Text label="Commentaires">{folder.suiviComment}</Text>
              <Text label="Montant de la facture">
                <b>{formatDevise(folder.billAmount)} €</b>
              </Text>
              <DownloadMedia mediaId={folder.facture} name="Facture" />
            </>
          )}

          {folder.status === 3 && (
            <>
              <Line />
              <div className="DetailFolderMember__subtitle">
                Suivi de l&apos;animal
              </div>
              <Facture
                folderId={folder?.id}
                onSubmitted={() => setFolderData()}
                white
              />
            </>
          )}

          {folder.status === 5 && (
            <>
              <Line />
              <div className="DetailFolderMember__subtitle">
                Les informations complémentaires ci-dessous ne seront pas
                transmises au propriétaire
              </div>
              <MissingElements
                folderId={folder?.id}
                onSubmitted={() => setFolderData()}
                white
              />
            </>
          )}
        </div>
      </ExpandLabel>
    </div>
  );
}

export default DetailFolderMember;
