import React from 'react';

interface ITab {
  title: string;
  children: React.ReactNode;
}

function Tab({ children, title }: ITab): JSX.Element {
  return <div className="Tab">{children}</div>;
}

export default Tab;
