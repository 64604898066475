/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Link from './Link';
import { ReactComponent as FolderValidate } from '../../img/folder-validate.svg';
import { ReactComponent as FolderPending } from '../../img/folder-pending.svg';
import { ReactComponent as FolderProgress } from '../../img/folder-progress.svg';
import { ReactComponent as FolderSettle } from '../../img/folder-settle.svg';
import { ReactComponent as FolderClose } from '../../img/folder-close.svg';
import { ReactComponent as FolderReject } from '../../img/folder-reject.svg';
import './LinkFolder.scss';

interface ILinkFolder {
  children: React.ReactNode;
  href: string;
  notification?: number;

  folderValidate?: boolean;
  folderPending?: boolean;
  folderProgress?: boolean;
  folderSettle?: boolean;
  folderClose?: boolean;
  folderReject?: boolean;
}

function LinkFolder({
  children,
  href,
  notification,
  folderValidate,
  folderPending,
  folderProgress,
  folderSettle,
  folderClose,
  folderReject,
}: ILinkFolder): JSX.Element {
  return (
    <Link href={href} className="LinkFolder" isCurrent>
      <div className="LinkFolder__icon">
        {(() => {
          if (folderValidate)
            return <FolderValidate className="LinkFolder__svg" />;
          if (folderPending)
            return <FolderPending className="LinkFolder__svg" />;
          if (folderProgress)
            return <FolderProgress className="LinkFolder__svg" />;
          if (folderSettle) return <FolderSettle className="LinkFolder__svg" />;
          if (folderClose) return <FolderClose className="LinkFolder__svg" />;
          if (folderReject) return <FolderReject className="LinkFolder__svg" />;
          return null;
        })()}
      </div>
      <span className="LinkFolder__title">{children}</span>
      {!!notification && (
        <span className="LinkFolder__notification">{notification}</span>
      )}
    </Link>
  );
}

LinkFolder.defaultProps = {
  notification: 0,
  folderValidate: false,
  folderPending: false,
  folderProgress: false,
  folderSettle: false,
  folderClose: false,
  folderReject: false,
};

export default LinkFolder;
