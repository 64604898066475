import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import './Select.scss';

interface ISelect {
  children: React.ReactNode;
  name: string;
  label: string;
  icon?: string;
  formApi: UseFormMethods<any>;
  required?: string;
  disabled?: boolean;
}

function Select({
  children,
  name,
  label,
  icon,
  formApi,
  required,
  disabled,
}: ISelect): JSX.Element {
  const { register, errors, watch } = formApi;
  const value = watch(name, false);

  return (
    <p className={`Select ${disabled ? '-disabled' : ''}`}>
      <label className="Select__group" htmlFor={name}>
        <span className="Select__label">{label}</span>
        <span className="Select__fieldGroup">
          {icon && <span className={`Select__icon -${icon}`} />}
          <select
            className={`Select__field ${
              errors && errors[name] ? '-error' : ''
            } ${value === '' ? '-placeholder' : ''}`}
            name={name}
            id={name}
            defaultValue={value || ''}
            ref={register({ required })}
            disabled={disabled}
            onChange={({ target }) => target.blur()}
          >
            <option value="" disabled>
              Choississez...
            </option>
            {children}
          </select>
        </span>

        {errors && errors[name] && (
          <span className="Select__error">{errors[name].message}</span>
        )}
      </label>
    </p>
  );
}

Select.defaultProps = {
  icon: null,
  required: null,
  disabled: null,
};

export default Select;
