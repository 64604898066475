import React, { useState } from 'react';
import Expand from './Expand';
import './Expand.scss';

interface IExpandLabel {
  children: React.ReactNode;
  label?: string;
  expand?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function ExpandLabel({
  children,
  label,
  expand = false,
  onClick,
}: IExpandLabel): JSX.Element {
  const [status, setStatus] = useState<boolean>(false);

  return (
    <div className="ExpandLabel">
      <button
        className="ExpandLabel__button"
        onClick={(e) => {
          if (onClick) onClick(e);
          else setStatus((s) => !s);
        }}
        title={label}
        type="button"
      >
        <span className="ExpandLabel__label">
          {label && <span className="ExpandLabel__label__text">{label}</span>}
          <span className="ExpandLabel__label__hr" />
        </span>
        <span
          className={`ExpandLabel__status ${expand || status ? '-open' : ''}`}
        />
      </button>
      <Expand expand={expand || status}>{children}</Expand>
    </div>
  );
}

ExpandLabel.defaultProps = {
  label: undefined,
  expand: undefined,
  onClick: undefined,
};

export default ExpandLabel;
