import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUpdateFolder } from '../../store/user/user.actions';
import { Input, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  folderId: string;
  max?: number;
  white?: boolean;
}

function Form({ folderId, max, white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { handleSubmit, getValues } = form;
  function onSubmit() {
    const finalAmountSupported = parseInt(getValues().finalAmountSupported, 10);
    setLoading(true);
    dispatch(
      setUpdateFolder(
        folderId,
        { finalAmountSupported, status: 8 },
        'Le dossier a bien été modifié !'
      )
    ).finally(() => setLoading(false));
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Input
        icon="euro"
        type="number"
        inputMode="numeric"
        name="finalAmountSupported"
        label="Saisir le montant définitif pris en charge par Vetericare*"
        formApi={form}
        required="Merci de renseigner un montant."
        min={0}
        max={max}
        white={white}
      />
      <Button loading={isLoading}>Envoyer</Button>
    </form>
  );
}

Form.defaultProps = {
  max: null,
  white: false,
};

export default Form;
