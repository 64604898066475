import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setValidateFolder } from '../../../store/user/user.actions';
import { userSelector } from '../../../store/user/user.selectors';
import { Input, Button, Checkbox } from '../../../components/Fields';
import { ExpandLabel } from '../../../components/Expand';
import { getFolder } from '../../../services/folders';
import { getTypePathologie } from '../../../services/constantes';
import { formatDevise } from '../../../services/helpers';
import Link, { LinkButton } from '../../../components/Link';
import { FormOwner1, FormOwner2 } from '../../../components/Forms';
import {
  showModalAction,
  hideModalAction,
} from '../../../store/modal/modal.actions';
import './Folder.scss';

function Folder(): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const folder = user?.folder;
  const formPart1 = useRef<Record<string, any> | null>(null);
  const formPart2 = useRef<Record<string, any> | null>(null);
  const [parts, setParts] = useState<boolean[]>([true, false, false, false]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      pathoType: '',
      estimateAmount: '',
    },
  });
  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (folder?.id) {
      getFolder(folder.id).then((folderDetail) => {
        setValue('pathoType', getTypePathologie(folderDetail.pathoType));
        setValue(
          'estimateAmount',
          `${formatDevise(folderDetail.estimateAmount)} €`
        );
      });
    }
  }, [folder, setValue]);

  function showPart(index: number, closeOther = false) {
    setParts((e) =>
      e.map((status, i) => {
        if (closeOther) {
          return i === index;
        }
        return i === index ? true : status;
      })
    );
  }

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  function onSubmit() {
    if (!formPart1.current) {
      showPart(0);
      return;
    }
    if (!formPart2.current) {
      showPart(1);
      return;
    }

    setLoading(true);

    const val = {
      genre: formPart1.current.genre,
      firstname: formPart1.current.firstname,
      lastname: formPart1.current.lastname,
      adress: formPart1.current.adress,
      cp: formPart1.current.cp,
      ville: formPart1.current.ville,
      phone: formPart1.current.phone,
      avisImpot: formPart1.current.avisImpot,

      animalSpecies: formPart2.current.animalSpecies,
      animalRace: formPart2.current.animalRace,
      animalSex: formPart2.current.animalSex,
      animalSterilise: formPart2.current.animalSterilise,
      animalBirthDate: formPart2.current.animalBirthDate,
      animalIdentification: formPart2.current.animalIdentification,
      suiviPhotoProprio: formPart2.current.suiviPhotoProprio,
    };

    dispatch(setValidateFolder(folder?.id, val))
      .then(() => {
        dispatch(
          showModalAction(
            <div>
              <div style={{ marginBottom: '20px' }}>
                Votre dossier a bien été enregistré !
              </div>
              <LinkButton href="/" onClick={() => dispatch(hideModalAction())}>
                Retour à la page d&apos;accueil
              </LinkButton>
            </div>,
            {
              closeBack: false,
            }
          )
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="OwnerFolder">
      <h1 className="OwnerFolder__title">Dossier</h1>
      <ExpandLabel
        onClick={() => togglePart(0)}
        expand={parts[0]}
        label="Vos coordonnées"
      >
        <div className="OwnerFolder__group">
          <FormOwner1
            onSubmitted={(v) => {
              formPart1.current = v;
              showPart(1, true);
            }}
          />
        </div>
      </ExpandLabel>
      <ExpandLabel
        onClick={() => togglePart(1)}
        expand={parts[1]}
        label="Votre animal"
      >
        <div className="OwnerFolder__group">
          <FormOwner2
            onSubmitted={(v) => {
              formPart2.current = v;
              showPart(2, true);
            }}
          />
        </div>
      </ExpandLabel>
      <ExpandLabel
        onClick={() => togglePart(2)}
        expand={parts[2]}
        label="Informations demande de soins"
      >
        <div className="OwnerFolder__group">
          <Input
            icon="paw"
            type="text"
            name="pathoType"
            label="Type de pathologie*"
            formApi={form}
            disabled
          />
          <Input
            icon="euro"
            type="text"
            name="estimateAmount"
            label="Montant du devis*"
            formApi={form}
            disabled
          />
          <Button type="button" onClick={() => showPart(3, true)}>
            Valider
          </Button>
        </div>
      </ExpandLabel>
      <ExpandLabel
        onClick={() => togglePart(3)}
        expand={parts[3]}
        label="Conditions d'utilisation"
      >
        <div className="OwnerFolder__group">
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            noValidate
          >
            <Checkbox name="confirm1" formApi={form} required>
              Je confirme faire une demande d&apos;aide au financement à
              Vetericare pour les soins de mon animal.*
            </Checkbox>
            <Checkbox name="confirm2" formApi={form} required>
              J&apos;autorise mon vétérinaire à transmettre à Vetericare les
              informations du dossier médical de mon animal en lien avec cette
              demande d&apos;aide.*
            </Checkbox>
            <Checkbox name="confirm3" formApi={form} required>
              J&apos;autorise Vetericare à utiliser les données, images et
              vidéos transmises par mon vétérinaire ou moi-même afin de
              communiquer sur les actions de l&apos;association.*
            </Checkbox>
            <Checkbox name="confirm4" formApi={form} required>
              En cas d&apos;acceptation de mon dossier, je m&apos;engage à
              suivre la formation proposée par Vetericare et à donner
              régulièrement des nouvelles de mon animal à l&apos;association.*
            </Checkbox>
            <Checkbox name="confirm5" formApi={form} required>
              J&apos;autorise Vetericare à virer directement le montant de
              l&apos;aide financière qu&apos;elle m&apos;octroie sur le compte
              de la clinique vétérinaire en règlement des soins effectués sur
              mon animal.*
            </Checkbox>
            <p className="OwnerFolder__text">
              Pour retrouver les conditions d’utilisation du service, vous
              pouvez les retrouver en cliquant sur ce lien :{' '}
              <Link
                className="OwnerFolder__link"
                href="https://google.com"
                icon
              >
                Condition d&apos;utilisation Vetericare
              </Link>
            </p>
            <Button loading={isLoading}>Valider & envoyer</Button>
          </form>
        </div>
      </ExpandLabel>
    </div>
  );
}

export default Folder;
