interface IConstantes {
  id: number;
  label: string;
}

const getLabelById = (
  constante: IConstantes[],
  id: string | number
): string | null => {
  const key = parseInt(`${id}`, 10) || id;
  const item = constante.find((e) => e.id === key);
  return item ? item.label : null;
};

export const typeEtablissement = [
  { id: 1, label: 'Cabinet' },
  { id: 2, label: 'Clinique' },
  { id: 3, label: 'Centre hospitalier vétérinaire' },
  { id: 4, label: 'Centre de référés' },
];

export const getLabelTypeEtablissement = (
  id: string | number | null
): string | null => {
  return getLabelById(typeEtablissement, id || 0);
};

export const fonction = [
  { id: 1, label: 'Vétérinaire' },
  { id: 2, label: 'ASV' },
  { id: 3, label: 'Personnel administratif' },
];
export const getFonction = (id: string | number): string | null => {
  return getLabelById(fonction, id);
};

export const genre = [
  { id: 1, label: 'Madame' },
  { id: 2, label: 'Monsieur' },
];
export const getGenre = (id: string | number): string | null => {
  return getLabelById(genre, id);
};

export const sexe = [
  { id: 1, label: 'Femelle' },
  { id: 2, label: 'Mâle' },
];
export const getSexe = (id: string | number): string | null => {
  return getLabelById(sexe, id);
};

export const espece: IConstantes[] = [
  { id: 1, label: 'Chien' },
  { id: 2, label: 'Chat' },
];

export const getLabelEspece = (id: string | number): string | null => {
  return getLabelById(espece, id);
};

export const typePathologie = [
  { id: 1, label: 'Orthopédique' },
  { id: 2, label: 'Abdominale' },
  { id: 3, label: 'Neurologique' },
  { id: 4, label: 'Autre' },
];
export const getTypePathologie = (id: string | number): string | null => {
  return getLabelById(typePathologie, id);
};

export const boolean = [
  { id: 2, label: 'Oui' },
  { id: 1, label: 'Non' },
];
export const getBoolean = (id: string | number): string | null => {
  return getLabelById(boolean, id);
};

/* 

Status Folders :
0 => IS_NEW
1 => IS_TO_COMPLETE
2 => IS_TO_INSTRUCT
3 => IS_ACCEPTED
4 => IS_REFUSED
5 => IS_TO_COMPLETE_AGAIN
6 => IS_TO_BE_PAYED
8 => IS_CLOSED

*/

export const folderStatusVeto: Record<string, number[]> = {
  'en-attente': [1, 2, 5],
  'en-cours': [3, 6],
  clotures: [8],
  refuses: [4],
};
export const folderStatusAdmin: Record<string, number[]> = {
  'a-valider': [2],
  'en-attente': [1, 5],
  'en-cours': [3],
  'a-regler': [6],
  clotures: [8],
  refuses: [4],
};

export const memberFolderStatus: { id: number; status: string }[] = [
  { id: 1, status: "En attente avis d'imposition propriétaire" },
  { id: 2, status: 'En attente validation vétéricare' },
  { id: 3, status: 'En attente facture vétérinaire' },
  // { id: 4, status: 'Refusé' },
  { id: 5, status: "En attente d'informations supplémentaires" },
  { id: 6, status: 'En attente paiement vétéricare' },
  // { id: 8, status: 'Fermé' },
];

export const getMemberFolderStatusById = (folderId: number): string | null => {
  const f = memberFolderStatus.find((folder) => folder.id === folderId);
  if (f) return f.status;
  return null;
};

export const adminFolderStatus: { id: number; status: string }[] = [
  { id: 1, status: "En attente avis d'imposition propriétaire" },
  { id: 2, status: 'En attente validation vétéricare' },
  { id: 3, status: 'En attente facture vétérinaire' },
  // { id: 4, status: 'Refusé' },
  { id: 5, status: 'En attente pièce(s) manquante(s) vétérinaire' },
  { id: 6, status: 'En attente paiement vétéricare' },
  // { id: 8, status: 'Fermé' },
];

export const getAdminFolderStatusById = (folderId: number): string | null => {
  const f = adminFolderStatus.find((folder) => folder.id === folderId);
  if (f) return f.status;
  return null;
};

export const folderOwnerStatus: { id: number; status: string }[] = [
  { id: 1, status: 'En attente' },
  { id: 2, status: 'En cours' },
  { id: 3, status: 'En cours' },
  { id: 4, status: 'Dossier refusé' },
  { id: 5, status: 'En cours' },
  { id: 6, status: 'En cours' },
  { id: 8, status: 'Dossier cloturés' },
];

export const getFolderOwnerStatusById = (folderId: number): string | null => {
  const f = folderOwnerStatus.find((folder) => folder.id === folderId);
  if (f) return f.status;
  return null;
};
