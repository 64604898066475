import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  showModalAction,
  showErrorModalAction,
} from '../../store/modal/modal.actions';
import { Input, Button } from '../../components/Fields';
import Link from '../../components/Link';
import './ForgetPassword.scss';
import { ReactComponent as Logo } from '../../img/logo.svg';
import { forgotPassword } from '../../services/users';

function ForgetPassword(): JSX.Element {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onTouched',
    defaultValues: { userEmail: '' },
  });
  const { handleSubmit, getValues } = form;

  function onSubmit() {
    setFormLoading(true);
    forgotPassword(getValues())
      .then((e) => {
        dispatch(
          showModalAction(
            <span>
              Un email de <b>confirmation</b> vous a été envoyé&nbsp;!
            </span>
          )
        );
      })
      .catch((err) => {
        dispatch(showErrorModalAction(`${err.status} | ${err.message}`));
      })
      .finally(() => setFormLoading(false));
  }

  return (
    <div className="ForgetPassword">
      <div>
        <Link
          className="ForgetPassword__back"
          href="/login"
          title="Retour"
          ariaLabel="Retour"
        />
      </div>
      <div className="ForgetPassword__content">
        <Logo className="ForgetPassword__logo" />
        <h1 className="ForgetPassword__title">Mot de passe oublié</h1>
        <p className="ForgetPassword__text">
          Nous vous enverrons un mail pour définir votre nouveau mot de passe.
        </p>
      </div>

      <form
        className="ForgetPassword__form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="ForgetPassword__form__fields">
          <Input
            icon="email"
            type="email"
            name="userEmail"
            label="Adresse mail"
            formApi={form}
            required="Merci de renseigner une adresse mail."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse mail valide.',
            }}
          />
        </div>

        <div className="ForgetPassword__form__buttons">
          <Button loading={formLoading}>Envoyer</Button>
        </div>
      </form>

      <div className="ForgetPassword__footer" />
    </div>
  );
}

export default ForgetPassword;
