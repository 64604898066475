import { Dispatch } from 'redux';
import { store } from '../index';
import {
  ILogin,
  IUpdateFolder,
  IPostUserMembers,
  IPostUserOwner,
} from '../../services/api.interface';
import { IAction } from './user.interface';
import {
  updateFolder,
  createFolder,
  validateFolder,
} from '../../services/folders';
import {
  authentification,
  getUser,
  clearUser,
  createPassword,
  putUser,
  updateUserEmail,
} from '../../services/users';
import { updateOwnerUser } from '../../services/owners';
import { subscribeMember, updateMember } from '../../services/members';

import { SET_USER_ACTION, SET_FOLDER_ACTION } from './user.reducer';
import { showErrorModalAction, showModalAction } from '../modal/modal.actions';

export function setUserAction(user: IAction['payload']['user']): IAction {
  return { type: SET_USER_ACTION, payload: { user } };
}

export function setLoadingAction(loading: boolean): IAction {
  return { type: SET_USER_ACTION, payload: { loading } };
}

export function setReadyAction(ready: boolean): IAction {
  return { type: SET_USER_ACTION, payload: { ready } };
}

export const setNotificationFolderAction: any = (folderId: string) => (
  dispatch: Dispatch
) => {
  return updateFolder(folderId, { isUpdate: false }).then(() => {
    dispatch({
      type: SET_FOLDER_ACTION,
      payload: { folderId, parameters: { isUpdate: false } },
    });
  });
};

export const loginAction: any = ({ email, password }: ILogin) => (
  dispatch: Dispatch
) => {
  dispatch(setLoadingAction(true));

  return authentification({ email, password })
    .then((payload) => dispatch(setUserAction(payload)))
    .catch((err) => {
      clearUser();
      console.log(err);
      if (err && (err.status || err.response)) {
        dispatch(
          showErrorModalAction(
            `${err.status || err.response.data.code} | ${
              err.data ? err.data.message : err.response.data.message
            }`
          )
        );
      }
      return Promise.reject(err);
    })
    .finally(() => dispatch(setLoadingAction(false)));
};

export const setCreatePassword: any = ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => (dispatch: Dispatch) => {
  dispatch(setLoadingAction(true));

  return createPassword({ token, password })
    .then((payload) => dispatch(setUserAction(payload)))
    .catch((err) => {
      clearUser();
      dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
      return Promise.reject(err);
    })
    .finally(() => dispatch(setLoadingAction(false)));
};

export const updateUser: any = () => (dispatch: Dispatch) => {
  return getUser()
    .then((payload) => dispatch(setUserAction(payload)))
    .catch((err) => {
      clearUser();
      dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
      return Promise.reject(err);
    });
};

export const logoutAction: any = () => (dispatch: Dispatch) => {
  dispatch(setLoadingAction(true));

  return clearUser().then(() => {
    dispatch(setUserAction(null));
    dispatch(setLoadingAction(false));
  });
};

export const getUserAction: any = () => (dispatch: Dispatch) => {
  return getUser()
    .then((user) => {
      dispatch(setUserAction(user));
      return user;
    })
    .finally(() => {
      dispatch(setReadyAction(true));
    });
};

export const setUpdateUserEmail: any = (values: any) => (
  dispatch: Dispatch
) => {
  return updateUserEmail(values)
    .then(() => {
      dispatch(updateUser());
    })
    .then(() => {
      dispatch(showModalAction('Votre email ont bien été modifié !'));
    })
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};

export const setUpdateUser: any = (
  values: any,
  successMessage?: string | boolean,
  isUpdateUser = true
) => (dispatch: Dispatch) => {
  return putUser(values)
    .then(() => {
      if (isUpdateUser) dispatch(updateUser());
    })
    .then(() => {
      if (successMessage !== false) {
        dispatch(
          showModalAction(
            successMessage || 'Vos données ont bien été modifié !'
          )
        );
      }
    })
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};

export const setCreateMember: any = (values: any) => (dispatch: Dispatch) => {
  return subscribeMember(values)
    .then(() => dispatch(updateUser()))
    .then(() =>
      dispatch(showModalAction('Vos données ont bien été enregistré !'))
    )
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};

export const setUpdateMember: any = (
  params: IPostUserMembers,
  successMessage: string
) => (dispatch: Dispatch) => {
  const { user } = store.getState();
  const memberId = user.user?.member?.id;
  return updateMember({ memberId, params })
    .then(() => dispatch(updateUser()))
    .then(() =>
      dispatch(
        showModalAction(successMessage || 'Vos données ont bien été modifié !')
      )
    )
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};

export const setUpdateOwner: any = (
  values: IPostUserOwner,
  successMessage: string
) => (dispatch: Dispatch) => {
  const { user } = store.getState();
  return updateOwnerUser(user.user?.userId, user.user?.owner?.id, values)
    .then(() => dispatch(updateUser()))
    .then(() =>
      dispatch(
        showModalAction(successMessage || 'Vos données ont bien été modifié !')
      )
    )
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};

export const setCreateFolder: any = (values: any) => (dispatch: Dispatch) => {
  return createFolder(values)
    .then(() => dispatch(updateUser()))
    .catch((err) => {
      if (err.data.message === 'This pet owner already has an open file') {
        dispatch(
          showErrorModalAction('Le propriétaire a déjà un dossier en cours.')
        );
      } else {
        dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
      }
      return Promise.reject(err);
    });
};

export const setValidateFolder: any = (
  folderId: string,
  values: IUpdateFolder
) => (dispatch: Dispatch) => {
  return validateFolder(folderId, values)
    .then(() => dispatch(updateUser()))
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
      return Promise.reject(err);
    });
};

export const setUpdateFolder: any = (
  folderId: string,
  parameters: IUpdateFolder,
  message?: string
) => (dispatch: Dispatch) => {
  return updateFolder(folderId, parameters)
    .then(() => dispatch(updateUser()))
    .then(() =>
      dispatch(showModalAction(message || 'Vos données ont bien été modifié !'))
    )
    .catch((err) => {
      dispatch(showErrorModalAction(`${err.status} | ${err.statusText}`));
      return Promise.reject(err);
    });
};
