import { SET_MODAL_ACTION, IAction } from './modal.reducer';

export function showModalAction(
  content: React.ReactNode,
  params?: { closeBack: boolean }
): IAction {
  return {
    type: SET_MODAL_ACTION,
    payload: { status: true, type: 'normal', content, params },
  };
}

export function showErrorModalAction(content?: React.ReactNode): IAction {
  return {
    type: SET_MODAL_ACTION,
    payload: { status: true, type: 'error', content },
  };
}

export function hideModalAction(): IAction {
  return { type: SET_MODAL_ACTION, payload: { status: false } };
}
