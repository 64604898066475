import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import { getFolder } from '../../../services/folders';
import { IFolder } from '../../../services/api.interface';
import { Line, Text } from '../../../components/Content';
import { ExpandLabel } from '../../../components/Expand';
import { LinkButton } from '../../../components/Link';
import Tabs, { Tab } from '../../../components/Tabs';
import Loading from '../../../components/Loading';
import {
  getLabelEspece,
  getTypePathologie,
  getSexe,
  getBoolean,
  getFolderOwnerStatusById,
} from '../../../services/constantes';
import { formatDate, formatDevise } from '../../../services/helpers';
import DownloadMedia from '../../../components/DownloadMedia';
import test from '../../../img/folder-pending.svg';
import { AnimalNews } from '../../../components/Forms';
import './Home.scss';

function Home(): JSX.Element {
  const user = useSelector(userSelector);
  const folder = user?.folder;
  const [folderDetail, setFolderDetail] = useState<IFolder | null>(null);

  useEffect(() => {
    if (folder?.id) {
      getFolder(folder.id).then((e) => {
        setFolderDetail(e);
      });
    }
  }, [folder]);

  return (
    <div className="OwnerHome">
      <h1 className="OwnerHome__title">Tableau de bord</h1>
      <Tabs>
        <Tab title="Mon dossier">
          {user && folder && folderDetail ? (
            <div className="OwnerHome__content">
              <Text>
                <b>Statut</b>
              </Text>
              <div className="OwnerHome__status">
                {getFolderOwnerStatusById(folder.status)}
              </div>
              <Line />
              <Text capitalize>
                <b>
                  {folderDetail.animalName} - {user.genre === 2 ? 'Mr' : 'Me'}{' '}
                  {user.firstname} {user.lastname}
                </b>
              </Text>
              <Text capitalize>{folderDetail.etbtName}</Text>
              <Text type="grey">
                Créé le {formatDate(folderDetail.createdAt)}
              </Text>
              <ExpandLabel expand>
                <Text label="N° de dossier">{folderDetail.id}</Text>
                <Text label="Espèce">
                  {getLabelEspece(folderDetail.animalSpecies)}
                </Text>
                <Text label="Date de naissance">
                  {formatDate(folderDetail.animalBirthDate)}
                </Text>
                <Text label="Sexe">{getSexe(folderDetail.animalSex)}</Text>
                <Text label="N° identification">
                  {folderDetail.animalIdentification}
                </Text>
                <Text label="Stérilisation">
                  {getBoolean(folderDetail.animalSterilise)}
                </Text>
                <Line />
                <Text label="Type de pathologie">
                  {getTypePathologie(folderDetail.pathoType)}
                </Text>
                <Text label="Montant du devis">
                  <b>{formatDevise(folderDetail.estimateAmount)} €</b>
                </Text>
                <DownloadMedia mediaId={folderDetail.devis} />

                {folder.status === 1 && (
                  <>
                    <br />
                    <LinkButton href="/proprietaire/dossier" center>
                      Compléter mon dossier
                    </LinkButton>
                  </>
                )}

                {folder.status === 3 && (
                  <>
                    <br />
                    <Text>
                      <b>
                        Montant maximim de la prise en charge Vetericare :{' '}
                        {formatDevise(folderDetail.maxAmountSupported)} €
                      </b>
                    </Text>
                    <p className="OwnerHome__restACharge">
                      Reste à votre charge* :{' '}
                      {formatDevise(
                        (folderDetail.estimateAmount || 0) -
                          (folderDetail.maxAmountSupported || 0)
                      )}{' '}
                      €
                    </p>
                    <Text>
                      *en cas de diminution de la facture, le montant du reste а
                      charge restera identique
                    </Text>
                  </>
                )}
              </ExpandLabel>
            </div>
          ) : (
            <Loading small />
          )}
        </Tab>
      </Tabs>
      <Tabs>
        <Tab title="Ma formation">
          <Text>Merci de consulter les documents mis à votre disposition</Text>
          <DownloadMedia
            link="https://google.com"
            name="document.formation.fr"
          />
          <DownloadMedia url={test} name="Pièce jointe 1" />
          <DownloadMedia url={test} name="Pièce jointe 2" />
        </Tab>
      </Tabs>
      {(folder?.status === 6 || folder?.status === 8) && (
        <Tabs>
          <Tab title="Nouvelles de mon animal">
            {folder ? <AnimalNews folderId={folder.id} /> : <Loading />}
          </Tab>
        </Tabs>
      )}
    </div>
  );
}

export default Home;
