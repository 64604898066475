import React, { useState } from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';

import { ReactComponent as Eye } from '../../../img/eye.svg';
import './Input.scss';

interface IInput {
  type?: string;
  name: string;
  label: string;
  icon?: string;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  min?: number;
  max?: number;
  maxLength?: number;
  formApi: UseFormMethods<any>;
  required?: string;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
  white?: boolean;
  pattern?: {
    value: RegExp;
    message: string;
  };
  onInput?: (value: string | number | undefined) => void;
  onBlur?: () => void;
  format?: (value: string) => string | undefined;
}

function Input({
  type = 'text',
  name,
  label,
  icon,
  formApi,
  required,
  disabled,
  placeholder,
  description,
  inputMode,
  min,
  max,
  maxLength,
  pattern,
  onInput,
  onBlur,
  format,
  white,
}: IInput): JSX.Element {
  const { errors, formState } = formApi;
  const [newType, setNewType] = useState<string>(type);

  function togglePassword() {
    setNewType((t) => (t === 'password' ? 'text' : 'password'));
  }

  return (
    <Controller
      name={name}
      control={formApi.control}
      rules={{ required, pattern }}
      render={({ onChange, onBlur: blur, value }, { isDirty }) => (
        <p className={`Input ${disabled ? '-disabled' : ''}`}>
          <label className="Input__group" htmlFor={name}>
            <span className="Input__label">{label}</span>
            <span className="Input__fieldGroup">
              <span className="Input__iconGroup">
                {icon && <span className={`Input__icon -${icon}`} />}
                <input
                  name={name}
                  id={name}
                  value={format ? format(value || '') : value || ''}
                  type={newType}
                  className={`Input__field -${newType} ${
                    white ? '-white' : ''
                  } ${
                    errors && (isDirty || formState.isSubmitted) && errors[name]
                      ? '-error'
                      : ''
                  }`}
                  onBlur={() => {
                    blur();
                    if (onBlur) onBlur();
                  }}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={(e) => {
                    const { value: v } = e.target;
                    if (max && newType === 'number' && parseInt(v, 10) > max) {
                      onChange(max);
                      if (onInput) onInput(max);
                    } else {
                      onChange(v);
                      if (onInput) onInput(v);
                    }
                  }}
                  inputMode={inputMode}
                  min={min}
                  max={max}
                  maxLength={maxLength}
                />
              </span>
              {type === 'password' && (
                <button
                  className="Input__passwordButton"
                  type="button"
                  onClick={togglePassword}
                  aria-label={`${
                    newType === 'password' ? 'Afficher' : 'Masquer'
                  } le mot de passe`}
                  title={`${
                    newType === 'password' ? 'Afficher' : 'Masquer'
                  } le mot de passe`}
                >
                  <Eye className={`Input__passwordButton__svg -${newType}`} />
                </button>
              )}
            </span>
            {description && (
              <span className="Input__description">{description}</span>
            )}
            {errors && (isDirty || formState.isSubmitted) && errors[name] && (
              <span className="Input__error">{errors[name].message}</span>
            )}
          </label>
        </p>
      )}
    />
  );
}

Input.defaultProps = {
  type: 'text',
  icon: null,
  required: null,
  disabled: null,
  placeholder: null,
  description: null,
  inputMode: null,
  min: null,
  max: null,
  maxLength: null,
  pattern: null,
  format: null,
  onInput: null,
  onBlur: null,
  white: false,
};

export default Input;
