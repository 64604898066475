import React, { useEffect, useRef } from 'react';
import './Expand.scss';

interface IParams {
  duration?: number;
  easing?: 'linear' | 'easeIn' | 'easeOut' | 'easeInOut' | 'easeInOutCubic';
  power?: number;
  fromTo?: number[];
  loop?: boolean;
}

const Animate = (
  params: IParams,
  callback: (x: number, y: (e: number) => void) => void
): void => {
  let date: any = new Date();
  const duration = params.duration || 300;
  const easing = params.easing || 'easeInOutCubic';
  const power = params.power || 2;
  const fromTo = params.fromTo || [0, 1];
  const loop = params.loop || false;
  const update = () => {
    const newDate: any = new Date();
    const timePassed = newDate - date;
    let progress = timePassed / duration;
    let ease = 0;

    if (progress > 1) progress = 1;

    if (easing === 'linear') {
      ease = progress;
    } else if (easing === 'easeIn') {
      ease = progress ** power;
    } else if (easing === 'easeOut') {
      ease = 1 - (1 - progress) ** power;
    } else if (easing === 'easeInOut') {
      ease =
        progress <= 0.5
          ? (2 * progress) ** power / 2
          : (2 - (2 * (1 - progress)) ** power) / 2;
    } else if (easing === 'easeInOutCubic') {
      ease =
        progress <= 0.5
          ? 4 * progress * progress * progress
          : 1 - (-2 * progress + 2) ** 3 / 2;
    }

    callback(ease * (fromTo[1] - fromTo[0]) + fromTo[0], (e) => {
      fromTo[1] = e;
    });

    if (progress === 1 && loop) date = new Date();
    if (progress !== 1 || loop) requestAnimationFrame(update);
  };

  requestAnimationFrame(update);
};

interface IExpand {
  children: React.ReactNode;
  expand: boolean;
  duration?: number;
  expandForRender?: boolean;
}

function Expand({
  children,
  expand,
  duration,
  expandForRender,
}: IExpand): JSX.Element {
  const contentParentRef = useRef<HTMLHeadingElement>(null);
  const contentChildrenRef = useRef<HTMLHeadingElement>(null);
  const alreadyOpened = useRef<boolean>(false);

  useEffect(() => {
    const contentParent = contentParentRef.current;
    const contentChildren = contentChildrenRef.current;

    if (contentParent && contentChildren) {
      const heightParent = contentParent?.clientHeight || 0;
      let heightChildren = contentChildren?.clientHeight - 1 || 0;

      let fromTo = [];
      if (expand) fromTo = [heightParent, heightChildren];
      else fromTo = [heightParent, 0];
      if (fromTo[0] === fromTo[1]) return;

      Animate({ duration, fromTo }, (data, setNewHeight) => {
        const newHeight = contentChildren?.clientHeight - 1 || 0;

        if (newHeight !== heightChildren) {
          heightChildren = newHeight;
          setNewHeight(newHeight);
        }

        if (data === heightChildren) {
          contentParent.style.height = 'auto';
          contentParent.style.overflow = 'visible';
        } else {
          contentParent.style.height = `${data}px`;
          contentParent.style.overflow = 'hidden';
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, duration]);

  if (expand) alreadyOpened.current = true;

  return (
    <div className="Expand" aria-hidden={!expand} ref={contentParentRef}>
      <div className="Expand__children" ref={contentChildrenRef}>
        {expandForRender ? alreadyOpened.current && children : children}
      </div>
    </div>
  );
}

Expand.defaultProps = {
  duration: undefined,
  expandForRender: false,
};

export default Expand;
