import React, { useState, useEffect, useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../Input';
import './CheckPassword.scss';

interface ICheckPassword {
  name: string;
  formApi: UseFormMethods<any>;
  disabled?: boolean;
  label1: string;
  label2: string;
  requiredLabel1: string;
  requiredLabel2: string;
  isValid?: (e: boolean) => void;
  white?: boolean;
}

const CheckPassword: React.FC<ICheckPassword> = ({
  name,
  formApi,
  disabled,
  label1,
  label2,
  requiredLabel1,
  requiredLabel2,
  isValid = () => null,
  white,
}: ICheckPassword) => {
  const { watch, setError, formState } = formApi;
  const { isSubmitting } = formState;
  const [minLength, setMinLength] = useState<boolean>(false);
  const [upperChar, setUpperChar] = useState<boolean>(false);
  const [lowerChar, setLowerChar] = useState<boolean>(false);
  const [oneNumber, setOneNumber] = useState<boolean>(false);
  const inputPasswordOne = watch(name);
  const inputPasswordTwo = watch(`${name}-check`);

  const isPasswordValid = useCallback(
    (test?: boolean) => {
      const inputPassword = watch(name);
      const upperCase = /[A-Z]+/g;
      const lowerCase = /[a-z]/g;
      const numberChar = /\d+/g;
      let isError = false;

      if (inputPassword.length >= 8) {
        setMinLength(true);
      } else {
        setMinLength(false);
        isError = true;
      }
      if (upperCase.test(inputPassword)) {
        setUpperChar(true);
      } else {
        setUpperChar(false);
        isError = true;
      }
      if (lowerCase.test(inputPassword)) {
        setLowerChar(true);
      } else {
        setLowerChar(false);
        isError = true;
      }
      if (numberChar.test(inputPassword)) {
        setOneNumber(true);
      } else {
        setOneNumber(false);
        isError = true;
      }

      if (test && isError) {
        setTimeout(() => {
          setError(name, {
            type: 'notSecure',
            message: 'Merci de renseigner votre mot de passe sécurisé',
          });
        }, 0);
      }

      return !isError;
    },
    [name, setError, watch]
  );

  const isPasswordSimilar = useCallback(
    (test?: boolean) => {
      if (inputPasswordOne !== inputPasswordTwo) {
        if (test) {
          setTimeout(() => {
            setError(`${name}-check`, {
              type: 'notSimilar',
              message: 'Les mots de passe ne sont pas identiques',
            });
          }, 0);
        }
        return false;
      }

      return true;
    },
    [name, inputPasswordOne, inputPasswordTwo, setError]
  );

  useEffect(() => {
    if (isSubmitting) {
      const test1 = isPasswordValid(true);
      const test2 = isPasswordSimilar(true);
      isValid(test1 && test2);
    }
  }, [isSubmitting, isValid, isPasswordSimilar, isPasswordValid]);

  return (
    <div className="CheckPassword">
      <Input
        icon="password"
        type="password"
        name={name}
        label={label1}
        formApi={formApi}
        required={requiredLabel1}
        onInput={() => isPasswordValid()}
        onBlur={() => isPasswordValid(true)}
        disabled={disabled}
        white={white}
      />
      <div className="CheckPassword__check">
        <div
          className={`CheckPassword__check__item 
              ${minLength ? '-valid' : ''}`}
        >
          8 caractères minimum
        </div>
        <div
          className={`CheckPassword__check__item
              ${upperChar ? '-valid' : ''}`}
        >
          1 majuscule minimum
        </div>
        <div
          className={`CheckPassword__check__item
              ${lowerChar ? '-valid' : ''}`}
        >
          1 minuscule minimum
        </div>
        <div
          className={`CheckPassword__check__item
              ${oneNumber ? '-valid' : ''}`}
        >
          1 chiffre minimum
        </div>
      </div>
      <Input
        icon="password"
        type="password"
        name={`${name}-check`}
        label={label2}
        formApi={formApi}
        required={requiredLabel2}
        onBlur={() => isPasswordSimilar(true)}
        disabled={disabled}
        white={white}
      />
    </div>
  );
};

CheckPassword.defaultProps = {
  disabled: undefined,
  white: false,
  isValid: () => null,
};

export default CheckPassword;
