import { IPostUserMembers } from './api.interface';
import { store } from '../store';
import { showErrorModalAction } from '../store/modal/modal.actions';
import { request } from './api';

/*
 * inscription du veterinaire
 * @param params:IPostUserMembers
 * @return Promse<any>
 */
export function subscribeMember(params: IPostUserMembers): Promise<any> {
  return request('POST', '/api/user_veterinaires', params);
}

/*
 * modification des données du veterinaire
 * @param params:IPostUserMembers
 * @return Promse<any>
 */
export function updateMember({
  memberId,
  params,
}: {
  memberId: string | undefined;
  params: IPostUserMembers;
}): Promise<any> {
  return request('PUT', `/api/user_veterinaires/${memberId}`, params);
}

/*
 * recupere le detail d'un veterinaire
 * @param params:IPostUserMembers
 * @return Promse<any>
 */
export function getMember({
  memberId,
}: {
  memberId: string | undefined;
}): Promise<any> {
  return request('GET', `/api/user_veterinaires/${memberId}`).then((e: any) => {
    return {
      ...e,
      user: {
        ...e.user,
        amountPaid: e?.user?.amountPaid ? e?.user?.amountPaid / 100 : 0,
        donationAmount: e?.user?.donationAmount
          ? e?.user?.donationAmount / 100
          : 0,
      },
    };
  });
}
