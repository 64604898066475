import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../store/user/user.actions';
import Link from '../Link';
import Media from '../Media';

import './Menu.scss';

interface INav {
  label: string;
  route: string;
  Icon?: React.ElementType | null;
  mediaId?: string | null;
}
interface IMenu {
  menu: INav[];
  menuStatus: boolean;
  desktop?: boolean;
  setMenuStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

function Menu({
  menu,
  menuStatus,
  setMenuStatus,
  desktop,
}: IMenu): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <nav
      aria-hidden={!menuStatus}
      className={`Menu ${desktop ? '-desktop' : ''}`}
    >
      <div
        role="button"
        tabIndex={-1}
        className="Menu__back"
        aria-hidden="true"
        onClick={() => setMenuStatus((e) => !e)}
      />
      <ul className="Menu__ul">
        {menu.map(({ label, route, mediaId, Icon }) => (
          <li key={route}>
            <Link
              href={route}
              onClick={() => setMenuStatus(false)}
              className="Menu__link"
              isCurrent
            >
              <span className="Menu__li__icon">
                {Icon && <Icon className="Menu__li__svg" />}
                {mediaId && (
                  <Media className="Menu__li__img" mediaId={mediaId} alt="" />
                )}
              </span>
              {label}
            </Link>
          </li>
        ))}
        <li>
          <button
            type="button"
            className="Menu__link"
            onClick={() => {
              dispatch(logoutAction()).then(() => history.push('/login'));
            }}
          >
            <span className="Menu__li__icon -plug" />
            Se déconnecter
          </button>
        </li>
      </ul>
    </nav>
  );
}

Menu.defaultProps = {
  desktop: null,
};

export default Menu;
export type { INav };
