import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import FolderList, { IFolderItem } from '../FolderList';
import DetailMemberAdmin from '../DetailMemberAdmin';
import { formatDate } from '../../services/helpers';
import './ListMembers.scss';

interface IListMembers {
  search?: string | null;
  memberId?: string;
  maxLength?: number;
}
function ListMembers({
  search,
  memberId,
  maxLength = 999,
}: IListMembers): JSX.Element {
  const user = useSelector(userSelector);
  const [folderFilteredList, setFolderFilteredList] = useState<
    IFolderItem[] | null | Error
  >(null);

  function checkAccountDate(date: Date | null | undefined) {
    if (!date) return null;

    const startSubscription = new Date(date).getTime();
    const today = new Date().getTime();
    const thereIsOneMonth = new Date().getTime() + 2678400000;

    if (startSubscription < today) {
      return <span>Renouvellement à faire</span>;
    }

    if (startSubscription < thereIsOneMonth) {
      return (
        <span>
          Renouvellement arrivant à échéance : <b>{formatDate(date)}</b>
        </span>
      );
    }

    return (
      <span>
        Renouvellement le <b>{formatDate(date)}</b>
      </span>
    );
  }

  useEffect(() => {
    const members = user?.members;
    if (members) {
      setFolderFilteredList(
        members
          .map((member) => {
            return {
              id: member['@id'],
              title: `${member.EtabNom}`,
              info: `Date de l'adhésion : ${
                member?.user?.startSubscription
                  ? formatDate(member?.user?.startSubscription)
                  : 'Aucune date disponible'
              }`,
              timeInfo: checkAccountDate(member?.user?.startSubscription),
              content: <DetailMemberAdmin memberId={member.id} />,
              expanded: memberId === member.id,
              date:
                member?.user?.startSubscription &&
                new Date(member?.user?.startSubscription),
              search: [member.EtabNom, member.EtabVille],
            };
          })
          .sort((a, b) => {
            if (!a.date) return +1;
            if (!b.date) return -1;
            return a.date.getTime() < b.date.getTime() ? 1 : -1;
          })
          .filter((folder) =>
            search
              ? folder.search.some((value) =>
                  value?.toLowerCase().includes(search.toLowerCase())
                )
              : true
          )
          .slice(0, maxLength)
      );
    }
  }, [user, memberId, maxLength, search]);

  return (
    <FolderList
      foldersList={folderFilteredList}
      notFoundLabel="Aucun adhérent trouvé..."
      expandForRender
    />
  );
}

ListMembers.defaultProps = {
  search: null,
  memberId: null,
  maxLength: 999,
};

export default ListMembers;
