import { ISelector } from './user.interface';

const initialState: ISelector = {
  user: null,
  loading: false,
  ready: false,
};

export const SET_USER_ACTION = 'SET_USER_ACTION';
export const SET_FOLDER_ACTION = 'SET_FOLDER_ACTION';

export function userReducer(state = initialState, action: any): ISelector {
  switch (action.type) {
    case SET_USER_ACTION:
      return { ...state, ...action.payload };
    case SET_FOLDER_ACTION:
      if (state.user && state.user.folders) {
        const { folders } = state.user;
        const newFolders = folders.map((e) =>
          e.id === action.payload.folderId
            ? { ...e, ...action.payload.parameters }
            : e
        );
        return {
          ...state,
          user: {
            ...state.user,
            folders: newFolders,
          },
        };
      }
      return state;
    default:
      return state;
  }
}
