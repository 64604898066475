import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { IUserJWT } from './api.interface';
import { store } from '../store';
import { showErrorModalAction } from '../store/modal/modal.actions';
// import { addTest1Action } from '../store/user/user.actions';

let JWT: string | null = localStorage.getItem('JWT');

export const setJWT: (token: string | null) => void = (
  token: string | null
) => {
  JWT = token;
  if (token) localStorage.setItem('JWT', token);
  else localStorage.removeItem('JWT');
};

export const getJWT: () => string | null = () => JWT;

export const getDecodedJWT: () => IUserJWT = () => {
  if (!JWT) return null;
  const tokenSplited = JWT.split('.');
  const userJson = atob(tokenSplited[1]);
  const user = JSON.parse(userJson);
  return user;
};

export const callApi: (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  path: string,
  body?: any,
  options?: any
) => Promise<AxiosResponse> = (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  path: string,
  body?: any,
  options?: any
) => {
  const url = `${process.env.REACT_APP_URL_API_DRUPAL}${path}`;

  switch (method) {
    case 'DELETE':
      return axios.delete(url, options);
    case 'GET':
      return axios.get(url, options);
    case 'POST':
      return axios.post(url, body, options);
    case 'PUT':
      return axios.put(url, body, options);
    default:
      throw Error(`Unsupported request: ${method}`);
  }
};

export const request: (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  path: string,
  body?: any
) => Promise<AxiosResponse> = (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  path: string,
  body?: any
) => {
  let headers = null;
  if (JWT) headers = { Authorization: `Bearer ${JWT}` };

  return callApi(method, path, body, { headers })
    .then(({ data }) => data)
    .catch(({ response }) => {
      if (
        response.data.code === 401 &&
        response.data.message === 'Expired JWT Token'
      ) {
        setJWT(null);
        store.dispatch(
          showErrorModalAction(
            <div>
              Vous êtes deconnecté.<a href="/">Se reconnecter</a>
            </div>
          )
        );
      }
      return Promise.reject(response);
    });
};

export const decodeJWT = (token: string | null) => {
  if (!token) return null;
  const tokenSplited = token.split('.');
  const userJson = atob(tokenSplited[1]);
  const user = JSON.parse(userJson);
  return user;
};
