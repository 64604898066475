import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Store from './store';
import Modal from './components/Modal';
import Routes from './Routes';
import './App.scss';

function App(): JSX.Element {
  return (
    <Router>
      <Store>
        <Modal />
        <Routes />
      </Store>
    </Router>
  );
}

export default App;
