import React from 'react';
import { ReactComponent as Loader } from '../../../img/loader.svg';
import './Button.scss';

interface IButton {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  type?: 'submit' | 'button';
  loading?: boolean;
  noCenter?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IButton> = ({
  children,
  className,
  type = 'submit',
  disabled,
  loading,
  noCenter,
  onClick,
}: IButton) => (
  <p className={`Button ${className}`} data-no-center={noCenter}>
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className="Button__button"
      disabled={disabled || loading}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
      <span className="Button__loading" data-visible={loading}>
        <Loader className="Button__loading__svg" />
      </span>
    </button>
  </p>
);

Button.defaultProps = {
  type: undefined,
  className: undefined,
  disabled: false,
  loading: false,
  noCenter: false,
  onClick: undefined,
};

export default Button;
