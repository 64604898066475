import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './Members.scss';
import ListMembers from '../../../components/ListMembers';

interface ParamTypes {
  memberId: string;
}

function Members(): JSX.Element {
  const { memberId } = useParams<ParamTypes>();
  const [search, setSearch] = useState<string | null>(null);

  return (
    <div className="AdminMembers">
      <h1 className="AdminMembers__title">Adhérents</h1>

      <div className="AdminMembers__search">
        <input
          type="search"
          placeholder="Rechercher un adhérent"
          className="AdminMembers__search__input"
          onChange={({ target }) => setSearch(target.value)}
        />
      </div>

      <ListMembers search={search} memberId={memberId} />
    </div>
  );
}

export default Members;
