import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUpdateFolder } from '../../store/user/user.actions';
import { Textarea, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  folderId: string;
  white?: boolean;
}

function Form({ folderId, white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { handleSubmit, getValues } = form;
  function onSubmit() {
    setLoading(true);
    dispatch(
      setUpdateFolder(
        folderId,
        { ...getValues(), status: 4 },
        'Le dossier a bien été refusé !'
      )
    ).finally(() => setLoading(false));
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Textarea
        name="motifRefus"
        label="Motif du refus*"
        formApi={form}
        required="Merci de renseigner un motif du refus."
        white={white}
      />
      <Button loading={isLoading}>Envoyer</Button>
    </form>
  );
}

Form.defaultProps = {
  white: false,
};

export default Form;
