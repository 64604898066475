/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Link from './Link';
import './LinkButton.scss';

interface ILinkButton {
  href: string;
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

function LinkButton({
  href,
  children,
  className,
  center,
  onClick,
  ...etc
}: ILinkButton): JSX.Element {
  if (center) {
    return (
      <div className="LinkButton__center">
        <Link
          href={href}
          className={`LinkButton ${className}`}
          onClick={onClick}
          {...etc}
        >
          {children}
        </Link>
      </div>
    );
  }
  return (
    <Link
      href={href}
      className={`LinkButton ${className}`}
      onClick={onClick}
      {...etc}
    >
      {children}
    </Link>
  );
}

LinkButton.defaultProps = {
  children: null,
  className: '',
  center: false,
  onClick: undefined,
};

export default LinkButton;
