import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Input, Button } from '../../components/Fields';
import Link from '../../components/Link';
import { loginAction } from '../../store/user/user.actions';
import { ReactComponent as Logo } from '../../img/logo.svg';
import './Login.scss';

function Login(): JSX.Element {
  const location = useLocation<{ from: { pathname: string } }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, getValues } = form;

  async function onSubmit() {
    const { email, password } = getValues();

    dispatch(loginAction({ email, password })).then(() =>
      history.push(location?.state?.from || '/')
    );
  }

  return (
    <div className="Login">
      <div className="Login__content">
        <Logo className="Login__logo" />
        <h1 className="Login__title">Connexion</h1>
        <p className="Login__text">Bienvenue sur l’application Vetericare</p>
      </div>

      <form
        className="Login__form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="Login__form__fields">
          <Input
            icon="email"
            type="email"
            name="email"
            label="Adresse mail"
            formApi={form}
            required="Merci de renseigner une adresse mail."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse mail valide.',
            }}
          />
          <Input
            icon="password"
            type="password"
            name="password"
            label="Mot de passe"
            formApi={form}
            required="Merci de renseigner un mot de passe."
          />
        </div>

        <div className="Login__form__buttons">
          <Button>Me connecter</Button>
          <Link className="Login__form__link" href="/mot-de-passe-oublie">
            *Mot de passe oublié ?
          </Link>
        </div>
      </form>

      <a
        className="Login__link"
        href="https://www.vetericare.fr/veterinaires"
        rel="noreferrer"
        target="_blank"
      >
        Pas encore adhérent ? C&apos;est par ici !
      </a>
    </div>
  );
}

export default Login;
