import React, { useEffect, useRef, useState } from 'react';
import Link from '../Link';
import Expand from '../Expand';
import { formatDate } from '../../services/helpers';
import './FolderItem.scss';

interface IFolderItem {
  id: string;
  title: string;
  info?: React.ReactNode;
  timeInfo?: React.ReactNode;
  statusLabel?: string | null;
  dateCreate?: Date | null;
  status?: number;
  content?: React.ReactNode;
  link?: string;
  notification?: boolean;
  photo?: string | boolean;
  expanded?: boolean;
  search?: (string | null)[];
}
interface IFolderProps {
  folder: IFolderItem;
  expandForRender?: boolean;
}

function FolderItem({ folder, expandForRender }: IFolderProps): JSX.Element {
  const {
    title,
    info,
    timeInfo,
    statusLabel,
    dateCreate,
    content,
    link,
    notification,
    photo,
    expanded = false,
  } = folder;
  const folderRef = useRef<HTMLDivElement>(null);
  const [expandStatus, setExpandStatus] = useState<boolean>(expanded);

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        if (folderRef.current) {
          const width = window.innerWidth > 768 ? 0 : 56;
          const y =
            folderRef.current.getBoundingClientRect().top +
            window.pageYOffset -
            width;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 300);
    }
  }, [expanded]);

  return (
    <div className={`FolderItem ${photo ? '-photo' : ''}`} ref={folderRef}>
      <div className="FolderItem__header">
        {photo && (
          <div className="FolderItem__header__photo">
            {photo === true ? <div /> : <img src={photo} alt="" />}
          </div>
        )}
        <div className="FolderItem__header__group">
          <div>
            <h2 className="FolderItem__header__title">
              {notification && (
                <span className="FolderItem__header__notification" />
              )}
              {title}
            </h2>
            {statusLabel && (
              <p className="FolderItem__header__statusLabel">{statusLabel}</p>
            )}
            {info && <p className="FolderItem__header__name">{info}</p>}
            {dateCreate && (
              <div className="FolderItem__header__date">
                Créé le {formatDate(dateCreate)}
              </div>
            )}
            {timeInfo && (
              <div className="FolderItem__header__timeInfo">{timeInfo}</div>
            )}
          </div>
          {content && (
            <button
              type="button"
              onClick={() => setExpandStatus((e) => !e)}
              className="FolderItem__header__more"
            >
              Lire plus
            </button>
          )}
          {link && (
            <Link href={link} className="FolderItem__header__more">
              Lire plus
            </Link>
          )}
        </div>
      </div>
      {content && (
        <Expand expand={expandStatus} expandForRender={expandForRender}>
          <div className="FolderItem__content">{content}</div>
        </Expand>
      )}
    </div>
  );
}

FolderItem.defaultProps = {
  expandForRender: false,
};

export default FolderItem;
export type { IFolderItem };
