import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '../../store/modal/modal.actions';
import { setNotificationFolderAction } from '../../store/user/user.actions';
import Loading from '../Loading';
import { ExpandLabel } from '../Expand';
import { Line, Text } from '../Content';
import { getFolder } from '../../services/folders';
import { IFolder } from '../../services/api.interface';
import { formatDate, formatDevise } from '../../services/helpers';
import {
  ReasonMissingElements,
  ReasonForReject,
  SupportAmount,
  PaidAmount,
} from '../Forms';
import {
  getLabelEspece,
  getTypePathologie,
  getSexe,
  getBoolean,
  getGenre,
} from '../../services/constantes';
import DownloadMedia from '../DownloadMedia';
import PopinMedia from '../PopinMedia';
import './DetailFolderAdmin.scss';
import Link, { LinkButton } from '../Link';
import { ReactComponent as Edit } from '../../img/edit.svg';

interface IDetailFolderAdmin {
  folderId: string;
}

function DetailFolderAdmin({ folderId }: IDetailFolderAdmin): JSX.Element {
  const dispatch = useDispatch();
  const [folder, setFolder] = useState<IFolder | null>(null);

  const setFolderData = useCallback(() => {
    getFolder(folderId).then((e) => {
      if (e.isUpdate && [2, 6].includes(e.status)) {
        dispatch(setNotificationFolderAction(folderId));
      }
      setFolder(e);
    });
  }, [folderId, dispatch]);

  useEffect(() => {
    setFolderData();
  }, [setFolderData]);

  if (!folder) return <Loading small />;

  return (
    <div className="DetailFolderAdmin">
      <Link
        href={`/admin/dossiers/editer/${folder.id}`}
        title="Éditer"
        ariaLabel="Éditer"
        className="DetailFolderAdmin__edit"
      >
        <Edit className="DetailFolderAdmin__edit__img" />
      </Link>
      <Text label="N°">{folder.id}</Text>
      <Text label="Espèce">{getLabelEspece(folder.animalSpecies)}</Text>
      <Text label="Date de naissance">
        {formatDate(folder.animalBirthDate)}
      </Text>
      <Text label="Type de pathologie">
        {getTypePathologie(folder.pathoType)}
      </Text>
      <Text label="Montant du devis">
        <b>{formatDevise(folder.estimateAmount)} €</b>
      </Text>
      {folder.maxAmountSupported && (
        <Text label="Montant maximim de la prise en charge Vetericare">
          <b>{formatDevise(folder.maxAmountSupported)} €</b>
        </Text>
      )}
      <ExpandLabel>
        <div className="DetailFolderAdmin__group">
          <Text label="Sexe">{getSexe(folder.animalSex)}</Text>
          <Text label="N° identification">{folder.animalIdentification}</Text>
          <Text label="Stérilisation">
            {getBoolean(folder.animalSterilise)}
          </Text>
          <Text label="Descriptions médicales">{folder.pathoSignes}</Text>
          <Text label="L’euthanasie a-t-elle été évoquée avec le propriétaire">
            {getBoolean(folder.pathoEuthanasie)}
          </Text>
          <Text label="Commentaires">
            {folder.comments.length ? (
              folder.comments.map(({ comment, ...rest }) => (
                <span key={rest['@id']} className="DetailFolderAdmin__comment">
                  <b>{comment}</b>
                </span>
              ))
            ) : (
              <b>Aucun commentaire.</b>
            )}
          </Text>
          <Line />
          {folder?.medias?.map((mediaId: string, i) => (
            <DownloadMedia
              key={mediaId}
              mediaId={mediaId}
              name={`Pièce jointe ${i + 1}`}
            />
          ))}
          <DownloadMedia
            mediaId={folder.devis}
            name={`Pièce jointe ${folder.medias.length + 1}`}
          />

          <Line />
          <div className="DetailFolderMember__subtitle">
            Informations vétérinaire
          </div>
          <Text label="Nom de l'établissement">{folder.etbtName}</Text>
          <Text label="Nom du vétérinaire en charge du cas">
            {folder.vetoIncharge}
          </Text>
          <Text label="Mail de contact">{folder.contactMail}</Text>
          <Text label="Téléphone de contact">{folder.contactPhone}</Text>
          <Text label="Adresse">{folder.structAdress}</Text>
          <Text label="Code postal">{folder.structureCP}</Text>
          <Text label="Ville">{folder.structureVille}</Text>

          <Line />
          <div className="DetailFolderMember__subtitle">
            Informations propiétaire
          </div>
          <Text label="Genre">
            {getGenre(folder.userProprietaire.user.genre)}
          </Text>
          <Text label="Prénom & Nom">
            <b>
              {folder.userProprietaire.user.firstname}{' '}
              {folder.userProprietaire.user.lastname}
            </b>
          </Text>
          <Text label="Adresse">{folder.userProprietaire.adress}</Text>
          <Text label="Code postal">{folder.userProprietaire.cp}</Text>
          <Text label="Ville">{folder.userProprietaire.ville}</Text>
          <Text label="Téléphone">{folder.userProprietaire.phone}</Text>
          <Text label="Mail">{folder.userProprietaire.user.email}</Text>
          <DownloadMedia
            mediaId={folder.avisImpot}
            name={`Avis d'imposition`}
          />

          {[6, 8].includes(folder.status) && (
            <>
              <Line />
              <div className="DetailFolderMember__subtitle">
                Suivi de l&apos;animal
              </div>
              <Text label="Compte rendu">{folder.report}</Text>
              {folder.suiviPJ.map((mediaId: string, i) => (
                <DownloadMedia
                  key={mediaId}
                  mediaId={mediaId}
                  name={`Pièce jointe ${i + 1}`}
                />
              ))}
              <p className="DetailFolderMember__text">Photos</p>
              <p className="DetailFolderMember__medias">
                {folder.suiviPhotos.map((mediaId: string) => (
                  <PopinMedia key={mediaId} mediaId={mediaId} />
                ))}
              </p>
              <Text label="Commentaires">{folder.suiviComment}</Text>
              <Text label="Montant de la facture">
                <b>{formatDevise(folder.billAmount)} €</b>
              </Text>
              <DownloadMedia mediaId={folder.facture} name="Facture" />
              {folder.finalAmountSupported && (
                <Text label="Montant définitif pris en charge par Vetericare">
                  <b>{formatDevise(folder.finalAmountSupported)} €</b>
                </Text>
              )}
            </>
          )}

          {!!folder.petOwnerNewsComments?.length && (
            <>
              <Line />
              <div className="DetailFolderMember__subtitle">
                Nouvelles de l&apos;animal
              </div>
              <div className="DetailFolderAdmin__buttons">
                <LinkButton
                  href={`/admin/nouvelles-des-animaux/${folder.id}`}
                  className="DetailFolderAdmin__button2"
                >
                  Accéder aux nouvelles
                </LinkButton>
              </div>
            </>
          )}

          {[6].includes(folder.status) && (
            <div className="DetailFolderAdmin__buttons">
              <button
                type="button"
                className="DetailFolderAdmin__button2"
                onClick={() => {
                  dispatch(
                    showModalAction(
                      <PaidAmount
                        folderId={folder.id}
                        white
                        max={folder.billAmount}
                      />
                    )
                  );
                }}
              >
                Paiement effectué
              </button>
            </div>
          )}

          {folder.status === 2 && (
            <div className="DetailFolderAdmin__buttons">
              <div className="DetailFolderAdmin__buttons__group">
                <button
                  type="button"
                  className="DetailFolderAdmin__button"
                  onClick={() => {
                    dispatch(
                      showModalAction(
                        <SupportAmount
                          folderId={folder.id}
                          white
                          max={folder.estimateAmount}
                        />
                      )
                    );
                  }}
                >
                  Accepter
                </button>
                <button
                  type="button"
                  className="DetailFolderAdmin__button"
                  onClick={() => {
                    dispatch(
                      showModalAction(
                        <ReasonForReject folderId={folder.id} white />
                      )
                    );
                  }}
                >
                  Refuser
                </button>
              </div>
              <button
                type="button"
                className="DetailFolderAdmin__button2"
                onClick={() => {
                  dispatch(
                    showModalAction(
                      <ReasonMissingElements folderId={folder.id} white />
                    )
                  );
                }}
              >
                Besoin d&apos;information complémentaire
              </button>
            </div>
          )}
        </div>
      </ExpandLabel>
    </div>
  );
}

export default DetailFolderAdmin;
