import React, { useEffect, useState } from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  showModalAction,
  showErrorModalAction,
} from '../../../store/modal/modal.actions';
import { mediaUpload } from '../../../services/folders';
import { IFile } from '../../../services/api.interface';
import ImgUploaded from './ImgUploaded';
import Loading from '../../Loading';
import './FileDoc.scss';

interface IFileDoc {
  name: string;
  label: string;
  formApi: UseFormMethods<any>;
  required?: string;
  multiple?: boolean;
  disabled?: boolean;
  maxSize?: number;
  info?: string;
  white?: boolean;
  pending?: (e: boolean) => void;
}

function InputFile({
  props: { label, multiple, disabled, maxSize, info, white, pending },
  formApi: { errors, formState },
  control: { name, onChange, onBlur, value, isDirty },
}: any) {
  const dispatch = useDispatch();
  let initialState: IFile[] = [];

  if (value && Array.isArray(value))
    initialState = value.map((media: string) => ({
      id: Math.round(Math.random() * 1000000),
      idMedia: media,
    }));
  else if (value) {
    initialState = [
      {
        id: Math.round(Math.random() * 1000000),
        idMedia: value,
      },
    ];
  }
  const [filesList, setFilesList] = useState<IFile[]>(initialState);

  useEffect(() => {
    const files = filesList.filter((e) => e.idMedia).map((e) => e.idMedia);
    if (multiple) {
      onChange(files.length ? files : null);
    } else {
      onChange(files[0] || null);
    }
  }, [multiple, filesList, onChange]);

  // useEffect(() => {
  //   if (value && Array.isArray(value)) {
  //     setFilesList(
  //       value.map((media: string) => ({
  //         id: Math.round(Math.random() * 1000000),
  //         idMedia: media,
  //       }))
  //     );
  //   } else if (value) {
  //     setFilesList([
  //       {
  //         id: Math.round(Math.random() * 1000000),
  //         idMedia: value,
  //       },
  //     ]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(value)]);

  function onInputChange({ target }: { target: HTMLInputElement }) {
    let files = target.files
      ? Object.values(target.files).map((val) => ({
          file: val,
          id: Math.round(Math.random() * 1000000),
        }))
      : [];

    if (
      maxSize &&
      files.some(({ file }: { file: File }) => file.size > maxSize * 1048576)
    ) {
      const filesTooLarge = files.filter(
        ({ file }: { file: File }) => file.size > maxSize * 1048576
      );

      files = files.filter(
        ({ file }: { file: File }) => file.size < maxSize * 1048576
      );

      dispatch(
        showModalAction(
          <>
            <span
              className="FileDoc__toolarge__alert"
              role="img"
              aria-label="Attention"
            >
              ⚠️
            </span>
            <p className="FileDoc__toolarge__title">
              Impossible de télécharger{' '}
              {filesTooLarge.length > 1 ? 'les photos ' : `la photo `}
              <b>{filesTooLarge.map((e) => e.file.name).join(', ')}</b>
            </p>
            <p className="FileDoc__toolarge__info">
              La taille d&apos;une photo ne doit pas dépasser {maxSize}Mo
            </p>
          </>
        )
      );
    }

    if (pending) pending(true);

    setFilesList((state) => [...state, ...files]);

    const uploads = mediaUpload(files);
    uploads.forEach((promise) =>
      promise.then((media) => {
        setFilesList((state) =>
          state.map((f) => (media.id === f.id ? media : f))
        );
      })
    );

    Promise.all(uploads).then(() => {
      if (pending) pending(false);
    });
  }

  return (
    <div className={`FileDoc ${disabled ? '-disabled' : ''}`}>
      <div className="FileDoc__label">{label}</div>
      <div className="FileDoc__group">
        {(!value || (Array.isArray(value) && value.length < 1) || multiple) && (
          <label className="FileDoc__input" htmlFor={name}>
            <input
              name={name}
              type="file"
              id={name}
              onBlur={onBlur}
              disabled={disabled}
              multiple={multiple}
              onChange={onInputChange}
              accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf, .txt, xls, .xlsx, .doc, .docx, .xml, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              className="FileDoc__input__field"
            />
            <div
              className={`FileDoc__input__button ${white ? '-white' : ''} ${
                errors && (isDirty || formState.isSubmitted) && errors[name]
                  ? '-error'
                  : ''
              }`}
              aria-label="Uploader une image"
              title="Uploader une image"
            />
          </label>
        )}
        <div className="FileDoc__attachment">
          {filesList.map(({ file, id, idMedia }) => (
            <div
              className="FileDoc__attachment__item"
              title={file?.name}
              key={id}
            >
              <ImgUploaded
                file={file}
                media={idMedia}
                className="FileDoc__attachment__item__label"
              />
              {!idMedia && file ? (
                <div
                  className="FileDoc__attachment__item__loading"
                  title="Chargement en cours..."
                >
                  <Loading size={10} white small />
                </div>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setFilesList((state) =>
                      state.filter((e) => e.idMedia !== idMedia)
                    );
                  }}
                  aria-label="Supprimer l'image"
                  title="Supprimer l'image"
                  className="FileDoc__attachment__item__remove"
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {info && <div className="FileDoc__info">{info}</div>}
      {errors && (isDirty || formState.isSubmitted) && errors[name] && (
        <div className="FileDoc__error">{errors[name].message}</div>
      )}
    </div>
  );
}

function FileDoc({ name, formApi, required, ...props }: IFileDoc): JSX.Element {
  return (
    <Controller
      name={name}
      control={formApi.control}
      rules={{ required }}
      render={(props1, props2) => (
        <InputFile
          props={props}
          formApi={formApi}
          control={{ ...props1, ...props2 }}
        />
      )}
    />
  );
}

FileDoc.defaultProps = {
  required: null,
  multiple: false,
  disabled: null,
  maxSize: 5,
  info: null,
  white: false,
  pending: null,
};

export default FileDoc;
