import React, { useState } from 'react';
import Link from '../Link';
import Menu, { INav } from '../Menu';
import { ReactComponent as LogoWhite } from '../../img/logo-white.svg';
import './Layout.scss';

interface ILayout {
  children: React.ReactNode;
  menu: INav[];
  footer?: INav[];
}

function Layout({ children, menu, footer = [] }: ILayout): JSX.Element {
  const [menuStatus, setMenuStatus] = useState(false);

  return (
    <div className="Layout">
      <div className="Layout__header">
        <button
          type="button"
          title="Menu"
          aria-label="Menu"
          className="Layout__header__menu"
          onClick={() => setMenuStatus((e) => !e)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="Layout__header__logo">
          <LogoWhite className="Layout__header__logo__svg" />
        </div>
      </div>
      <Menu menu={menu} menuStatus={menuStatus} setMenuStatus={setMenuStatus} />
      <div className="Layout__content">
        <div className="Layout__content__left">
          <Menu
            menu={menu}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
            desktop
          />
        </div>
        <div className="Layout__content__right">{children}</div>
      </div>
      {!!footer.length && (
        <div className="Layout__footer -mobile">
          {footer.map(({ label, route, Icon }) => (
            <Link
              href={route}
              onClick={() => setMenuStatus(false)}
              className="Layout__footer__link"
              key={route}
            >
              {Icon && <Icon className="Layout__footer__icon" />}
              {label}
            </Link>
          ))}
        </div>
      )}
      <div className="Layout__footer -desktop" />
    </div>
  );
}

Layout.defaultProps = {
  footer: [],
};

export default Layout;
