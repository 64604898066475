import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FolderList, { IFolderItem } from '../../../components/FolderList';
import { formatDate } from '../../../services/helpers';
import './AnimalsNews.scss';
import { Text } from '../../../components/Content';
import PopinMedia from '../../../components/PopinMedia';
import { getFolders } from '../../../services/folders';
import { LinkButton } from '../../../components/Link';

interface ParamTypes {
  folderId: string;
}

function AnimalsNews(): JSX.Element {
  const { folderId } = useParams<ParamTypes>();
  const [search, setSearch] = useState<string | null>(null);
  const [folderFilteredList, setFolderFilteredList] = useState<
    IFolderItem[] | null | Error
  >(null);

  useEffect(() => {
    getFolders().then((folders) => {
      setFolderFilteredList(
        folders
          .filter((folder) => folder.petOwnerNewsComments?.length)
          .map((folder) => {
            return {
              id: folder['@id'],
              title: `${folder.animalName} - ${folder.userProprietaire.user.firstname} ${folder.userProprietaire.user.lastname}`,
              info: folder.etbtName,
              dateCreate: new Date(folder.createdAt),
              content: (
                <div>
                  <Text>Photos ajoutées :</Text>
                  {folder.petOwnerNewsPhotos?.map((e) => (
                    <PopinMedia mediaId={e} key={e} />
                  ))}
                  <div className="AnimalsNews__comments">
                    <Text label="Commentaire">
                      {folder.petOwnerNewsComments?.map(
                        ({ comment, createdAt, ...rest }) => (
                          <p key={rest['@id']} className="AnimalsNews__comment">
                            <span className="AnimalsNews__comment__date">
                              {formatDate(new Date(createdAt))} -{' '}
                            </span>
                            {comment}
                          </p>
                        )
                      )}
                    </Text>
                  </div>
                  <LinkButton href={`/admin/dossiers/${folder.id}`} center>
                    Accéder au dossier complet
                  </LinkButton>
                </div>
              ),
              expanded: folderId === `${folder.id}`,
              search: [
                folder.userProprietaire.user.firstname,
                folder.userProprietaire.user.lastname,
                folder.animalName,
                folder.etbtName,
              ],
            };
          })
          .sort((a, b) => {
            if (!a.dateCreate) return +1;
            if (!b.dateCreate) return -1;
            return a.dateCreate.getTime() < b.dateCreate.getTime() ? 1 : -1;
          })
          .filter((folder) =>
            search
              ? folder.search.some((value) =>
                  value?.toLowerCase().includes(search.toLowerCase())
                )
              : true
          )
      );
    });
  }, [folderId, search]);

  return (
    <div className="AnimalsNews">
      <h1 className="AnimalsNews__title">Nouvelles des animaux</h1>

      <div className="AnimalsNews__search">
        <input
          type="search"
          placeholder="Rechercher une nouvelle"
          className="AnimalsNews__search__input"
          onChange={({ target }) => setSearch(target.value)}
        />
      </div>

      <FolderList
        foldersList={folderFilteredList}
        notFoundLabel="Aucune nouvelle trouvé..."
        expandForRender
      />
    </div>
  );
}

export default AnimalsNews;
