import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const formatDate = (dt: Date, dateFormat = 'd LLLL yyyy'): string => {
  return format(new Date(dt), dateFormat, {
    locale: fr,
  });
};

export const formatDevise = (amount: number | string): string => {
  return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 })
    .format(typeof amount === 'string' ? parseFloat(amount) : amount)
    .replace(/\s/, ' ');
};
