import { ISelector } from './user.interface';

export function userSelector({
  user,
}: {
  user: ISelector;
}): ISelector['user'] | null {
  return user.user;
}

export function loadingSelector({ user }: { user: ISelector }): boolean {
  return user.loading;
}

export function readySelector({ user }: { user: ISelector }): boolean {
  return user.ready;
}
