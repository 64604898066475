import { INewFolder, IFolder, IUpdateFolder, IFile } from './api.interface';
import { request } from './api';
import { getOwnerUser } from './owners';

/*
 * get media data by media id
 * @param id: number
 * @return Promise<IMedia>
 */
export function getMedia(id: number): Promise<any> {
  return request('GET', `/api/medias/${id}`);
}

/*
 * update user param
 * @param files: File[]
 * @return { id }
 */
export function mediaUpload(files: IFile[]): Promise<any>[] {
  return files.map(({ file, id }, i) => {
    const formdata = new FormData();
    if (file) formdata.append('file', file);
    return request('POST', `/api/medias`, formdata).then((res: any) => ({
      file,
      id,
      idMedia: res['@id'],
    }));
  });
}

/*
 * recupere un commentaire a partir de sont ID
 * @param commentId:string
 * @return Promise<string>
 */
export function getComment(commentId: string): Promise<any> {
  return request('GET', `/api/comments${commentId}`);
}

/*
 * creation d'un commentaire
 * @param comment:string
 * @return Promise
 */
export function createComment(comment: string): Promise<any> {
  return request('POST', `/api/comments`, { comment });
}

/*
 * creation d'un dossier
 * @param parameters:INewFolder
 * @return Promise
 */
export async function createFolder(parameters: INewFolder): Promise<any> {
  const commentId = parameters.comments
    ? await createComment(parameters.comments).then((e) => e['@id'])
    : null;
  return request('POST', `/api/dossiers`, {
    ...parameters,
    comments: commentId ? [commentId] : [],
  });
}

/*
 * get folder by folder id
 * @param folderId
 * @return Promise
 */
export function getFolder(idFolder: string): Promise<IFolder> {
  return request('GET', `/api/dossiers/${idFolder}`)
    .then((folder: any) => ({
      ...folder,
      id: folder['@id'].replace('/api/dossiers/', ''),
    }))
    .then(async (folder) => {
      const ownerId = folder.userProprietaire['@id'].replace(
        '/api/user_proprietaires/',
        ''
      );
      const userProprietaire = await getOwnerUser(ownerId);
      return {
        ...folder,
        userProprietaire,
      };
    });
}

/*
 * get folder by folder id
 * @param folderId
 * @return Promise<IFolder[]>
 */
export function getFolders(): Promise<IFolder[]> {
  return request('GET', `/api/dossiers`)
    .then((folder: any) => ({
      ...folder,
      id: folder['@id'].replace('/api/dossiers/', ''),
    }))
    .then((data) => data['hydra:member'][0]);
}

/*
 * validation d'un dossier par le proprietaire
 * @param parameters:INewFolder
 * @return Promise
 */
export async function validateFolder(
  idFolder: string,
  parameters: IUpdateFolder
): Promise<any> {
  return request('PUT', `/api/dossiers/${idFolder}`, {
    ...parameters,
  });
}

/*
 * modification d'un dossier
 * @param idFolder:string
 * @param parameters:INewFolder
 * @return Promise
 */
export async function updateFolder(
  idFolder: string,
  parameters: IUpdateFolder
): Promise<any> {
  let newParameters: IUpdateFolder = { ...parameters };

  if (
    parameters?.media ||
    parameters?.comment ||
    parameters?.missingElement ||
    parameters?.petOwnerNewsComment
  ) {
    const folder: IFolder = await getFolder(idFolder);

    if (parameters?.media) {
      newParameters = {
        ...newParameters,
        medias: [...folder.medias, ...parameters.media],
      };
    }

    if (parameters?.petOwnerNewsPhotos) {
      newParameters = {
        ...newParameters,
        petOwnerNewsPhotos: [
          ...folder.petOwnerNewsPhotos,
          ...parameters.petOwnerNewsPhotos,
        ],
      };
    }

    if (parameters?.petOwnerNewsComment) {
      const commentId = await createComment(
        parameters.petOwnerNewsComment
      ).then((e) => e['@id']);
      const comments = folder.petOwnerNewsComments.map((com) => com['@id']);
      newParameters = {
        ...newParameters,
        petOwnerNewsComments: [...comments, commentId],
      };
    }

    if (parameters?.comment) {
      const commentId = await createComment(parameters.comment).then(
        (e) => e['@id']
      );
      const comments = folder.comments.map((com) => com['@id']);
      newParameters = { ...newParameters, comments: [...comments, commentId] };
    }

    if (parameters?.missingElement) {
      const commentId = await createComment(parameters.missingElement).then(
        (e) => e['@id']
      );
      const comments = folder.missingElements.map((com) => com['@id']);
      newParameters = {
        ...newParameters,
        missingElements: [...comments, commentId],
      };
    }
  }
  return request('PUT', `/api/dossiers/${idFolder}`, newParameters);
}
