import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CheckPassword, Button } from '../../components/Fields';
import { setCreatePassword } from '../../store/user/user.actions';
import { ReactComponent as Logo } from '../../img/logo.svg';
import './CreatePassword.scss';

interface ParamTypes {
  token: string;
}

function CreatePassword(): JSX.Element {
  const history = useHistory();
  const { token } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const isValid = useRef(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      password: '',
    },
  });

  const { handleSubmit, getValues } = form;

  function onSubmit() {
    if (isValid.current) {
      const { password } = getValues();

      dispatch(setCreatePassword({ token, password })).then(() =>
        history.push('/')
      );
    }
  }

  return (
    <div className="CreatePassword">
      <div className="CreatePassword__content">
        <Logo className="CreatePassword__logo" />
        <h1 className="CreatePassword__title">Creation du mot de passe</h1>
      </div>

      <form
        className="CreatePassword__form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <CheckPassword
          name="password"
          label1="Mot de passe.*"
          label2="Confirmation du mot de passe.*"
          requiredLabel1="Merci de renseigner un mot de passe."
          requiredLabel2="Merci de confirmer le mot de passe."
          formApi={form}
          isValid={(e) => {
            isValid.current = e;
          }}
        />

        <Button>Créer le compte</Button>
      </form>

      <div className="CreatePassword__footer" />
    </div>
  );
}

export default CreatePassword;
