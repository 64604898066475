import React from 'react';
import './Error.scss';

export interface IError {
  message?: JSX.Element | string | null;
}

function Error({ message }: IError): JSX.Element | null {
  if (!message) return null;
  return <div className="Error">{message}</div>;
}

Error.defaultProps = {
  message: null,
};

export default Error;
