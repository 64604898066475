import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import { setUpdateUser } from '../../../store/user/user.actions';
import { showModalAction } from '../../../store/modal/modal.actions';
import { ExpandLabel } from '../../../components/Expand';
import { Button } from '../../../components/Fields';
import {
  UpdatePassword,
  UpdateEmail,
  UpdateLogo,
} from '../../../components/Forms';
import './Account.scss';
import Toggle from '../../../components/Toggle';

function Account(): JSX.Element {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [parts, setParts] = useState<boolean[]>([true, false]);

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  return (
    <div className="AdminAccount">
      <div className="AdminAccount__header">
        <h1 className="AdminAccount__header__title">Mon compte</h1>
      </div>
      <div className="AdminAccount__content">
        <ExpandLabel
          onClick={() => togglePart(0)}
          expand={parts[0]}
          label="Profil"
        >
          <UpdateEmail />
          <Button
            onClick={() => {
              dispatch(showModalAction(<UpdatePassword white />));
            }}
          >
            Changer le mot de passe
          </Button>
          <UpdateLogo />
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(1)}
          expand={parts[1]}
          label="Gérer les notifications"
        >
          <Toggle
            status={user?.folderNotif}
            onChange={(folderNotif) =>
              dispatch(setUpdateUser({ folderNotif }, false, false))
            }
          >
            Recevoir un mail lorsqu’un dossier est soumis à validation
          </Toggle>
          <Toggle
            status={user?.billNotification}
            onChange={(billNotification) =>
              dispatch(setUpdateUser({ billNotification }, false, false))
            }
          >
            Recevoir un mail lorsqu’un dossier est à payer
          </Toggle>
          <Toggle
            status={user?.petOwnerNewsNotif}
            onChange={(petOwnerNewsNotif) =>
              dispatch(setUpdateUser({ petOwnerNewsNotif }, false, false))
            }
          >
            Recevoir un mail lorsqu’il y une actualité propriétaire
          </Toggle>
        </ExpandLabel>
      </div>
    </div>
  );
}

export default Account;
