import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateMember } from '../../store/user/user.actions';
import { Input, Select, FileImg, Button } from '../Fields';
import { typeEtablissement } from '../../services/constantes';
import './Forms.scss';

interface IForm {
  label: string;
  onSubmitted?: any;
}

function Form({ label, onSubmitted }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      EtabNom: user?.member?.EtabNom || undefined,
      GroupOrGie: user?.member?.GroupOrGie || undefined,
      nbOfVeto: user?.member?.nbOfVeto || undefined,
      SIRET: user?.member?.SIRET || undefined,
      EtabAdresse: user?.member?.EtabAdresse || undefined,
      EtabCP: user?.member?.EtabCP || undefined,
      EtabVille: user?.member?.EtabVille || undefined,
      telephone: user?.member?.telephone || undefined,
      medias: user?.member?.medias ? user?.member?.medias[0] : undefined,
      iban: user?.member?.iban || undefined,
      bic: user?.member?.bic || undefined,
    },
  });

  const { handleSubmit, getValues } = form;

  function preSubmit() {
    if (onSubmitted) {
      onSubmitted(getValues());
    } else {
      setLoading(true);

      dispatch(
        setUpdateMember({
          ...getValues(),
          medias: getValues().medias ? [getValues().medias] : [],
          GroupOrGie: parseInt(`${getValues().GroupOrGie}`, 10) || undefined,
        })
      ).finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <form
      className="Forms"
      onSubmit={handleSubmit(preSubmit)}
      encType="multipart/form-data"
      noValidate
    >
      <Input
        icon="house"
        type="text"
        name="EtabNom"
        label="Nom de l’établissement*"
        formApi={form}
        required="Merci de renseigner un nom de l’établissement."
      />

      <Select
        icon="house"
        name="GroupOrGie"
        label="Type d’établissement*"
        formApi={form}
        required="Merci de selectionner un type d’établissement."
      >
        {typeEtablissement.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>

      <Input
        icon="house"
        type="number"
        name="nbOfVeto"
        label="Nombre de vétérinaires exerçant au sein du DPE*"
        formApi={form}
        required="Merci de renseigner un nombre."
        inputMode="numeric"
      />

      <Input
        icon="house"
        inputMode="numeric"
        name="SIRET"
        label="SIRET*"
        formApi={form}
        required="Merci de renseigner un numéro SIRET."
        format={(value) => value.replace(/\D/g, '')}
      />

      <Input
        icon="pin"
        type="text"
        name="EtabAdresse"
        label="Adresse*"
        formApi={form}
        required="Merci de renseigner une adresse."
      />

      <Input
        icon="pin"
        type="text"
        name="EtabCP"
        label="Code postal*"
        formApi={form}
        required="Merci de renseigner un code postal."
        maxLength={5}
        inputMode="numeric"
        pattern={{
          value: /[0-9]{5}/,
          message: 'Merci de renseigner une code postal valide.',
        }}
        format={(value) => value?.replace(/\D/g, '')}
      />

      <Input
        icon="pin"
        type="text"
        name="EtabVille"
        label="Ville*"
        formApi={form}
        required="Merci de renseigner une ville."
      />

      <Input
        icon="phone"
        type="text"
        name="telephone"
        inputMode="tel"
        label="Téléphone*"
        formApi={form}
        required="Merci de renseigner un numéro de téléphone."
        maxLength={14}
        pattern={{
          value: /([0-9]{2}\s?){5}/g,
          message: 'Merci de renseigner un numéro de téléphone valide.',
        }}
        format={(value) =>
          value
            ?.replace(/\D/g, '')
            ?.match(/.{1,2}/g)
            ?.join(' ') || ''
        }
      />

      <FileImg name="medias" label="Votre logo :" formApi={form} />

      <Input
        icon="euro"
        type="text"
        inputMode="numeric"
        name="iban"
        label="IBAN*"
        formApi={form}
        required="Merci de renseigner un numéro IBAN."
        maxLength={33}
        pattern={{
          value: /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/g,
          message: 'Merci de renseigner un numéro IBAN valide.',
        }}
        format={(value) =>
          value
            ?.replace(/\W/g, '')
            ?.toUpperCase()
            ?.match(/.{1,4}/g)
            ?.join(' ') || ''
        }
      />

      <Input
        icon="euro"
        type="text"
        name="bic"
        label="BIC*"
        formApi={form}
        required="Merci de renseigner un numéro BIC."
        maxLength={11}
        format={(value) => value?.replace(/\W/g, '').toUpperCase()}
      />

      <Button loading={isLoading}>{label}</Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
};

export default Form;
