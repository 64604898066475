import React, { useState, useEffect } from 'react';
import { getMedia } from '../../services/folders';
import './DownloadMedia.scss';

interface IDownloadMedia {
  mediaId?: string;
  url?: string;
  link?: string;
  name?: string;
  remove?: boolean;
}
function DownloadMedia({
  mediaId,
  link,
  url,
  name,
  remove,
}: IDownloadMedia): JSX.Element | null {
  const [file, setFile] = useState<Record<string, string> | undefined>(
    undefined
  );

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const src = window.URL.createObjectURL(blob);
          setFile({ url: src, fileName: 'fichier' });
        });
    }
    if (mediaId)
      getMedia(parseInt(mediaId.replace('/api/medias/', ''), 10)).then((m) => {
        fetch(`${process.env.REACT_APP_URL_API_DRUPAL}${m.fileLink}`)
          .then((resp) => resp.blob())
          .then((blob) => {
            const src = window.URL.createObjectURL(blob);
            setFile({ url: src, fileName: m.fileName });
          });
      });
  }, [mediaId, url]);

  if (link) {
    return (
      <p className="DownloadMedia">
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="DownloadMedia__link"
        >
          {name || link}
        </a>
      </p>
    );
  }

  if (!file) return null;

  return (
    <p className="DownloadMedia">
      <a
        href={file.url}
        className="DownloadMedia__download"
        download={file.fileName}
      >
        {name || file.fileName}
      </a>
      {remove && (
        <button
          type="button"
          title="supprimer la pièce jointe"
          aria-label="supprimer la pièce jointe"
          className="DownloadMedia__remove"
          onClick={() => {
            console.log('todo');
          }}
        />
      )}
    </p>
  );
}

DownloadMedia.defaultProps = {
  mediaId: null,
  url: null,
  link: null,
  name: null,
  remove: null,
};

export default DownloadMedia;
