import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import './Tabs.scss';

interface ITabs {
  children: React.ReactNode;
}
interface IParams {
  duration?: number;
  easing?: 'linear' | 'easeIn' | 'easeOut' | 'easeInOut';
  power?: number;
  fromTo?: number[];
  loop?: boolean;
}

const Animate = (
  params: IParams,
  callback: (x: number, y: number) => void
): void => {
  let date: any = new Date();
  const duration = params.duration || 300;
  const easing = params.easing || 'easeInOut';
  const power = params.power || 2;
  const fromTo = params.fromTo || [0, 1];
  const loop = params.loop || false;
  const update = () => {
    const newDate: any = new Date();
    const timePassed = newDate - date;
    let progress = timePassed / duration;
    let ease = 0;

    if (progress > 1) progress = 1;

    if (easing === 'linear') {
      ease = progress;
    } else if (easing === 'easeIn') {
      ease = progress ** power;
    } else if (easing === 'easeOut') {
      ease = 1 - (1 - progress) ** power;
    } else if (easing === 'easeInOut') {
      if (progress <= 0.5) ease = (2 * progress) ** power / 2;
      else ease = (2 - (2 * (1 - progress)) ** power) / 2;
    }

    callback(ease * (fromTo[1] - fromTo[0]) + fromTo[0], ease);

    if (progress === 1 && loop) date = new Date();
    if (progress !== 1 || loop) requestAnimationFrame(update);
  };

  requestAnimationFrame(update);
};

function Tabs({ children }: ITabs): JSX.Element {
  const tabsRef = useRef<HTMLHeadingElement>(null);
  const tabsWrapRef = useRef<HTMLHeadingElement>(null);
  const previousHeightRef = useRef<number | null>(null);
  const childrenArray: any[] = React.Children.toArray(children);
  const [current, setCurrent] = useState(childrenArray[0].key);
  const index = childrenArray.findIndex((child) => child.key === current);

  useMemo(() => {
    const tabsChildren = tabsWrapRef.current;
    const heightChildren = tabsChildren?.clientHeight || 0;
    if (previousHeightRef.current !== null) {
      previousHeightRef.current = heightChildren;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useLayoutEffect(() => {
    const tabsParent = tabsRef.current;
    const tabsChildren = tabsWrapRef.current;
    const heightChildren = tabsChildren?.clientHeight || 0;

    if (previousHeightRef.current !== null) {
      const fromTo = [previousHeightRef.current || 0, heightChildren];
      Animate({ duration: 300, fromTo }, (data) => {
        if (data === heightChildren && tabsParent) {
          tabsParent.style.height = 'auto';
        } else if (tabsParent) tabsParent.style.height = `${data}px`;
      });
    }

    previousHeightRef.current = heightChildren;
  }, [index]);

  return (
    <div className="Tabs" ref={tabsRef}>
      <div className="Tabs__wrap" ref={tabsWrapRef}>
        <nav className="Tabs__buttons">
          <div
            className="Tabs__current"
            style={{
              width: `${100 / childrenArray.length}%`,
              transform: `translateX(${100 * index}%)`,
            }}
            aria-hidden="true"
          >
            {childrenArray.map((child, i) => (
              <div
                className="Tabs__current__label"
                key={child.key}
                style={{
                  left: `${100 * i}%`,
                  transform: `translateX(-${100 * index}%)`,
                }}
              >
                {child?.props.title}
              </div>
            ))}
          </div>
          {childrenArray.length === 1 ? (
            <div className="Tabs__button">{childrenArray[0].props.title}</div>
          ) : (
            childrenArray.map((child) => (
              <button
                type="button"
                className="Tabs__button"
                key={child.key}
                onClick={() => setCurrent(child.key)}
              >
                {child.props.title}
              </button>
            ))
          )}
        </nav>
        <section>
          {childrenArray.filter((child) => child.key === current)}
        </section>
      </div>
    </div>
  );
}

export default Tabs;
