import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userSelector } from '../../store/user/user.selectors';

interface IPrivateRoute {
  children?: React.ReactNode;
  render?: any;
  path: string;
  roleAccess?: string;
  exact?: boolean;
}

function PrivateRoute({
  children,
  path,
  render,
  roleAccess,
  exact,
}: IPrivateRoute): JSX.Element | null {
  const user = useSelector(userSelector);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props: any) => {
        const { location } = props;

        // console.log(userAccess, user?.root);

        if (user && roleAccess && roleAccess === user.role) {
          return children || render(props);
        }

        if (user && !roleAccess) {
          return children || render(props);
        }

        return (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
}

PrivateRoute.defaultProps = {
  children: undefined,
  render: undefined,
  roleAccess: undefined,
  exact: false,
};

export default PrivateRoute;
