/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import './Link.scss';

interface ILink {
  href: string;
  children?: React.ReactNode;
  className?: string;
  title?: string;
  ariaLabel?: string;
  isCurrent?: boolean;
  icon?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

function Link({
  href,
  children,
  className,
  title,
  ariaLabel,
  onClick,
  isCurrent,
  icon,
  ...etc
}: ILink): JSX.Element {
  const location = useLocation();

  if (/^https?:\/\//.test(href)) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={`Link ${icon ? '-icon' : ''} ${className}`}
        title={title}
        aria-label={ariaLabel}
        onClick={(e) => onClick && onClick(e)}
        {...etc}
      >
        {children}
      </a>
    );
  }

  let current = false;

  if (
    isCurrent &&
    /^\/(admin|membre|proprietaire)$/.test(href) &&
    location.pathname === href
  ) {
    current = true;
  } else if (
    isCurrent &&
    !/^\/(admin|membre|proprietaire)$/.test(href) &&
    location.pathname.includes(href)
  ) {
    current = true;
  }
  return (
    <RouterLink
      to={href}
      className={`Link ${className} ${current ? '-current' : ''}`}
      onClick={(e) => onClick && onClick(e)}
      title={title}
      aria-label={ariaLabel}
    >
      {children}
    </RouterLink>
  );
}

Link.defaultProps = {
  children: null,
  className: '',
  title: null,
  ariaLabel: null,
  onClick: undefined,
  isCurrent: false,
  icon: null,
};

export default Link;
