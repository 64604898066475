import React from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';

import './Textarea.scss';

interface ITextarea {
  name: string;
  label: string;
  maxLength?: number;
  formApi: UseFormMethods<any>;
  required?: string;
  disabled?: boolean;
  placeholder?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  format?: (value: string) => string | undefined;
  rows?: number;
  autoHeight?: boolean;
  white?: boolean;
  icon?: string;
}

function Textarea({
  name,
  label,
  formApi,
  required,
  disabled,
  placeholder,
  maxLength,
  pattern,
  format,
  rows = 5,
  icon,
  autoHeight,
  white,
}: ITextarea): JSX.Element {
  const { errors, formState } = formApi;

  return (
    <p className={`Textarea ${disabled ? '-disabled' : ''}`}>
      <label className="Textarea__group" htmlFor={name}>
        <span className="Textarea__label">{label}</span>
        <span className="Textarea__fieldGroup">
          <span className="Textarea__iconGroup">
            <span className={`Textarea__icon -${icon || 'pen'}`} />
            <Controller
              name={name}
              control={formApi.control}
              rules={{ required, pattern }}
              render={({ onChange, onBlur, value, name: n }) => (
                <textarea
                  name={n}
                  id={n}
                  value={value || ''}
                  className={`Textarea__field ${white ? '-white' : ''} ${
                    errors &&
                    (formState.isDirty || formState.isSubmitted) &&
                    errors[name]
                      ? '-error'
                      : ''
                  }`}
                  onBlur={onBlur}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={(event: any) => {
                    const { target } = event;
                    if (autoHeight) {
                      target.style.height = `${target.scrollHeight}px`;
                    }
                    const { value: v } = target;
                    onChange(format ? format(v) : v);
                  }}
                  maxLength={maxLength}
                  rows={rows}
                >
                  okok
                </textarea>
              )}
            />
          </span>
        </span>

        {errors &&
          (formState.isDirty || formState.isSubmitted) &&
          errors[name] && (
            <span className="Textarea__error">{errors[name].message}</span>
          )}
      </label>
    </p>
  );
}

Textarea.defaultProps = {
  required: null,
  disabled: null,
  placeholder: null,
  maxLength: null,
  pattern: null,
  format: null,
  rows: 5,
  autoHeight: false,
  icon: null,
  white: false,
};

export default Textarea;
