interface ISelector {
  status: boolean;
  type?: 'error' | 'normal';
  content?: React.ReactNode;
  params?: { closeBack: boolean };
}

interface IAction {
  type: string;
  payload: ISelector;
  params?: { closeBack: boolean };
}

const initialState: ISelector = {
  status: false,
  type: 'normal',
  content: null,
};

export const SET_MODAL_ACTION = 'SET_MODAL_ACTION';

export function modalReducer(state = initialState, action: IAction): ISelector {
  switch (action.type) {
    case SET_MODAL_ACTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export type { ISelector, IAction };
