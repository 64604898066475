import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '../../store/modal/modal.actions';
import { getMedia } from '../../services/folders';
import './PopinMedia.scss';

interface IPopinMedia {
  mediaId: string;
}
function PopinMedia({ mediaId }: IPopinMedia): JSX.Element | null {
  const dispatch = useDispatch();
  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    getMedia(parseInt(mediaId.replace('/api/medias/', ''), 10)).then(
      ({ fileLink }) => {
        setSrc(`${process.env.REACT_APP_URL_API_DRUPAL}${fileLink}`);
      }
    );
  }, [mediaId]);

  if (!src) return null;

  return (
    <button
      type="button"
      className="PopinMedia"
      onClick={(e) =>
        dispatch(
          showModalAction(
            <img src={src} alt="" className="PopinMedia__popin" />
          )
        )
      }
    >
      <img src={src} alt="" className="PopinMedia__img" />
    </button>
  );
}

export default PopinMedia;
