import React, { useEffect, useState } from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  showModalAction,
  showErrorModalAction,
} from '../../../store/modal/modal.actions';
import { mediaUpload } from '../../../services/folders';
import { IFile } from '../../../services/api.interface';
import Loading from '../../Loading';
import ImgUploaded from './ImgUploaded';
import './FileImg.scss';

interface IFileImg {
  name: string;
  label: string;
  formApi: UseFormMethods<any>;
  required?: string;
  multiple?: boolean;
  disabled?: boolean;
  maxSize?: number;
  white?: boolean;
  pending?: (e: boolean) => void;
  onSubmitted?: (e: string | string[]) => void;
}

function InputFile({
  props: { label, multiple, disabled, maxSize, white, pending, onSubmitted },
  formApi: { errors, formState },
  control: { name, onChange, onBlur, value, isDirty },
}: any) {
  const dispatch = useDispatch();
  let initialState: IFile[] = [];
  if (value && Array.isArray(value))
    initialState = value.map((media: string) => ({
      id: Math.round(Math.random() * 1000000),
      idMedia: media,
    }));
  else if (value) {
    initialState = [
      {
        id: Math.round(Math.random() * 1000000),
        idMedia: value,
      },
    ];
  }
  const [filesList, setFilesList] = useState<IFile[]>(initialState);

  // useEffect(() => {
  //   onChange(filesList.filter((e) => e.idMedia).map((e) => e.idMedia));
  // }, [multiple, filesList, onChange]);

  useEffect(() => {
    const files = filesList.filter((e) => e.idMedia).map((e) => e.idMedia);
    if (multiple) {
      onChange(files.length ? files : null);
    } else {
      onChange(files[0]);
    }
  }, [multiple, filesList, onChange]);

  function onInputChange({ target }: { target: HTMLInputElement }) {
    let files = target.files
      ? Object.values(target.files).map((val) => ({
          file: val,
          id: Math.round(Math.random() * 1000000),
        }))
      : [];

    if (
      maxSize &&
      files.some(({ file }: { file: File }) => file.size > maxSize * 1048576)
    ) {
      const filesTooLarge = files.filter(
        ({ file }: { file: File }) => file.size > maxSize * 1048576
      );

      files = files.filter(
        ({ file }: { file: File }) => file.size < maxSize * 1048576
      );

      dispatch(
        showModalAction(
          <>
            <span
              className="FileImg__toolarge__alert"
              role="img"
              aria-label="Attention"
            >
              ⚠️
            </span>
            <p className="FileImg__toolarge__title">
              Impossible de télécharger{' '}
              {filesTooLarge.length > 1 ? 'les photos ' : `la photo `}
              <b>{filesTooLarge.map((e) => e.file.name).join(', ')}</b>
            </p>
            <p className="FileImg__toolarge__info">
              La taille d&apos;une photo ne doit pas dépasser {maxSize}Mo
            </p>
          </>
        )
      );
    }

    if (pending) pending(true);

    setFilesList((state) => [...state, ...files]);

    const uploads = mediaUpload(files);
    uploads.forEach((promise) =>
      promise
        .then((e) => {
          setFilesList((s) => s.map((f) => (e.id === f.id ? e : f)));
        })
        .catch((err) => {
          dispatch(
            showErrorModalAction(
              `${err.status} | ${err.data['hydra:description']}`
            )
          );
          setFilesList((s) => s.filter((f) => f?.idMedia));
        })
    );

    Promise.all(uploads)
      .then((list) => {
        if (multiple && onSubmitted) {
          onSubmitted(list.map((e) => e.idMedia));
        } else if (onSubmitted) {
          onSubmitted(list.map((e) => e.idMedia)[0]);
        }
      })
      .finally(() => {
        if (pending) pending(false);
      });
  }

  return (
    <div className={`FileImg ${disabled ? '-disabled' : ''}`}>
      <div className="FileImg__label">{label}</div>

      <div className="FileImg__group">
        {filesList.map(({ file, id, idMedia }) => (
          <div className="FileImg__item" key={id}>
            <ImgUploaded
              file={file}
              media={idMedia}
              className="FileImg__item__img"
            />
            {!idMedia && file ? (
              <div
                className="FileImg__item__loading"
                title="Chargement en cours..."
              >
                <Loading white small />
              </div>
            ) : (
              <button
                type="button"
                onClick={() => {
                  setFilesList((state) =>
                    state.filter((e) => e.idMedia !== idMedia)
                  );
                  if (!multiple && onSubmitted) onSubmitted(null);
                }}
                aria-label="Supprimer l'image"
                title="Supprimer l'image"
                className="FileImg__item__remove"
              />
            )}
          </div>
        ))}

        {(filesList.length < 1 || multiple) && (
          <label className="FileImg__input" htmlFor={name}>
            <input
              name={name}
              type="file"
              id={name}
              onBlur={onBlur}
              disabled={disabled}
              multiple={multiple}
              onChange={onInputChange}
              accept="image/png, image/jpeg, image/jpg, image/gif"
              className="FileImg__input__field"
            />
            <div
              className={`FileImg__input__button ${white ? '-white' : ''} ${
                errors && (isDirty || formState.isSubmitted) && errors[name]
                  ? '-error'
                  : ''
              }`}
              aria-label="Uploader une image"
              title="Uploader une image"
            />
          </label>
        )}
      </div>
      {/* {console.log(errors)}
      {errors && errors[name] && (
        <div className="Input__error">{errors[name].message}</div>
      )} */}
      {errors && errors[name] && (
        <div className="Input__error">{errors[name].message}</div>
      )}
    </div>
  );
}

function FileImg({ name, formApi, required, ...props }: IFileImg): JSX.Element {
  return (
    <Controller
      name={name}
      control={formApi.control}
      rules={{ required }}
      render={(props1, props2) => (
        <InputFile
          props={props}
          formApi={formApi}
          control={{ ...props1, ...props2 }}
        />
      )}
    />
  );
}

FileImg.defaultProps = {
  required: null,
  multiple: false,
  disabled: null,
  maxSize: 5,
  pending: null,
  onSubmitted: null,
  white: false,
};

export default FileImg;
