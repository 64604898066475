import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  showModalAction,
  showErrorModalAction,
} from '../../store/modal/modal.actions';
import Link from '../../components/Link';
import { CheckPassword, Button } from '../../components/Fields';
import { ReactComponent as Logo } from '../../img/logo.svg';
import './NewPassword.scss';
import { resetPassword } from '../../services/users';

interface ParamTypes {
  token: string;
}

function NewPassword(): JSX.Element {
  const { token } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const isValid = useRef(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      password: '',
    },
  });

  const { handleSubmit, getValues } = form;

  function onSubmit() {
    if (isValid.current) {
      resetPassword({ ...getValues(), token })
        .then((e) => {
          dispatch(
            showModalAction(
              <span>Le mot de passe a bien été mofifié&nbsp;!</span>
            )
          );
        })
        .catch((err) => {
          dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
        });
    }
  }

  return (
    <div className="NewPassword">
      <div>
        <Link
          className="ForgetPassword__back"
          href="/login"
          title="Retour"
          ariaLabel="Retour"
        />
      </div>
      <div className="NewPassword__content">
        <Logo className="NewPassword__logo" />
        <h1 className="NewPassword__title">Nouveau mot de passe</h1>
      </div>

      <form
        className="NewPassword__form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <CheckPassword
          name="password"
          label1="Mot de passe.*"
          label2="Confirmation du mot de passe.*"
          requiredLabel1="Merci de renseigner un mot de passe."
          requiredLabel2="Merci de confirmer le mot de passe."
          formApi={form}
          isValid={(e) => {
            isValid.current = e;
          }}
        />

        <Button>Créer le compte</Button>
      </form>

      <div className="NewPassword__footer" />
    </div>
  );
}

export default NewPassword;
