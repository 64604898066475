import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUpdateFolder } from '../../store/user/user.actions';
import { FileDoc, Textarea, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  folderId: string;
  onSubmitted?: () => void;
  white?: boolean;
}

function Form({ folderId, onSubmitted, white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { handleSubmit, getValues } = form;

  function onSubmit() {
    setLoading(true);
    const values = getValues();
    dispatch(
      setUpdateFolder(folderId, {
        missingEltAdded: true,
        media: values.medias,
        comment: values.comment,
      })
    )
      .then(() => {
        if (onSubmitted) onSubmitted();
      })
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FileDoc
        name="medias"
        formApi={form}
        multiple
        label="Pièce.s jointe.s"
        white={white}
      />
      <Textarea
        name="comment"
        label="Commentaires*"
        formApi={form}
        required="Merci de renseigner un commentaire"
        autoHeight
        white={white}
      />
      <Button loading={isLoading}>Envoyer</Button>
    </form>
  );
}

Form.defaultProps = {
  onSubmitted: null,
  white: false,
};

export default Form;
