import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateUser } from '../../store/user/user.actions';
import { FileImg } from '../Fields';
import './Forms.scss';

interface IForm {
  label?: string;
  white?: boolean;
}

function Form({ label, white }: IForm): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      logo: user?.logo || '',
    },
  });

  return (
    <form className="Forms">
      <FileImg
        name="logo"
        label={label || 'Votre logo :'}
        formApi={form}
        white={white}
        onSubmitted={(logo) => dispatch(setUpdateUser({ logo }, false))}
      />
    </form>
  );
}

Form.defaultProps = {
  label: null,
  white: false,
};

export default Form;
