import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  showModalAction,
  showErrorModalAction,
} from '../../../store/modal/modal.actions';
import { ExpandLabel } from '../../../components/Expand';
import {
  Input,
  Select,
  Textarea,
  Button,
  InputDate,
} from '../../../components/Fields';
import {
  genre,
  espece,
  typePathologie,
  sexe,
  boolean,
} from '../../../services/constantes';
import { IFolder } from '../../../services/api.interface';
import { getFolder, updateFolder } from '../../../services/folders';
import { putUserById, putOwnerIdById } from '../../../services/users';
import './FolderEdit.scss';
import { updateUser } from '../../../store/user/user.actions';

function FolderEdit(): JSX.Element {
  const dispatch = useDispatch();
  const { folderId } = useParams<{ folderId: string }>();
  const [parts, setParts] = useState<boolean[]>([true, false, false, false]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm<any>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      etbtName: '',
      vetoIncharge: '',
      contactMail: '',
      contactPhone: '',
      structAdress: '',
      structureCP: '',
      structureVille: '',

      genre: '',
      firstname: '',
      lastname: '',
      adress: '',
      cp: '',
      ville: '',
      phone: '',
      emailProprietaire: '',

      animalName: '',
      animalSpecies: '',
      animalRace: '',
      animalSex: '',
      animalSterilise: '',
      animalBirthDate: '',
      animalIdentification: '',

      pathoType: '',
      pathoSignes: '',
      estimateAmount: '',
      pathoEuthanasie: '',
    },
  });
  const { handleSubmit, setValue, errors } = form;

  useEffect(() => {
    getFolder(folderId).then((folder: IFolder) => {
      Object.entries({
        etbtName: folder.etbtName,
        vetoIncharge: folder.vetoIncharge,
        contactMail: folder.contactMail,
        contactPhone: folder.contactPhone,
        structAdress: folder.structAdress,
        structureCP: folder.structureCP,
        structureVille: folder.structureVille,

        genre: folder.userProprietaire.user.genre,
        firstname: folder.userProprietaire.user.firstname,
        lastname: folder.userProprietaire.user.lastname,
        adress: folder.userProprietaire.adress,
        cp: folder.userProprietaire.cp,
        ville: folder.userProprietaire.ville,
        phone: folder.userProprietaire.phone,
        emailProprietaire: folder.userProprietaire.user.email,

        animalName: folder.animalName,
        animalSpecies: folder.animalSpecies,
        animalRace: folder.animalRace,
        animalSex: folder.animalSex,
        animalSterilise: folder.animalSterilise,
        animalBirthDate: new Date(folder.animalBirthDate),
        animalIdentification: folder.animalIdentification,

        pathoType: folder.pathoType,
        pathoSignes: folder.pathoSignes,
        estimateAmount: folder.estimateAmount,
        pathoEuthanasie: folder.pathoEuthanasie,
      }).forEach(([name, value]) => setValue(name, value));
    });
  }, [folderId, setValue]);

  function showAllParts() {
    setParts((e) => e.map(() => true));
  }

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  function submit(folder: any) {
    setLoading(true);

    updateFolder(folderId, {
      etbtName: folder.etbtName,
      vetoIncharge: folder.vetoIncharge,
      contactMail: folder.contactMail,
      contactPhone: folder.contactPhone,
      structAdress: folder.structAdress,
      structureCP: folder.structureCP,
      structureVille: folder.structureVille,

      animalName: folder.animalName,
      animalSpecies: parseInt(`${folder.animalSpecies}`, 10),
      animalRace: folder.animalRace,
      animalSex: parseInt(`${folder.animalSex}`, 10),
      animalSterilise: parseInt(`${folder.animalSterilise}`, 10),
      animalBirthDate: folder.animalBirthDate,
      animalIdentification: folder.animalIdentification,

      pathoType: parseInt(`${folder.pathoType}`, 10),
      pathoSignes: folder.pathoSignes,
      estimateAmount: parseInt(`${folder.estimateAmount}`, 10),
      pathoEuthanasie: parseInt(`${folder.pathoEuthanasie}`, 10),
    })
      .then((e) => {
        const userId = e.userProprietaire.user['@id'].replace(
          '/api/users/',
          ''
        );
        return putUserById(userId, {
          genre: parseInt(`${folder.genre}`, 10),
          firstname: folder.firstname,
          lastname: folder.lastname,
          email: folder.emailProprietaire,
        });
      })
      .then((e) => {
        const ownerId = e.userProprietaire['@id'].replace(
          '/api/user_proprietaires/',
          ''
        );
        return putOwnerIdById(ownerId, {
          adress: folder.adress,
          cp: folder.cp,
          ville: folder.ville,
          phone: folder.phone,
        });
      })
      .then(() => {
        dispatch(updateUser());
        dispatch(
          showModalAction(<span>Le dossier a bien été modifié&nbsp;!</span>)
        );
      })
      .catch((err) => {
        dispatch(showErrorModalAction(`${err.status} | ${err?.data?.message}`));
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="FolderEdit">
      <form
        className="Forms"
        onSubmit={handleSubmit(submit)}
        encType="multipart/form-data"
        noValidate
      >
        <ExpandLabel
          label="Informations structure de soins"
          expand={parts[0]}
          onClick={() => togglePart(0)}
        >
          <Input
            icon="house"
            type="text"
            name="etbtName"
            label="Nom de l’établissement*"
            formApi={form}
            required="Merci de renseigner un nom de l’établissement."
          />
          <Input
            icon="house"
            type="text"
            name="vetoIncharge"
            label="Nom du vétérinaire en charge du cas*"
            formApi={form}
            required="Merci de renseigner le Nom du vétérinaire en charge du cas."
          />
          <Input
            icon="email"
            type="email"
            name="contactMail"
            label="Mail de contact*"
            formApi={form}
            required="Merci de renseigner un email."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse mail valide.',
            }}
          />
          <Input
            icon="phone"
            type="tel"
            name="contactPhone"
            label="Téléphone de contact*"
            formApi={form}
            required="Merci de renseigner un numéro de téléphone."
            inputMode="tel"
            maxLength={14}
            pattern={{
              value: /([0-9]{2}\s?){5}/g,
              message: 'Merci de renseigner un numéro de téléphone valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\D/g, '')
                ?.match(/.{1,2}/g)
                ?.join(' ') || ''
            }
          />
          <Input
            icon="pin"
            type="text"
            name="structAdress"
            label="Adresse*"
            formApi={form}
            required="Merci de renseigner une adresse."
          />
          <Input
            icon="pin"
            type="text"
            name="structureCP"
            label="Code postal*"
            formApi={form}
            required="Merci de renseigner un code postal."
            maxLength={5}
            inputMode="numeric"
            pattern={{
              value: /[0-9]{5}/,
              message: 'Merci de renseigner une code postal valide.',
            }}
            format={(value) => value?.replace(/\D/g, '')}
          />
          <Input
            icon="pin"
            type="text"
            name="structureVille"
            label="Ville*"
            formApi={form}
            required="Merci de renseigner une ville."
          />
        </ExpandLabel>
        <ExpandLabel
          label="Informations propriétaire"
          expand={parts[1]}
          onClick={() => togglePart(1)}
        >
          <Select
            icon="house"
            name="genre"
            label="Genre*"
            formApi={form}
            required="Merci de selectionner un genre."
          >
            {genre.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
          <Input
            icon="user"
            type="text"
            name="firstname"
            label="Prénom*"
            formApi={form}
            required="Merci de renseigner un prénom."
          />
          <Input
            icon="user"
            type="text"
            name="lastname"
            label="Nom*"
            formApi={form}
            required="Merci de renseigner un nom."
          />
          <Input
            icon="pin"
            type="text"
            name="adress"
            label="Adresse*"
            formApi={form}
            required="Merci de renseigner une adresse."
          />
          <Input
            icon="pin"
            type="text"
            name="cp"
            label="Code postal*"
            formApi={form}
            required="Merci de renseigner un code postal."
            maxLength={5}
            inputMode="numeric"
            pattern={{
              value: /[0-9]{5}/,
              message: 'Merci de renseigner une code postal valide.',
            }}
            format={(value) => value?.replace(/\D/g, '') || ''}
          />
          <Input
            icon="pin"
            type="text"
            name="ville"
            label="Ville*"
            formApi={form}
            required="Merci de renseigner une ville."
          />
          <Input
            icon="phone"
            type="tel"
            name="phone"
            label="Téléphone*"
            formApi={form}
            required="Merci de renseigner un numéro de téléphone."
            inputMode="tel"
            maxLength={14}
            pattern={{
              value: /([0-9]{2}\s?){5}/g,
              message: 'Merci de renseigner un numéro de téléphone valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\D/g, '')
                ?.match(/.{1,2}/g)
                ?.join(' ') || ''
            }
          />
          <Input
            icon="email"
            type="email"
            name="emailProprietaire"
            label="Mail*"
            formApi={form}
            required="Merci de renseigner un email."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse mail valide.',
            }}
          />
        </ExpandLabel>
        <ExpandLabel
          label="Informations animal"
          expand={parts[2]}
          onClick={() => togglePart(2)}
        >
          <Input
            icon="paw"
            type="text"
            name="animalName"
            label="Nom*"
            formApi={form}
            required="Merci de renseigner un nom."
          />
          <Select
            icon="paw"
            name="animalSpecies"
            label="Espèce*"
            formApi={form}
            required="Merci de selectionner un genre."
          >
            {espece.map((e: any) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
          <Input
            icon="paw"
            type="text"
            name="animalRace"
            label="Race"
            formApi={form}
          />
          <Select
            icon="paw"
            name="animalSex"
            label="Sexe*"
            formApi={form}
            required="Merci de selectionner un sexe."
          >
            {sexe.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
          <Select
            icon="paw"
            name="animalSterilise"
            label="Stérilisé*"
            formApi={form}
            required="Merci de selectionner une réponse."
          >
            {boolean.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
          <InputDate
            name="animalBirthDate"
            label="Date de naissance*"
            formApi={form}
            required="Merci de renseigner une date."
            maxDate={new Date()}
          />
          <Input
            icon="fingerPrint"
            type="text"
            name="animalIdentification"
            label="Identification"
            formApi={form}
            maxLength={15}
            format={(value) => value?.replace(/\D/g, '').toUpperCase()}
            description="(Si l’animal n’est pas identifié, il devra l’être avant le règlement de la facture)"
          />
        </ExpandLabel>
        <ExpandLabel
          label="Informations demande de soin"
          expand={parts[3]}
          onClick={() => togglePart(3)}
        >
          <Select
            icon="paw"
            name="pathoType"
            label="Type de pathologie*"
            formApi={form}
            required="Merci de selectionner une pathologie."
          >
            {typePathologie.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
          <Textarea
            icon="paw"
            label="Descriptions médicales*"
            name="pathoSignes"
            required="Merci de renseigner une description médicale."
            formApi={form}
          />
          <Input
            icon="euro"
            type="number"
            name="estimateAmount"
            label="Montant du devis*"
            formApi={form}
            required="Merci de renseigner un montant."
          />
          <Select
            icon="paw"
            name="pathoEuthanasie"
            label="L’euthanasie a-t-elle été évoquée avec le propriétaire ?*"
            formApi={form}
            required="Merci de selectionner une réponse."
          >
            {boolean.map((e) => (
              <option value={e.id} key={e.id}>
                {e.label}
              </option>
            ))}
          </Select>
        </ExpandLabel>

        <Button
          loading={isLoading}
          onClick={() => {
            if (Object.keys(errors).length) showAllParts();
          }}
        >
          Enregistrer les modifications
        </Button>
      </form>
    </div>
  );
}

export default FolderEdit;
