import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';
import FolderList, { IFolderItem } from '../../../components/FolderList';
import { LinkFolder } from '../../../components/Link';
import DetailFolderAdmin from '../../../components/DetailFolderAdmin';
import {
  folderStatusAdmin,
  getAdminFolderStatusById,
} from '../../../services/constantes';
import './Folders.scss';

interface ParamTypes {
  filter: string;
  folderId: string;
}

function Folders(): JSX.Element {
  const user = useSelector(userSelector);
  const { filter, folderId } = useParams<ParamTypes>();
  const [search, setSearch] = useState<string | null>(null);
  const [folderList, setFolderList] = useState<IFolderItem[] | null | Error>(
    null
  );
  const [folderFilteredList, setFolderFilteredList] = useState<
    IFolderItem[] | null | Error
  >(null);

  useEffect(() => {
    setFolderList(null);
    setFolderFilteredList(null);

    if (user?.folders) {
      const folders = user.folders.map((folder) => ({
        id: folder['@id'],
        title: `${folder.animalName} - ${folder.ownerFirstname} ${folder.ownerLastname}`,
        statusLabel: [1, 5].includes(folder.status)
          ? getAdminFolderStatusById(folder.status)
          : null,
        dateCreate: new Date(folder.createdAt),
        notification: [2, 6].includes(folder.status) && !!folder.isUpdate,
        status: folder.status,
        content: <DetailFolderAdmin folderId={folder.id} />,
        expanded: (folderStatusAdmin[filter] ? folderId : filter) === folder.id,
        search: [
          folder.id,
          folder.ownerFirstname,
          folder.ownerLastname,
          folder.animalName,
          folder.etbtName,
        ],
      }));

      const foldersFiltered = folders
        .filter((e) =>
          folderStatusAdmin[filter]
            ? folderStatusAdmin[filter].includes(e.status)
            : true
        )
        .filter((folder) =>
          search
            ? folder.search.some((value) =>
                value?.toLowerCase().includes(search.toLowerCase())
              )
            : true
        )
        .sort((a, b) => {
          return new Date(a.dateCreate).getTime() <
            new Date(b.dateCreate).getTime()
            ? 1
            : -1;
        });

      setFolderList(folders);
      setFolderFilteredList(foldersFiltered);
    }
  }, [filter, folderId, user, search]);

  function checkNotification(type: string) {
    return Array.isArray(folderList)
      ? folderList.filter(
          (e) =>
            e.status &&
            e.notification &&
            folderStatusAdmin[type].includes(e.status)
        ).length
      : 0;
  }

  return (
    <div className="AdminFolders">
      <div className="AdminFolders__header">
        <h1 className="AdminFolders__header__title">Dossiers</h1>
        <nav>
          <ul className="AdminFolders__header__nav">
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/a-valider"
                notification={checkNotification('a-valider')}
                folderPending
              >
                Dossiers <b>à valider</b>
              </LinkFolder>
            </li>
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/en-attente"
                notification={checkNotification('en-attente')}
                folderPending
              >
                Dossiers <b>en attente</b>
              </LinkFolder>
            </li>
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/en-cours"
                notification={checkNotification('en-cours')}
                folderProgress
              >
                Dossiers <b>en cours</b>
              </LinkFolder>
            </li>
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/a-regler"
                notification={checkNotification('a-regler')}
                folderPending
              >
                Dossiers <b>à régler</b>
              </LinkFolder>
            </li>
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/clotures"
                notification={checkNotification('clotures')}
                folderClose
              >
                Dossiers <b>clôturés</b>
              </LinkFolder>
            </li>
            <li className="AdminFolders__header__nav__list">
              <LinkFolder
                href="/admin/dossiers/refuses"
                notification={checkNotification('refuses')}
                folderReject
              >
                Dossiers <b>refusés</b>
              </LinkFolder>
            </li>
          </ul>
        </nav>
        <div className="AdminFolders__header__search">
          <input
            type="search"
            placeholder="Rechercher un dossier"
            className="AdminFolders__header__search__input"
            onChange={({ target }) => setSearch(target.value)}
          />
        </div>
      </div>
      <FolderList
        foldersList={folderFilteredList}
        notFoundLabel="Aucun dossier trouvé..."
        expandForRender
      />
    </div>
  );
}

export default Folders;
