import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import Layout from '../../components/Layout';
import PrivateRoute from '../../components/PrivateRoute';
import Page404 from '../Page404';
import Home from './Home';
import Folders from './Folders';
import FolderEdit from './FolderEdit';
import Account from './Account';
import Members from './Members';
import AnimalsNews from './AnimalsNews';
import { ReactComponent as House } from '../../img/house.svg';
import { ReactComponent as Folder } from '../../img/folder-vetaricare.svg';
import { ReactComponent as People } from '../../img/people.svg';
import { ReactComponent as Paw } from '../../img/paw.svg';

function Routes({ props }: any): JSX.Element {
  const user = useSelector(userSelector);

  const {
    match: { path },
  } = props;

  const menu = [
    { label: 'Tableau de bord', route: `${path}`, Icon: House },
    { label: 'Dossiers', route: `${path}/dossiers`, Icon: Folder },
    {
      label: 'Nouvelles des animaux',
      route: `${path}/nouvelles-des-animaux`,
      Icon: Paw,
    },
    { label: 'Adhérents', route: `${path}/adherents`, Icon: People },
    {
      label: 'Mon compte',
      route: `${path}/mon-compte`,
      mediaId: user?.logo,
    },
  ];

  const footer = [
    { label: 'Dossier', route: `${path}/dossiers`, Icon: Folder },
    { label: 'Tableau de bord', route: `${path}`, Icon: House },
  ];

  return (
    <Layout menu={menu} footer={footer}>
      <Switch>
        <PrivateRoute
          path={`${path}/`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <Home />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/dossiers/editer/:folderId`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <FolderEdit />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/dossiers/:filter/:folderId?`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <Folders />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/dossiers`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <Redirect to={{ pathname: `${path}/dossiers/a-valider` }} />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/nouvelles-des-animaux/:folderId?`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <AnimalsNews />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/adherents/:memberId?`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <Members />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/mon-compte`}
          roleAccess="ROLE_ADMIN_VETERICARE"
          exact
        >
          <Account />
        </PrivateRoute>

        <PrivateRoute path="*" roleAccess="ROLE_ADMIN_VETERICARE" exact>
          <Page404 />
        </PrivateRoute>
      </Switch>
    </Layout>
  );
}

export default Routes;
export { PrivateRoute };
