import React, { useState } from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';
import './Checkbox.scss';

interface ICheckbox {
  name: string;
  children: React.ReactNode;
  formApi: UseFormMethods<any>;
  required?: boolean;
  disabled?: boolean;
}

function Checkbox({
  children,
  name,
  formApi,
  required,
  disabled,
}: ICheckbox): JSX.Element {
  const { errors, formState, register } = formApi;

  return (
    <p className={`Checkbox ${disabled ? '-disabled' : ''}`}>
      <label className="Checkbox__group" htmlFor={name}>
        <span className="Checkbox__fields">
          <input
            type="checkbox"
            name={name}
            id={name}
            ref={register({ required })}
            className={`Checkbox__field ${
              errors && formState.isSubmitted && errors[name] ? '-error' : ''
            }`}
            disabled={disabled}
          />
          <span className="Checkbox__status" />
          <span className="Checkbox__label">{children}</span>
        </span>
        {errors && formState.isSubmitted && errors[name] && (
          <span className="Checkbox__error">
            Merci d&apos;accepter les conditions
          </span>
        )}
      </label>
    </p>
  );
}

Checkbox.defaultProps = {
  required: null,
  disabled: null,
};

export default Checkbox;
