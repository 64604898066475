import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { userSelector } from '../../store/user/user.selectors';
import Layout from '../../components/Layout';
import PrivateRoute from '../../components/PrivateRoute';
import Page404 from '../Page404';
import Home from './Home';
import NewFolder from './NewFolder';
import Folders from './Folders';
import Subscribe from './Subscribe';
import Contact from './Contact';
import Profile from './Profile';

import { ReactComponent as House } from '../../img/house.svg';
import { ReactComponent as FolderVeto } from '../../img/folder-vetaricare.svg';
import { ReactComponent as Folder } from '../../img/folder.svg';
import { ReactComponent as Money } from '../../img/money.svg';
import { ReactComponent as Chat } from '../../img/chat.svg';

function Routes({ props }: any): JSX.Element {
  const user = useSelector(userSelector);

  const {
    match: { path },
  } = props;

  const menu = [
    { label: 'Tableau de bord', route: `${path}`, Icon: House },
    {
      label: 'Nouveau dossier',
      route: `${path}/nouveau-dossier`,
      Icon: Folder,
    },
    { label: 'Dossiers', route: `${path}/dossiers`, Icon: FolderVeto },
    {
      label: 'Mon profil',
      route: `${path}/mon-profil`,
      mediaId: user?.member?.medias && user.member.medias[0],
    },
    {
      label: 'Faire un don',
      route: 'https://www.vetericare.fr/soutenez-l-association',
      Icon: Money,
    },
    { label: 'Nous contacter', route: `${path}/nous-contacter`, Icon: Chat },
  ];

  const footer = [
    {
      label: 'Nouveau dossier',
      route: `${path}/nouveau-dossier`,
      Icon: Folder,
    },
    { label: 'Tableau de bord', route: `${path}`, Icon: House },
  ];

  if (!user?.member) {
    return (
      <Switch>
        <PrivateRoute path={`${path}/`} roleAccess="ROLE_VETERINAIRE" exact>
          <Subscribe />
        </PrivateRoute>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    );
  }

  return (
    <Layout menu={menu} footer={footer}>
      <Switch>
        <PrivateRoute path={`${path}/`} roleAccess="ROLE_VETERINAIRE" exact>
          <Home />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/nouveau-dossier`}
          roleAccess="ROLE_VETERINAIRE"
          exact
        >
          <NewFolder />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/dossiers/:filter/:folderId?`}
          roleAccess="ROLE_VETERINAIRE"
          exact
        >
          <Folders />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/dossiers`}
          roleAccess="ROLE_VETERINAIRE"
          exact
        >
          <Redirect to={{ pathname: `${path}/dossiers/en-attente` }} />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/mon-profil`}
          roleAccess="ROLE_VETERINAIRE"
          exact
        >
          <Profile />
        </PrivateRoute>

        <PrivateRoute
          path={`${path}/nous-contacter`}
          roleAccess="ROLE_VETERINAIRE"
          exact
        >
          <Contact />
        </PrivateRoute>

        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Layout>
  );
}

export default Routes;
export { PrivateRoute };
