import React, { useState, useEffect } from 'react';
import Loading from '../Loading';
import { Line, Text } from '../Content';
import { getMember } from '../../services/members';
import { IMember } from '../../services/api.interface';
import { formatDevise } from '../../services/helpers';
import { getLabelTypeEtablissement } from '../../services/constantes';
import './DetailMemberAdmin.scss';
import { ReactComponent as Money } from '../../img/money.svg';

interface IDetailMemberAdmin {
  memberId: string;
}

function DetailMemberAdmin({ memberId }: IDetailMemberAdmin): JSX.Element {
  const [member, setMember] = useState<IMember | null | Error>(null);

  useEffect(() => {
    let isCancelled = false;

    (async function fn() {
      try {
        const list = await getMember({ memberId });
        if (!isCancelled) setMember(list);
      } catch (err) {
        if (!isCancelled)
          setMember(new Error(`${err.status} : ${err.data.message}`));
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [memberId]);

  if (!member) return <Loading small />;

  if (member instanceof Error) {
    return (
      <div className="FolderList__error">
        Impossible de charger le contenu.
        <span>{member.message}</span>
      </div>
    );
  }

  return (
    <div className="DetailMemberAdmin">
      <Text label="Type l’établissement" capitalize>
        {getLabelTypeEtablissement(member.GroupOrGie)}
      </Text>
      <Text label="Nombre de vétérinaires">{`${member.nbOfVeto}`}</Text>
      <Text label="Montant cotisation">{`${formatDevise(
        member?.user?.amountPaid || 0
      )}€`}</Text>
      <Text label="Montant donation">{`${formatDevise(
        member?.user?.donationAmount || 0
      )}€`}</Text>
      <Text label="N° Siret">{member.SIRET}</Text>
      <Text label="Adresse">{member.EtabAdresse}</Text>
      <Text label="Code postal">{member.EtabCP}</Text>
      <Text label="Ville" capitalize>
        {member.EtabVille}
      </Text>
      <Text label="Téléphone">{member.telephone}</Text>
      <Line />
      <Text>
        <b>Référent Vetericare</b>
      </Text>
      <Text label="Prénom & Nom" capitalize>
        {member.contactReferent}
      </Text>
      <Text label="Téléphone">{member.refTel}</Text>
      <Text label="Email">{member.refEmail}</Text>
      <Line />
      <Text>
        <b>Récapitulatif des dossiers</b>
      </Text>
      <div>
        <div className="DetailMemberAdmin__group">
          <div className="DetailMemberAdmin__group__item">
            <span>{member?.dossiers?.length}</span> Demandes effectuées
          </div>
          <div className="DetailMemberAdmin__group__item">
            <span>
              {
                member?.dossiers?.filter((e) => [3, 6, 8].includes(e.status))
                  .length
              }
            </span>{' '}
            Cas acceptés
          </div>
          <div className="DetailMemberAdmin__group__item">
            <span>
              {
                member?.dossiers?.filter((e) => [1, 2, 5].includes(e.status))
                  .length
              }
            </span>{' '}
            Cas en cours
          </div>
        </div>
        <div className="DetailMemberAdmin__item">
          <span>
            <Money />
            {formatDevise(
              member?.dossiers?.reduce(
                (a, e) => a + (e.finalAmountSupported || 0),
                0
              ) || 0
            )}
            €
          </span>{' '}
          Global des financements accordées
        </div>
      </div>
    </div>
  );
}

export default DetailMemberAdmin;
