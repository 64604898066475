import React from 'react';
import { ReactComponent as Loader } from '../../img/loader.svg';
import './Loading.scss';

interface ILoading {
  small?: boolean;
  background?: boolean;
  size?: number;
  white?: boolean;
}

function Loading({ small, background, size, white }: ILoading): JSX.Element {
  return (
    <div
      className={`Loading ${small ? '-small' : ''} ${white ? '-white' : ''} ${
        background ? '-background' : ''
      }`}
    >
      <Loader className="Loading__svg" style={{ width: `${size}px` }} />
    </div>
  );
}

Loading.defaultProps = {
  small: false,
  background: false,
  size: null,
  white: false,
};

export default Loading;
