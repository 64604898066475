import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateOwner } from '../../store/user/user.actions';
import { Input, Select, Button } from '../Fields';
import { genre } from '../../services/constantes';
import './Forms.scss';

function Form(): JSX.Element {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      genre: user?.genre || '',
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      adress: user?.owner?.adress || '',
      cp: user?.owner?.cp || '',
      ville: user?.owner?.ville || '',
      phone: user?.owner?.phone || '',
    },
  });

  const { handleSubmit, getValues } = form;

  function preSubmit() {
    const values = getValues();
    dispatch(
      setUpdateOwner({
        genre: parseInt(`${values.genre}`, 10),
        firstname: values.firstname,
        lastname: values.lastname,
        adress: values.adress,
        cp: values.cp,
        ville: values.ville,
        phone: values.phone,
      })
    );
  }

  return (
    <form
      className="Forms"
      onSubmit={handleSubmit(preSubmit)}
      encType="multipart/form-data"
      noValidate
    >
      <Select
        icon="user"
        name="genre"
        label="Genre*"
        formApi={form}
        required="Merci de selectionner un genre."
      >
        {genre.map((e) => (
          <option value={e.id} key={e.id}>
            {e.label}
          </option>
        ))}
      </Select>
      <Input
        icon="user"
        type="text"
        name="firstname"
        label="Prénom*"
        formApi={form}
        required="Merci de renseigner un prénom."
      />
      <Input
        icon="user"
        type="text"
        name="lastname"
        label="Nom*"
        formApi={form}
        required="Merci de renseigner un nom."
      />
      <Input
        icon="pin"
        type="text"
        name="adress"
        label="Adresse*"
        formApi={form}
        required="Merci de renseigner une adresse."
      />
      <Input
        icon="pin"
        type="text"
        name="cp"
        label="Code postal*"
        formApi={form}
        required="Merci de renseigner un code postal."
        maxLength={5}
        inputMode="numeric"
        pattern={{
          value: /[0-9]{5}/,
          message: 'Merci de renseigner une code postal valide.',
        }}
        format={(value) => value?.replace(/\D/g, '')}
      />
      <Input
        icon="pin"
        type="text"
        name="ville"
        label="Ville*"
        formApi={form}
        required="Merci de renseigner une ville."
      />
      <Input
        icon="phone"
        type="tel"
        name="phone"
        label="Téléphone*"
        formApi={form}
        required="Merci de renseigner un numéro de téléphone."
        inputMode="tel"
        maxLength={14}
        pattern={{
          value: /([0-9]{2}\s?){5}/g,
          message: 'Merci de renseigner un numéro de téléphone valide.',
        }}
        format={(value) =>
          value
            ?.replace(/\D/g, '')
            ?.match(/.{1,2}/g)
            ?.join(' ')
        }
      />
      <Button>Enregistrer les modifications</Button>
    </form>
  );
}

export default Form;
