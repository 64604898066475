import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { userSelector } from '../../store/user/user.selectors';
import Layout from '../../components/Layout';
import PrivateRoute from '../../components/PrivateRoute';
import Page404 from '../Page404';
import Home from './Home';
import Folder from './Folder';
import Account from './Account';

import { ReactComponent as House } from '../../img/house.svg';

function Routes({ props }: any): JSX.Element {
  const user = useSelector(userSelector);

  const {
    match: { path },
  } = props;

  const menu = [
    { label: 'Tableau de bord', route: `${path}`, Icon: House },
    {
      label: 'Mon compte',
      route: `${path}/mon-compte`,
      mediaId: user?.logo,
    },
  ];

  return (
    <Layout menu={menu}>
      <Switch>
        <PrivateRoute path={`${path}/`} roleAccess="ROLE_USER" exact>
          <Home />
        </PrivateRoute>

        <PrivateRoute path={`${path}/dossier`} roleAccess="ROLE_USER" exact>
          <Folder />
        </PrivateRoute>

        <PrivateRoute path={`${path}/mon-compte`} roleAccess="ROLE_USER" exact>
          <Account />
        </PrivateRoute>

        <PrivateRoute path="*" roleAccess="ROLE_USER" exact>
          <Page404 />
        </PrivateRoute>
      </Switch>
    </Layout>
  );
}

export default Routes;
export { PrivateRoute };
