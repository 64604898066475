import React, { useState, useEffect } from 'react';
import { getMedia } from '../../services/folders';

interface IMedia {
  mediaId: string;
  className?: string;
  alt: string;
}

function Media({ mediaId, className, alt }: IMedia): JSX.Element {
  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    getMedia(parseInt(mediaId.replace('/api/medias/', ''), 10)).then(
      ({ fileLink }) => {
        setSrc(`${process.env.REACT_APP_URL_API_DRUPAL}${fileLink}`);
      }
    );
  }, [mediaId]);

  return <img src={src} className={className} alt={alt} />;
}

Media.defaultProps = {
  className: null,
};

export default Media;
