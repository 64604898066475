import React from 'react';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import { userReducer } from './user/user.reducer';
import { modalReducer } from './modal/modal.reducer';

const store = createStore(
  combineReducers({
    user: userReducer,
    modal: modalReducer,
  }),
  applyMiddleware(thunk)
);

export default function ProviderStore({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <Provider store={store}>{children}</Provider>;
}

export { store };
