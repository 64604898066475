import React from 'react';
import Link from '../../components/Link';
import { ReactComponent as Logo } from '../../img/logo.svg';
import { ReactComponent as Plug } from '../../img/plug.svg';
import './Page404.scss';

function Page404(): JSX.Element {
  return (
    <div className="Page404">
      <div className="Page404__left">
        <Link
          className="Page404__back"
          href="/"
          title="Page d'accueil"
          ariaLabel="Page d'accueil"
        />
      </div>
      <div className="Page404__content">
        <Logo className="Page404__logo" />
        <h1 className="Page404__title">Oups</h1>
        <p className="Page404__text">Erreur 404</p>
      </div>

      <Plug className="Page404__plug" />

      <Link className="Page404__link" href="/">
        Retour Accueil
      </Link>

      <div className="Page404__footer" />
    </div>
  );
}

export default Page404;
