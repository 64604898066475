import React, { useEffect, useState } from 'react';
import './FolderList.scss';
import FolderItem, { IFolderItem } from '../FolderItem';
import Loading from '../Loading';

interface IFolderList {
  asyncFoldersList?: () => Promise<IFolderItem[]>;
  foldersList?: IFolderItem[] | null | Error;
  notFoundLabel?: React.ReactNode;
  expandForRender?: boolean;
  limit?: number;
}

function FolderList({
  asyncFoldersList,
  foldersList = null,
  notFoundLabel,
  expandForRender,
  limit = 999,
}: IFolderList): JSX.Element {
  const [folders, setFolders] = useState<IFolderList['foldersList']>(
    foldersList
  );

  useEffect(() => {
    setFolders(foldersList);
  }, [foldersList]);

  useEffect(() => {
    let isCancelled = false;

    if (asyncFoldersList) {
      setFolders(null);
      (async function fn() {
        try {
          const list = await asyncFoldersList();
          if (!isCancelled) setFolders(list);
        } catch (err) {
          if (!isCancelled) setFolders(err);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [asyncFoldersList]);

  /* return loading */
  if (folders === null || folders === undefined) {
    return (
      <div className="FolderList__loading">
        <Loading small />
      </div>
    );
  }

  /* return error */
  if (folders instanceof Error) {
    return (
      <div className="FolderList__error">
        Impossible de charger le contenu.
        <span>{folders.message}</span>
      </div>
    );
  }

  /* return no folder found */
  if (!folders.length) {
    return <div className="FolderList__empty">{notFoundLabel}</div>;
  }

  /* return folder list */
  return (
    <ul className="FolderList">
      {folders
        .filter((e, i) => i < limit)
        .map((folder) => (
          <li key={folder.id} className="FolderList__item">
            <FolderItem folder={folder} expandForRender={expandForRender} />
          </li>
        ))}
    </ul>
  );
}

FolderList.defaultProps = {
  asyncFoldersList: undefined,
  foldersList: null,
  expandForRender: false,
  notFoundLabel: "Oups, il n'y a rien à voir...",
  limit: 999,
};

export default FolderList;
