import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalSelector } from '../../store/modal/modal.selectors';
import { hideModalAction } from '../../store/modal/modal.actions';
import Plug from '../../img/plug.svg';
import './Modal.scss';

function Modal(): JSX.Element {
  const dispatch = useDispatch();
  const { status, content, type, params = { closeBack: true } } = useSelector(
    modalSelector
  );

  return (
    <div className={`Modal ${status ? '-visible' : ''}`} aria-hidden={!status}>
      <div
        className="Modal__back"
        onClick={() => {
          if (params.closeBack) dispatch(hideModalAction());
        }}
        role="presentation"
      />
      <div className="Modal__content">
        <button
          type="button"
          className="Modal__close"
          aria-label="Fermer la popin"
          title="Fermer la popin"
          onClick={() => {
            if (params.closeBack) dispatch(hideModalAction());
          }}
        />
        {type === 'normal' ? (
          <div className="Modal__content__wrap">{content}</div>
        ) : (
          <div className="Modal__content__wrap">
            <div>
              <img src={Plug} alt="" className="Modal__img" />
              <div className="Modal__text">
                Une erreur s’est produite, veuillez ré-essayer.
                {content && <div className="Modal__message">{content}</div>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
