import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '../../../store/modal/modal.actions';
import { ExpandLabel } from '../../../components/Expand';
import { Button } from '../../../components/Fields';
import { LinkButton } from '../../../components/Link';
import {
  UpdatePassword,
  UpdateEmail,
  FormVeto1,
  FormVeto2,
} from '../../../components/Forms';
import './Profile.scss';
import Charte from '../../../components/Charte';

function Profile(): JSX.Element {
  const dispatch = useDispatch();
  const [parts, setParts] = useState<boolean[]>([true, false, false, false]);

  function togglePart(index: number) {
    const lastStatus = parts.find((e, i) => i === index);
    setParts((e) =>
      e.map((status, i) => {
        if (i === index) return !status;
        return lastStatus ? status : false;
      })
    );
  }

  return (
    <div className="MemberProfile">
      <div className="MemberProfile__header">
        <h1 className="MemberProfile__header__title">Mon compte</h1>
      </div>
      <div className="MemberProfile__content">
        <ExpandLabel
          onClick={() => togglePart(0)}
          expand={parts[0]}
          label="Profil"
        >
          <UpdateEmail />
          <Button
            onClick={() => {
              dispatch(showModalAction(<UpdatePassword white />));
            }}
          >
            Changer le mot de passe
          </Button>
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(1)}
          expand={parts[1]}
          label="Information structure de soin"
        >
          <FormVeto1 label="Enregistrer les modifications" />
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(2)}
          expand={parts[2]}
          label="Information référent Vetericare"
        >
          <FormVeto2 label="Enregistrer les modifications" />
        </ExpandLabel>
        <ExpandLabel
          onClick={() => togglePart(3)}
          expand={parts[3]}
          label="Charte Vetericare"
        >
          <div className="MemberProfile__text">
            Consulter la charte d’engagement
          </div>
          <Charte>
            <p>
              <strong>Vetericare</strong> est une association à but non
              lucratif, qui aide les équipes vétérinaires à soigner des animaux
              nécessitant des soins lourds et soutenir leurs familles démunies.
            </p>
            <p>
              La réussite pérenne des missions de l’association repose sur la
              solidarité et l’intérêt collectif.{' '}
              <strong>Adhérer, c’est partager nos valeurs</strong> et s’inscrire
              dans la Charte Vetericare.
              <br />
              <strong>
                Adhérer, c’est aussi s’engager à respecter les règles de
                fonctionnement
              </strong>{' '}
              de la plateforme Vetericare
            </p>

            <h2>Charte Vetericare</h2>

            <p>
              <strong>En adhérant à l’association Vetericare,</strong>
              <br />
              <br />
              Notre équipe participe au développement{' '}
              <strong>d’une médecine solidaire et responsable :</strong>
            </p>

            <p>
              - en proposant aux familles modestes une solution d’aide au
              financement de soins lourds à bon pronostic,
            </p>

            <p>
              - en donnant accès à un parcours pédagogique dédié aux
              propriétaires, responsables d’animaux de compagnie,
            </p>
            <p>
              - en participant financièrement, à travers notre adhésion et
              l’organisation de différentes actions caritatives, au financement
              de l’association,
            </p>
            <p>
              - en faisant connaître Vetericare et ses actions et ainsi
              participer au développement de la médecine solidaire vétérinaire.
            </p>

            <h2>Règles de fonctionnement de la plateforme</h2>

            <p>
              <strong>En adhérant à l’association Vetericare,</strong>
              <br />
              <br />
              Je m’engage, ainsi que mon équipe, à sélectionner des
              propriétaires honnêtes, de bonne foi et motivés pour suivre le
              cursus de formation lors d’une demande de financement.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, quant à la véracité et
              l’honnêteté des informations médicales transmises sur la
              plateforme de l’association.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, à réaliser des devis de soins
              réalistes et cohérents avec les prix de marché.
            </p>
            <p>
              Je m’engage, ainsi que mon équipe, à m’assurer que le propriétaire
              de l’animal sera capable d’assumer les suites médicales et
              financières des soins pris en charge par l’association
            </p>
            <p>
              J’ai pris connaissance, ainsi que mon équipe, du délai de carence
              de 10 jours entre l’adhésion et la possibilité de constituer le
              premier dossier de financement
            </p>
            <p>
              J’ai pris connaissance, ainsi que mon équipe, des critères
              médicaux de prise en charge (soins lourds de bon pronostic) et je
              m’engage à réserver mes demandes de financement aux cas
              correspondant à ces critères.
            </p>
            <p>
              J’accepte, ainsi que mon équipe, la publication et la diffusion
              des données médicales et non-médicales (photos, vidéos, ....)
              transmises à Vetericare.
            </p>
            <p>
              J’accepte, ainsi que mon équipe, que Vetericare communique sur
              l’adhésion de mon établissement et les soins réalisés par
              l’équipe.
            </p>
          </Charte>

          <LinkButton
            href={`${window.location.origin}/Charte-et-Reglement-Vetericare.pdf`}
            center
          >
            Télécharger
          </LinkButton>
        </ExpandLabel>
      </div>
    </div>
  );
}

export default Profile;
