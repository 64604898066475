import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  userSelector,
  loadingSelector,
  readySelector,
} from './store/user/user.selectors';
import { getUserAction } from './store/user/user.actions';
import PrivateRoute from './components/PrivateRoute';

import Login from './pages/Login';
import NewPassword from './pages/NewPassword';
import Loading from './components/Loading';
import Page404 from './pages/Page404';
import ForgetPassword from './pages/ForgetPassword';
import CreatePassword from './pages/CreatePassword';

import AdminRoutes from './pages/Admin/Routes';
import MemberRoutes from './pages/Member/Routes';
import OwnerRoutes from './pages/Owner/Routes';

function Routes(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation<{ pathname: string }>();
  const history = useHistory();
  const user = useSelector(userSelector);
  const isLoading = useSelector(loadingSelector);
  const isReady = useSelector(readySelector);

  useEffect(() => {
    dispatch(getUserAction()).then((u: any) => {
      if (u) history.push(location.pathname || '/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Routes">
      <div className="App__notready" data-visible={!isReady} />
      <div className="App__loading" data-visible={isLoading}>
        <Loading background />
      </div>

      <Switch>
        <Route path="/login" render={() => <Login />} />
        <Route path="/mot-de-passe-oublie" render={() => <ForgetPassword />} />
        <Route
          path="/creation-du-mot-de-passe/:token"
          render={() => <CreatePassword />}
        />

        <Route
          path="/nouveau-mot-de-passe/:token"
          render={() => <NewPassword />}
        />

        <PrivateRoute path="/" exact>
          <Redirect to={{ pathname: `/${user?.root}` }} />
        </PrivateRoute>

        {/* ADMIN */}
        <Route
          path="/admin"
          render={(props) => <AdminRoutes props={props} />}
        />

        {/* MEMBER */}
        <Route
          path="/membre"
          render={(props) => <MemberRoutes props={props} />}
        />

        {/* OWNER */}
        <Route
          path="/proprietaire"
          render={(props) => <OwnerRoutes props={props} />}
        />

        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </div>
  );
}

export default Routes;
export { PrivateRoute };
