import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { userSelector } from '../../store/user/user.selectors';
import { setUpdateFolder } from '../../store/user/user.actions';
import { getFolder } from '../../services/folders';
import { Textarea, FileImg, Button } from '../Fields';
import './Forms.scss';

interface IForm {
  folderId: string;
  white?: boolean;
}

function Form({ folderId, white }: IForm): JSX.Element {
  const user = useSelector(userSelector);
  const folder = user?.folder;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      petOwnerNewsPhotos: [],
      petOwnerNewsComment: '',
    },
  });
  const { handleSubmit, getValues, setValue } = form;

  useEffect(() => {
    if (folder?.id) {
      getFolder(folder.id).then((folderDetail) => {
        setValue('petOwnerNewsPhotos', folderDetail.petOwnerNewsPhotos || []);
      });
    }
  }, [folder, setValue]);

  function onSubmit() {
    setLoading(true);
    const { petOwnerNewsPhotos, petOwnerNewsComment } = getValues();
    dispatch(
      setUpdateFolder(
        folderId,
        { petOwnerNewsPhotos, petOwnerNewsComment },
        'Les nouvelles de votre animal ont bien été envoyé !'
      )
    ).finally(() => setLoading(false));
  }

  return (
    <form className="Forms" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FileImg
        name="petOwnerNewsPhotos"
        formApi={form}
        label="Photo.s fortement recommandée.s mais pas obligatoires"
        multiple
        white={white}
      />
      <Textarea
        name="petOwnerNewsComment"
        label="Commentaire*"
        formApi={form}
        required="Merci de renseigner un commentaire."
        white={white}
      />
      <Button loading={isLoading}>Envoyer</Button>
    </form>
  );
}

Form.defaultProps = {
  white: false,
};

export default Form;
