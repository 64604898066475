import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  showModalAction,
  showErrorModalAction,
} from '../../../store/modal/modal.actions';
import { userSelector } from '../../../store/user/user.selectors';
import { Input, Button, Textarea } from '../../../components/Fields';
import { contact } from '../../../services/users';
import './Contact.scss';

function Contact(): JSX.Element {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      referent:
        [
          ...(user?.member?.EtabNom ? [user?.member?.EtabNom] : []),
          ...(user?.member?.contactReferent
            ? [user?.member?.contactReferent]
            : []),
        ].join(' - ') || '',
      phone: user?.member?.telephone || '',
      email: user?.email || '',
      message: '',
    },
  });
  const { handleSubmit, getValues, setValue } = form;

  function onSubmit() {
    setLoading(true);

    contact(getValues())
      .then(() => {
        dispatch(
          showModalAction(<span>Votre message a bien été envoyé&nbsp;!</span>)
        );
        setValue('message', '');
      })
      .catch((err) => {
        dispatch(showErrorModalAction(`${err.status} | ${err.data.message}`));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="MemberContact">
      <div className="MemberContact__header">
        <h1 className="MemberContact__header__title">Nous contacter</h1>
      </div>
      <div className="MemberContact__content">
        <form
          className="MemberContact__form"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          noValidate
        >
          <Input
            icon="user"
            type="text"
            name="referent"
            label="Nom de la clinique - Nom du référent*"
            formApi={form}
            required="Merci de renseigner ce champ."
          />
          <Input
            icon="phone"
            type="text"
            inputMode="tel"
            name="phone"
            label="Numéro de téléphone*"
            formApi={form}
            required="Merci de renseigner un numéro de téléphone."
            maxLength={14}
            pattern={{
              value: /([0-9]{2}\s?){5}/g,
              message: 'Merci de renseigner un numéro de téléphone valide.',
            }}
            format={(value) =>
              value
                ?.replace(/\D/g, '')
                ?.match(/.{1,2}/g)
                ?.join(' ')
            }
          />
          <Input
            icon="email"
            type="email"
            name="email"
            label="Adresse mail*"
            formApi={form}
            required="Merci de renseigner un email."
            pattern={{
              value: /.+@.+\..+/,
              message: 'Merci de renseigner une adresse email valide.',
            }}
          />
          <Textarea
            name="message"
            label="Commentaire*"
            formApi={form}
            required="Merci de renseigner un commentaire."
          />
          <Button loading={isLoading}>Envoyer</Button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
