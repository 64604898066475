import React from 'react';
import { UseFormMethods, Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import './InputDate.scss';

registerLocale('fr', fr);

interface IInputDate {
  name: string;
  label: string;
  formApi: UseFormMethods<any>;
  required?: string;
  disabled?: boolean;
  white?: boolean;
  pattern?: {
    value: RegExp;
    message: string;
  };
  onBlur?: () => void;
  maxDate?: Date;
}

function InputDate({
  name,
  label,
  formApi,
  required,
  disabled,
  pattern,
  onBlur,
  white,
  maxDate,
}: IInputDate): JSX.Element {
  const { errors, formState } = formApi;

  return (
    <Controller
      name={name}
      control={formApi.control}
      rules={{ required, pattern }}
      render={({ onChange, onBlur: blur, value }, { isDirty }) => (
        <div className={`InputDate ${disabled ? '-disabled' : ''}`}>
          <label className="InputDate__group" htmlFor={name}>
            <div className="InputDate__label">{label}</div>
            <div className="InputDate__fieldGroup">
              <div className="InputDate__iconGroup">
                <div className="InputDate__icon -date" />
                <div className="InputDate__field--wrap">
                  <DatePicker
                    name={name}
                    selected={value}
                    className={`InputDate__field ${white ? '-white' : ''} ${
                      errors &&
                      (isDirty || formState.isSubmitted) &&
                      errors[name]
                        ? '-error'
                        : ''
                    }`}
                    onBlur={(e) => {
                      blur();
                      if (onBlur) onBlur();
                    }}
                    disabled={disabled}
                    onChange={onChange}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    maxDate={maxDate}
                    placeholderText="31/01/2014"
                  />
                </div>
              </div>
            </div>

            {errors && (isDirty || formState.isSubmitted) && errors[name] && (
              <div className="InputDate__error">{errors[name].message}</div>
            )}
          </label>
        </div>
      )}
    />
  );
}

InputDate.defaultProps = {
  required: null,
  disabled: null,
  pattern: null,
  onBlur: null,
  white: false,
  maxDate: null,
};

export default InputDate;
