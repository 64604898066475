import React from 'react';
import { VetoNewFolder } from '../../../components/Forms';
import './NewFolder.scss';

function NewFolder(): JSX.Element {
  return (
    <div className="MemberNewFolder">
      <div className="MemberNewFolder__header">
        <h1 className="MemberNewFolder__header__title">Nouveau dossier</h1>
      </div>
      <div className="MemberNewFolder__content">
        <VetoNewFolder />
      </div>
    </div>
  );
}

export default NewFolder;
